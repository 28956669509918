<template>
    <div>
        <h2><i class="fa fa-download fa-2x text-primary" aria-hidden="true"></i> Exportation des données</h2>
        <div class="card">
            <div class="card-body">
                <br>
                <i class="fa fa-info-circle text-primary" aria-hidden="true"></i> Les dessins de fichiers nécessaires à
                l'utilisation des fichiers ci-dessous sont disponibles dans <a :href="'/' + section + '_doc/1'">l'onglet
                    Zone
                    documentaire</a>
                <!--////////    //////-->
                <div v-if="section != 'ppdom2'" class="card mb-3" v-for="rub in tablesRef" v-bind:data="tablesRef"
                    v-bind:key="tablesRef.name">
                    <div class="card-header"><span class="font-weight-bold text-primary">source : {{ rub.title }}</span>
                        <span>{{ rub.date }}</span>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-4">
                                <br />
                                <i class="fa fa-calendar" aria-hidden="true"></i> Sélection de l'année d'étude
                            </div>
                            <div class="col-sm-8">
                                <br />
                                <b-form-select v-model="selectedDate" class="mb-3" id="input-select-date">
                                    <option v-for="millesime in datesExport" :value="millesime.code">{{ millesime.label }}
                                    </option>
                                </b-form-select>
                            </div>
                        </div>
                        <div v-if="section == 'copro'"
                            class="d-flex flex-column justify-center align-items-center font-italic mbn-3 text-grisBleuClaire">
                            <small class="w-100 text-right">(Zonage des EPCI au 1<sup>er</sup> janvier 2022 )</small>
                            <br>
                            <br>
                            <small><span class="font-weight-bold text-danger text-center mx-auto">Attention</span>, Le
                                zonage en <span class="font-weight-bold">aires d'attraction des villes de l'INSEE de
                                    2020</span> est dorénavant utilisé pour les indicateurs (au lieu du zonage en aire
                                urbaine) </small>
                        </div>
                        <table class="table table-bordered table-hover">
                            <tr v-for="tableRef in rub.tables" v-bind:key="rub.id">
                                <td width="95%">
                                    <span><i class="fa fa-arrow-circle-right text-primary" aria-hidden="true"></i>
                                        {{ tableRef.title }}</span>
                                </td>
                                <td class="text-center" width="5%">
                                    <button v-if="(selectedDate !== '2021' && tableRef.name !== 'pp_lovac_19')"
                                        class="btn btn-sm btn-primary rounded" @click="loaddata(tableRef)" v-b-tooltip.hover
                                        title="Cliquez pour télécharger les données"> Exporter <i
                                            class="fa fa-cloud-download" aria-hidden="true"></i></button>
                                    <button v-else-if="(selectedDate === '2021' && tableRef.name === 'pp_lovac_19')"
                                        class="btn btn-sm btn-primary rounded" @click="loaddata(tableRef)" v-b-tooltip.hover
                                        title="Cliquez pour télécharger les données"> Exporter <i
                                            class="fa fa-cloud-download" aria-hidden="true"></i></button>
                                    <div v-else class="btn btn-sm btn-secondary text-center rounded px-4 py-2"
                                        style="opacity: 0.7;width: 70px"><i class="fa fa-ban" aria-hidden="true"></i></div>
                                </td>
                            </tr>
                        </table>
                        <br>
                        <pppi-classement v-if="section == 'pppi'" :selectedReg="selectedReg"
                            :selectedRegLabel="selectedRegLabel">Chargement....</pppi-classement>
                    </div>
                </div>
                <!-- //////    Section pour PPDOM ///////////    -->

                <!--
 <div v-if="section == 'ppdom'" class="card mb-3"
                 v-for="rub in tablesRef"
                 v-bind:data="tablesRef"
                 v-bind:key="tablesRef.name"
            >
                <div class="card-header"><span class="font-weight-bold text-primary">source : {{rub.title}}</span>  <span>{{rub.date}}</span></div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-4" >
                            <h5 class="text-primary">Bases de données sources :</h5>
                            <br>
                        </div>
                        <div class="col-sm-8">
                        </div>
                    <table class="table table-bordered table-hover">
                        <tr v-for="millesime in datesExport" >
                            <td width="95%">
                                <span><i class="fa fa-arrow-circle-right text-primary rounded" aria-hidden="true"></i> {{rub.tables[0].title}}   <span class="small">(en {{millesime.label}})</span></span>
                            </td>
                            <td class="text-center" width="5%">
                              <button v-if="(selectedDate !=='2021' &&  rub.tables[0].name!=='pp_lovac_19') "  class="btn btn-sm btn-primary" @click="loaddata(rub.tables)" v-b-tooltip.hover title="Cliquez pour télécharger les données"> Exporter {{selectedDate}} <i class="fa fa-cloud-download" aria-hidden="true"></i></button>
                              <button v-else-if="(selectedDate ==='2021' &&  rub.tables[0].name ==='pp_lovac_19') "  class="btn btn-sm btn-primary" @click="loaddata(rub.tables)" v-b-tooltip.hover title="Cliquez pour télécharger les données"> Exporter  {{selectedDate}} <i class="fa fa-cloud-download" aria-hidden="true"></i></button>
                              <div v-else  class="btn btn-sm btn-secondary text-center rounded px-4 py-2" style="opacity: 0.7;width: 70px"><i class="fa fa-ban" aria-hidden="true"></i></div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            </div>-->
                <!-- /////////     Fin de section PPDOM ///////////      -->
            </div>
        </div>
        <b-modal id="loadmodal" ref="loadmodal" ok-diseabled="true" ok-title="ok" cancel-title="Annuler"
            title="Exportation de données en cours" header-bg-variant="primary" header-text-variant="fbmWhite">

            <div class="text-center">
                <i class="fa fa-cog fa-3x text-primary" aria-hidden="true" v-if="isSpinning"></i>
                <i class="fa fa-cloud-download fa-3x text-primary" aria-hidden="true" v-if="!isSpinning"></i>
                <i class="fa fa-exclamation-triangle fa-3x text-danger" aria-hidden="true" v-if="alarme"></i>
                <br>

                <p class="my-4" v-html="exportMsg"></p>

                <fbm-rotate-spinner v-if="isSpinning"></fbm-rotate-spinner>

            </div>
            <template slot="modal-footer" class="w-100">
                <b-btn size="sm" class="float-right" variant="primary" @click="hideModal"> Fermer </b-btn>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { PGService } from '../../services/PGService';
import { ExportDataService } from '../../services/ExportDataService';
export default {
    name: "pp_export",
    props: ['section', 'selectedReg', 'selectedRegLabel'],
    data: function () {
        return {
            csrf: null,
            isSpinning: false,
            tablesRef: null,
            exportMsg: '',
            exportDispo: false,
            alarme: false,
            exportPath: '',
            currentItemSelected: {},
            selectedDate: '2019',
            datesExport: [
            ],
            AllTablesRef: [
                {
                    section: 'pprive',
                    datesExport: [
                        { 'code': '2021', 'label': '2021' },
                        { 'code': '2019', 'label': '2019' },
                        { 'code': '2017', 'label': '2017' },
                        { 'code': '2015', 'label': '2015' },
                        { 'code': '2013', 'label': '2013' },
                        { 'code': '2011', 'label': '2011' },
                        { 'code': '2009', 'label': '2009' },
                    ],
                    ref: [
                        {
                            'name': 'cube',
                            'title': ' MTE/SDES, Filocom 2019 d\'après DGFiP, LOVAC 2021, traitement Anah',
                            'date': '',
                            'tables': [
                                { 'id': 0, 'type': 'db', 'name': 'cube1_beyond_19', 'title': 'Le parc de logements', 'date': '2019' },
                                { 'id': 1, 'type': 'db', 'name': 'cube3_beyond_19', 'title': 'Le parc des résidences principales', 'date': '2019' },
                                { 'id': 2, 'type': 'db', 'name': 'cube4a_beyond_19', 'title': 'Les occupants des résidences principales - 1', 'date': '2019' },
                                { 'id': 3, 'type': 'db', 'name': 'cube4b_beyond_19', 'title': 'Les occupants des résidences principales - 2', 'date': '2019' },
                                { 'id': 4, 'type': 'db', 'name': 'pp_emmrecents_19', 'title': 'Les emménagés récents', 'date': '2019' },
                                { 'id': 5, 'type': 'db', 'name': 'pp_revenus_19', 'title': 'Les revenus des ménages', 'date': '2019' },
                                { 'id': 6, 'type': 'db', 'name': 'cube6_beyond_17', 'title': 'La vacance dans le parc privé et le parc public', 'date': '2019' },
                                { 'id': 7, 'type': 'db', 'name': 'cube7_beyond_19', 'title': 'Construction : le parc de logements construits depuis moins de 10 ans', 'date': '2019' },
                                { 'id': 8, 'type': 'db', 'name': 'cube8_beyond_19', 'title': 'Les résidences principales récentes depuis moins de 10 ans par statut d\'occupation', 'date': '2019' },
                                { 'id': 9, 'type': 'db', 'name': 'cube11_beyond_19', 'title': 'Les conditions d\'habitat des propriétaires occupants éligibles aux aides de l\'Anah', 'date': '2019' },
                                { 'id': 10, 'type': 'db', 'name': 'pp_copro_19', 'title': 'Le parc de logements en copropriété ', 'date': '2019' },
                                { 'id': 11, 'type': 'db', 'name': 'pp_precenergetique_19', 'title': 'La précarité énérgétique', 'date': '2019' },
                                { 'id': 12, 'type': 'db', 'name': 'pp_vieillissement_19', 'title': 'Le vieillissement', 'date': '2019' },
                                { 'id': 13, 'type': 'db', 'name': 'pp_lovac_19', 'title': 'Les logements vacants - (focus LOVAC 2021)', 'date': '2021' },
                            ]
                        }
                    ]
                },
                {
                    section: 'dom',
                    datesExport: [
                        { 'code': '2019', 'label': '2019' },
                        { 'code': '2013', 'label': '2013' },
                    ],
                    ref: [
                        {
                            'name': 'dom',
                            'title': 'INSEE, recensements de la population 2013 et 2019, traitements Anah ',
                            'date': '',
                            'tables': [
                                { 'id': 0, 'type': 'db', 'name': 'ppdom_19', 'title': 'Données Insee DOM ', 'date': '2019' },
                                // {'id': 1,'type': 'db', 'name': 'ppdom_13', 'title': 'Données Insee DOM ', 'date': '2013'},
                            ]
                        },
                    ]
                },
                {
                    section: 'dom_filoc',
                    datesExport: [
                        { 'code': '2021', 'label': '2021' },
                        { 'code': '2019', 'label': '2019' },
                        { 'code': '2015', 'label': '2015' },
                    ],
                    ref: [
                        {
                            'name': 'cube',
                            'title': ' MTE/SDES, Filocom 2019 d\'après DGFiP, LOVAC 2021, traitement Anah',
                            'date': '',
                            'tables': [
                                { 'id': 0, 'type': 'db', 'name': 'cube1_beyond_19', 'title': 'Le parc de logements', 'date': '2019' },
                                { 'id': 1, 'type': 'db', 'name': 'cube3_beyond_19', 'title': 'Le parc des résidences principales', 'date': '2019' },
                                { 'id': 2, 'type': 'db', 'name': 'cube4a_beyond_19', 'title': 'Les occupants des résidences principales - 1', 'date': '2019' },
                                { 'id': 3, 'type': 'db', 'name': 'cube4b_beyond_19', 'title': 'Les occupants des résidences principales - 2', 'date': '2019' },
                                { 'id': 4, 'type': 'db', 'name': 'pp_emmrecents_19', 'title': 'Les emménagés récents', 'date': '2019' },
                                { 'id': 5, 'type': 'db', 'name': 'pp_revenus_19', 'title': 'Les revenus des ménages', 'date': '2019' },
                                { 'id': 6, 'type': 'db', 'name': 'cube6_beyond_19', 'title': 'La vacance dans le parc privé et le parc public', 'date': '2019' },
                                { 'id': 7, 'type': 'db', 'name': 'cube7_beyond_19', 'title': 'Construction : le parc de logements construits depuis moins de 10 ans', 'date': '2019' },
                                { 'id': 8, 'type': 'db', 'name': 'cube8_beyond_19', 'title': 'Les résidences principales récentes depuis moins de 10 ans par statut d\'occupation', 'date': '2019' },
                                { 'id': 9, 'type': 'db', 'name': 'cube11_beyond_19', 'title': 'Les conditions d\'habitat des propriétaires occupants éligibles aux aides de l\'Anah', 'date': '2019' },
                                { 'id': 10, 'type': 'db', 'name': 'pp_copro_19', 'title': 'Le parc de logement en copropriété ', 'date': '2019' },
                                { 'id': 11, 'type': 'db', 'name': 'pp_precenergetique_19', 'title': 'La précarité énérgétique', 'date': '2019' },
                                { 'id': 12, 'type': 'db', 'name': 'pp_vieillissement_19', 'title': 'Le vieillissement', 'date': '2019' },
                                { 'id': 13, 'type': 'db', 'name': 'pp_lovac_19', 'title': 'Les logements vacants - (focus LOVAC 2021)', 'date': '2021' }
                            ]
                        }
                    ]
                },
                {
                    section: 'pppi',
                    datesExport: [
                        { 'code': '2019', 'label': '2019' },
                        { 'code': '2015', 'label': '2015' },
                        { 'code': '2011', 'label': '2011' },
                    ],
                    ref: [
                        {
                            'name': 'pppi',
                            'title': 'MTE - Filocom 2019 d\'après la DGFiP, traitement PPPI Anah',
                            'date': '',
                            'tables': [
                                { 'id': 0, 'type': 'db', 'name': 'pppi_19_a', 'title': 'Données PPPI - partie 1', 'date': '2019' },
                                { 'id': 1, 'type': 'db', 'name': 'pppi_19_b', 'title': 'Données PPPI - partie 2', 'date': '2019' },
                                { 'id': 2, 'type': 'db', 'name': 'pppi_cadrage19_a', 'title': 'Données de Cadrage - partie 1', 'date': '2019' },
                                { 'id': 3, 'type': 'db', 'name': 'pppi_cadrage19_b', 'title': 'Données de Cadrage - partie 2', 'date': '2019' },
                                { 'id': 4, 'type': 'db', 'name': 'pppi_section19', 'title': 'Données du PPPI à la section cadastrale', 'date': '2019' },
                            ]
                        },
                    ]
                },
                {
                    section: 'copro',
                    datesExport: [
                        { 'code': '2019', 'label': '2019' },
                        { 'code': '2017', 'label': '2017' },
                    ],
                    ref: [
                        {
                            'name': 'copro',
                            'title': 'MTE - Filocom 2019 d\'après DGFiP, Fichiers infracommunaux d\'aide au repérage des copropriétés fragiles, Anah/DGALN\n',
                            'date': '',
                            'tables': [
                                { 'id': 0, 'type': 'file', 'name': 'copro', 'title': 'Données copropriétés ', 'date': '2019' },
                            ]
                        },
                    ]
                },
                {
                    section: 'coproff',
                    datesExport: [
                        { 'code': '2022', 'label': '2022' },
                    ],
                    ref: [
                        {
                            'name': 'coproff',
                            'title': ' Fichiers Coproff, RNIC, Cerema, Anah/DGALN\n',
                            'date': '',
                            'tables': [
                                { 'id': 0, 'type': 'file', 'name': 'coproff', 'title': 'Données coproff ', 'date': '2022' },
                            ]
                        },
                    ]
                },
            ]
        }
    },
    mounted() {

        //console.log('test firfoxe');

        this.csrf = window.csrfToken;
        this.exportDataService = new ExportDataService();

        switch (this.section) {
            case 'pprive':
                this.tablesRef = this.AllTablesRef[0].ref;
                this.datesExport = this.AllTablesRef[0].datesExport;
                this.selectedDate = '2019';

                break;
            case 'ppdom':
                this.tablesRef = this.AllTablesRef[1].ref;
                this.datesExport = this.AllTablesRef[1].datesExport;
                this.selectedDate = '2019';
                break;
            case 'ppdom_filoc':
                this.tablesRef = this.AllTablesRef[2].ref;
                this.datesExport = this.AllTablesRef[2].datesExport;
                this.selectedDate = '2019';
                break;
            case 'pppi':
                this.tablesRef = this.AllTablesRef[3].ref;
                this.datesExport = this.AllTablesRef[3].datesExport;
                this.selectedDate = '2019';
                break;
            case 'copro':
                this.tablesRef = this.AllTablesRef[4].ref;
                this.datesExport = this.AllTablesRef[4].datesExport;
                this.selectedDate = '2019';
                break;
            case 'coproff':
                this.tablesRef = this.AllTablesRef[5].ref;
                this.datesExport = this.AllTablesRef[5].datesExport;
                this.selectedDate = '2022';
                break;
            default:
                this.tablesRef = this.AllTablesRef[1].ref;
                this.datesExport = this.AllTablesRef[1].datesExport;
                this.selectedDate = '2019';
        }
    },
    watch: {
        selectedReg: function (val) {
            //console.log(val);
        },
    },
    methods: {
        hideModal: function () {
            this.$refs.loadmodal.hide();
        },
        loaddata: function (ref) {
            this.isSpinning = true;
            this.alarme = false;
            this.exportDispo = false;
            this.$refs.loadmodal.show();

            if ((this.selectedDate === "2021" && ref.name !== "pp_lovac_19") || (this.selectedDate !== "2021" && ref.name === "pp_lovac_19")) {
                this.isSpinning = false;
                this.exportMsg = 'Désolé, votre requête n\'a produit aucun résultat avec les critères de recherche demandés !!';
                this.exportDispo = false;
                this.alarme = true;
            } else {
                this.exportMsg = 'Préparation des éléments pour l\'exportation :';
                //(ref.name!=="pp_lovac_17")
                this.exportDataService.exporPPData('anah_pp', ref.name, this.selectedReg, (ref.name === "pp_lovac_19") ? "2019" : this.selectedDate, this.section).then((result) => {
                    //console.log("resltat : ", result.data);
                    //console.log("count : ", result.data.data);
                    if (result.data.data.type === 'file') {
                        this.isSpinning = false;
                        this.exportDispo = true;
                        this.exportMsg = 'Votre fichier est maintenant disponible en téléchargement ici :<br><br><a href="/storage/' + result.data.data.repertoir + '/' + result.data.data.zip + '" class="btn btn-primary">' + result.data.data.title + '</a>';
                    } else if (result.data.data.count > 0) {
                        this.isSpinning = false;
                        this.exportDispo = true;
                        this.exportMsg = 'Votre fichier est maintenant disponible en téléchargement ici :<br><br><a href="/storage' + result.data.data.zip + '" class="btn btn-primary">' + result.data.data.title + '</a>';
                    } else {
                        this.isSpinning = false;
                        this.exportMsg = 'Votre requête n\'a produit aucun résultat avec les critères de recherche demandés !!';
                        this.exportDispo = false;
                        this.alarme = true;
                    }
                }, (err) => {
                    this.exportMsg = ' Alerte : votre requête a généré <br>une ou plusieurs erreurs à l\'exécution <br>et ne peut aboutir !!';
                    this.isSpinning = false;
                    this.exportDispo = false;
                    this.alarme = true;
                });
            }
        },
    }
}
</script>

<style scoped></style>