export class Ol3ChoroMapService {

    constructor(colors, discretClass){

        this._map = null;
        this.tooltip = null;
        this.overlay = null;
        this._parameters = {
            discretClass: discretClass,
            colors:  colors,
        };
        this._gradient = ['#00f', '#0ff', '#0f0', '#ff0', '#f00'];
    }

    buildBasicChoroMapStyle(feature, resolution) {

        let colors = feature.get('parameters').colors;//[[133,6,178,.6], [218,114,255,.6],[194,255,61,.6],[220,255,144,.6],[132,178,32,.6]];//this._parameters.colors;
        let neutralColor = 'rgba(196,195,171,0.5)';
        let nullColor = 'rgba(223,221,222,0.0)';
        let secretColor = feature.get('parameters').sec_color;
        //if(feature.get('parameters').nr_color) { nullColor = feature.get('parameters').nr_color };

        let discretClass = feature.get('parameters').discretClass;// [ '250','150','100','50'];//this._parameters.discretClass;
        let size = feature.length;
        let styleCache = {};

        let fillColor = null;
        let strockColor = 'rgba(50,50,50,0.6)';
        let strockWidth = 1;
        let indic = feature.get('parameters').indic;
        let baseIndicValue = feature.get(indic);
        let indicValue = String(feature.get(indic));

        if(baseIndicValue == null ||  baseIndicValue == undefined){

            //console.log(" est null  : ", indic);
            fillColor = nullColor;
        }else if (indicValue && (indicValue.indexOf('[') >= 0 || indicValue.indexOf(']') >= 0)) {

            fillColor = secretColor;

        } else {

            let comput_indic = Number(feature.get(indic));

            let txtLabel = (Math.round(comput_indic * 100) / 100).toString();

            for (let i = 0; i < discretClass.length; i++) {
                if (comput_indic > discretClass[i]) {
                    fillColor = colors[i];
                    break;
                } else if (comput_indic == null || isNaN(comput_indic) || comput_indic == 'undefined') {
                    if (feature.get('scale') == 'pars' && (feature.get('code').length > 14)) {
                        fillColor = nullColor;
                        txtLabel = 'N.R.';
                    } else {
                        fillColor = neutralColor;
                        (feature.get('code').length < 4) ? txtLabel = feature.get('code') : txtLabel = '';
                    }

                } else {
                    fillColor = colors[colors.length - 1];

                }
            }
        }




        let style = styleCache[size];


        fillColor = ol.color.asArray(fillColor);
        fillColor.slice();
        fillColor[3] = .5; // set oppacity !!


        if (!style) {
            style = [new ol.style.Style({
                fill: new ol.style.Fill({
                    color: fillColor
                }),
                stroke: new ol.style.Stroke({
                    color: strockColor,
                    width: strockWidth
                }),
            })];
            styleCache[size] = style;
        }
        return style;

    }

    buildChoroMapStyle(feature, resolution) {
        /////// PARAMETRES /////////

        let colors = feature.get('parameters').colors;//[[133,6,178,.6], [218,114,255,.6],[194,255,61,.6],[220,255,144,.6],[132,178,32,.6]];//this._parameters.colors;
        let neutralColor = 'rgba(196,195,171,0.5)';
        let nullColor = 'rgba(153,199,191,0.5)';
        let secretColor = feature.get('parameters').sec_color;

        //let discretClass = [ '10000','7000','4000','3500'];//this._parameters.discretClass;
        let discretClass = feature.get('parameters').discretClass;// [ '250','150','100','50'];//this._parameters.discretClass;
        //console.log(feature.getProperties());
        let size = feature.length;
        let styleCache = {};

        let fillColor = null;
        let strockColor = 'rgba(50,50,50,0.6)';
        let strockWidth = 1;
        let indic = feature.get('parameters').indic;
        let indicValue = feature.get(indic);

        if (indicValue && (indicValue.indexOf('[') >= 0 || indicValue.indexOf(']') >= 0)) {

            fillColor = secretColor;

        } else {

            //console.log(indic.indexOf('['));


            let comput_indic =  (feature.get(indic))?Number(feature.get(indic)):null;

            let txtLabel= (comput_indic)?comput_indic.toString():null;


            if(feature.get('label') && (feature.get('label').substring(0,5)=='IDPDL')) {
                        strockColor = 'rgba(50,150,0,0.6)';
                        strockWidth = 2;
            }

            for (let i=0;i<discretClass.length;i++){
                if(comput_indic>discretClass[i]){
                    fillColor = colors[i];
                    break;
                //}else if(comput_indic == null || isNaN(comput_indic) || comput_indic =='undefined' || feature.get('nb_transac') == null){
                }else if(comput_indic == null || isNaN(comput_indic) || comput_indic =='undefined'  ){
                   if(feature.get('nbr_copro') ||((feature.get('scale')=='pars' && feature.get('code').length > 14))){
                       fillColor=nullColor;
                       txtLabel = 'N.R.';
                   }else{
                       fillColor=neutralColor;
                       (feature.get('code').length<4)?txtLabel=feature.get('code') :  txtLabel = '';
                   }

                }else{
                    fillColor=colors[colors.length-1];
                    fillColor= nullColor;
                }
            }
        }
        ////////////// FIN PARAMETRES /////////
        ////////////// FILTRE AUTHORISATION TERRITOIR /////////

        let style = styleCache[size];
        if(feature.get('filter') &&  _.includes("'reg','dep','com'", feature.get('scale')) && !(feature.get('scale')=='com'&&feature.get('ref_conv_scale')=='dep') )
        {
            //let refCodes = feature.get('auth_ref_code').split(";");
            //console.log(feature.get('ref_conv_scale'));

            if( !_.includes(feature.get('auth_ref_code'), String(feature.get('code')))){
                //console.log(_.includes(feature.get('auth_ref_code'), String(feature.get('code'))));
                fillColor = "#C2BFD2";
                txtLabel = '.';
            }
        }


        //// FIN DU FILTRE ////////


        fillColor = ol.color.asArray(fillColor);
        fillColor.slice();
        fillColor[3] = .5; // set oppacity !!


        if (!style) {
            style = [new ol.style.Style({
                fill: new ol.style.Fill({
                    color: fillColor
                }),
                stroke: new ol.style.Stroke({
                    color: strockColor,
                    width: strockWidth
                }),
                text: new ol.style.Text({
                    font: '12px sans-serif',
                    text: txtLabel ,//(Math.round(comput_indic*100)/100).toString(),
                    fill: new ol.style.Fill({
                        color: '#FFF'
                    }),
                    stroke: new ol.style.Stroke({
                        color: '#000',
                        width: 3,
                    }),
                })
            })];
            styleCache[size] = style;
        }
        return style;
    }



    /////////////////////////////
    //////// ACCESSORS //////////
    /////////////////////////////




    get parameters(){
        return this._parameters;
    }

    set parameters(params){
        this._parameters = params;
    }

}


