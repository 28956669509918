<template>
    <div class="row title">
        <div class="col-md-3">
            <b-card no-body>
                <b-tabs ref="tabs" card v-model="tabIndex" >
                    <b-tab title="Thématiques" >
                        <synth-map-list :section="storedSection"></synth-map-list>
                    </b-tab>
                    <b-tab title="Légende" >
                        <legendChoroMap :parameters="legendParameters"  ></legendChoroMap>
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>
        <div class="col-md-9">
            <div class="card">
                <h5 class="card-header">Thématique : <span class="text-primary">{{currentMap.rubrique}}</span></h5>
                <div class="card-body">
                    <h4 class=""><i class="fa fa-bookmark text-primary" aria-hidden="true"></i> {{currentMap.title}}</h4>
                    <geoBreadCrumb :refGeo="refGeo" v-if="section != 'ppdom'"></geoBreadCrumb>
                    <b-btn v-b-toggle="'draw-tools'" size="sm" class="m-1" v-b-tooltip.hover.top title="Afficher - Masquer les outils"><i class="fa fa-cogs" aria-hidden="true"></i></b-btn>
               <b-collapse id="draw-tools">
                        <div class="card">
                            <div class="card-body">
                                <div class="btn-toolbar mt-2 mb-2" role="toolbar" aria-label="Toolbar with button groups">
                                    <div id="layer-map-btn-trace" class="btn-group btn-group-sm mr-2" role="group" aria-label="Premier group" data-togle="button">
                                        <label class="btn btn-secondary "> Routes <input name="basemap" type="radio" id="road"  value="Road" v-model="layerImageSet"></label>
                                        <label class="btn btn-secondary "> Satellite <input name="basemap" type="radio" id="sat"  value="Sat" v-model="layerImageSet"></label>
                                        <label class="btn btn-secondary "> Neutre <input name="basemap" type="radio" id="grey"  value="Grey" v-model="layerImageSet"></label>
                                        <label class="btn btn-secondary "> Parcellaire or. <input name="basemap" type="radio" id="parcelles"  value="Parcelles" v-model="layerImageSet"></label>
                                        <label class="btn btn-secondary ">Parcellaire Noir <input name="basemap" type="radio" id="elevation"  value="Elevation" v-model="layerImageSet"></label>
                                        <label class="btn btn-secondary ">Limites <input name="basemap" type="radio" id="limites"  value="Limite" v-model="layerImageSet"></label>
                                    </div>
                                    <div class="btn-group btn-group-sm mr-2" role="group" aria-label="Outil de tracage" data-togle="button">
                                        <!--<button class="btn btn-outline-primary" @click="buildLegendeCanvas()" v-b-tooltip.hover.top title="Exporter la carte au format PNG">PNG</button>-->
                                        <button class="btn btn-outline-primary" @click="buildLegendeCanvas('pdf')" v-b-tooltip.hover.top title="Exporter la carte au format PDF">PDF</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                  </b-collapse>

                       <div id="info"></div>
                    <div id="tooltip" class="tooltip-fbm"></div>
                    <div id="fullscreen" class="fullscreen">
                        <div id="zoneMap" >
                            <fbm-rotate-spinner :test="isSpinning"></fbm-rotate-spinner>
                        </div>
                    </div>

                </div>
                <div class="card-footer small">{{currentMap.source}}</div>
            </div>

    </div>
    </div>
</template>
<script>
    import {GeoJsonService} from '../services/geoJsonService';
    import {Ol3ProcessService} from '../services/ol3-process-service';
    import {Ol3PointMapService} from '../services/ol3-point-map-service';
    import {Ol3HeatMapService} from '../services/ol3-heat-map-service';
    import {Ol3ChoroMapService} from '../services/ol3-choro-map-service';
    import {LegendMapParamsService} from '../services/legend-map-params-service';
    import {LogoDataService} from '../services/logoDataService';
    export default{
        props: ['title','subtitle',  'selectedreg', 'selectedRegLabel', 'section'],
        data(){
            return{
                map:'',
                zoomLevel: 1,
                layerImageSet: "Grey",
                mapTerrPdfLabel: '',
                tabIndex: 1,
                selectedRegStored : this.selectedreg,
                storedSection : this.section,
                isSpinning: false,
                currentsMapParamsId: 1,
                dataSynth: {},
                legendParameters: {
                    indic       : 'moy_note_marche',
                    indicTitle  : "Indice dit 'Marché'",
                    discretClass: ['2.5', '2', '1', '0.5'],
                    colors      : ['#8806B2', '#DC72FF', '#82B223', '#BEFF3D', '#DAFF90' ],
                    source      : 'DGFIP : 2013 - CEREMA NORD PICARDIE',
                    refGeo      : {scale: 'Test',},
                },
                scaleSearch : [{'scale':'com', label:'Code Insee Commune'}, {'scale':'epci', label:'Code EPCI'}],
                refGeo: {
                    dep: null,
                    commune: null,
                    epci: null,
                    scale: 'reg',
                    scaleLabel: 'Région',
                    sec: null,
                    code: null
                },
                currentMap: {
                    title: '...Chargement',
                    resum: '...Chargement',
                    thema: '...Chargement',
                    source: '...Chargement',
                    rubrique : '...Chargement'
                },
            }
        },
        mounted(){


            (this.storedSection==undefined || this.storedSection==null)?this.storedSection='pppi':null;

            //console.log('test 1', this.selectedreg);

             EventBus.$emit('UPDATE_SELECTED_LINK', {url:'/cartes-synth'});

             this.geoJsonService = new GeoJsonService();
             this.ol3Process = new Ol3ProcessService();
             this.ol3PointMapService = new Ol3PointMapService();
             this.ol3HeatMapService = new Ol3HeatMapService();
             this.logoDataService = new LogoDataService();
             this.ol3ChoroMapService = new Ol3ChoroMapService(['#92B21B','#EFFFB2','#E3ACFF', '#C659FF','#842DB2'], [ '10000','7000','4000','3500']);
             this.legendMapParamsService = new LegendMapParamsService(this.section);
             this.legendParameters = this.updateLegendMapParams(this.currentsMapParamsId);
             this.buildMap();
             this.initGeoData();
             let that = this;

             EventBus.$on('display_synth_map', (evt)=>{
                     that.tabIndex = 1;
                     this.isSpinning = true;
                     this.updateMap(evt);
            });

             EventBus.$on('DISPLAY_SCALE', (evt)=>{
                 this.isSpinning = true;
                 this.getGeoJsonTerritoryData(this.refGeo.dep, evt , this.refGeo.dep);
             });

            EventBus.$on('UPDATE_SELECTED_SEARCHREF', (evt)=>{
                this.refGeo.code = evt.code;
                this.getGeoJsonTerritoryData(evt.code, evt.scale, evt.dep);
            });

            EventBus.$on('RELOAD_LEVEL', (evt) =>{
                this.reloadLevel(evt.level, evt.scale, evt.dep);
            });
        },
        watch:{
            layerImageSet: function (val) {
                    let mapLayers = this.map.getLayers().getArray();
                    switch(val){
                    case 'Road':
                        mapLayers[0].setVisible(1);
                        mapLayers[1].setVisible(0);
                        mapLayers[2].setVisible(0);
                        mapLayers[3].setVisible(0);
                        mapLayers[4].setVisible(0);
                        mapLayers[5].setVisible(0);
                        break;
                    case 'Sat':
                        mapLayers[0].setVisible(0);
                        mapLayers[1].setVisible(1);
                        mapLayers[2].setVisible(0);
                        mapLayers[3].setVisible(0);
                        mapLayers[4].setVisible(0);
                        mapLayers[5].setVisible(0);
                        break;
                    case 'Grey':
                        mapLayers[0].setVisible(0);
                        mapLayers[1].setVisible(0);
                        mapLayers[2].setVisible(1);
                        mapLayers[3].setVisible(0);
                        mapLayers[4].setVisible(0);
                        mapLayers[5].setVisible(0);
                        break
                    case 'Parcelles':
                        mapLayers[0].setVisible(0);
                        mapLayers[1].setVisible(0);
                        mapLayers[2].setVisible(0);
                        mapLayers[3].setVisible(1);
                        mapLayers[4].setVisible(0);
                        mapLayers[5].setVisible(0);
                        break;
                    case 'ParcelGrey':
                        mapLayers[0].setVisible(0);
                        mapLayers[1].setVisible(0);
                        mapLayers[2].setVisible(1);
                        mapLayers[3].setVisible(1);
                        mapLayers[4].setVisible(0);
                        mapLayers[5].setVisible(0);
                        break;
                    case 'Elevation':
                        mapLayers[0].setVisible(0);
                        mapLayers[1].setVisible(0);
                        mapLayers[2].setVisible(0);
                        mapLayers[3].setVisible(0);
                        mapLayers[4].setVisible(1);
                        mapLayers[5].setVisible(0);
                        break;

                    case 'Limite':
                        mapLayers[0].setVisible(0);
                        mapLayers[1].setVisible(0);
                        mapLayers[2].setVisible(1);
                        mapLayers[3].setVisible(0);
                        mapLayers[4].setVisible(0);
                        mapLayers[5].setVisible(1);
                        break;
                    }
                }
        },
        methods:{
            initGeoData: function(geoData) {
                this.isSpinning = true;
                //console.log('La region choisis : ', this.selectedreg);

                if(this.section=='ppdom'){
                    this.getGeoJsonTerritoryData('97'+this.selectedreg.substring(1,2), 'dep', '97'+this.selectedreg.substring(1,2));
                }else{
                    this.getGeoJsonTerritoryData(this.selectedreg, 'reg', '');
                }
                this.initPhase = true;
             },
            getGeoJsonTerritoryData: function(refCode,refScale, refDep){
                //console.log('refCode',refCode, 'refScale',refScale, 'refDep', refDep);
                (refScale=='reg')?refCode=String(this.selectedreg):null;
                if(refScale=='reg'||refScale=='dep' || refScale=='epci'){
                    this.geoJsonService.getTerritory(refCode, refScale, refDep, false, this.section).then((response) =>{
                        this.geoData = response.data.data[0].row_to_json;
                        this.refGeo.dep = refDep;
                        this.refGeo.scale = refScale;
                        (this.refGeo.scale=='epci')?this.refGeo.epci = this.refGeo.code: null;
                        (refScale=='com')?this.refGeo.commune = this.refGeo.code: null;
                        this.isSpinning = false;
                        this.buildVectorLayer(this.geoData);
                        (this.initPhase)?EventBus.$emit('wait_for_first_map', {}): null;
                        this.initPhase = false;
                    }, (err) =>{
                        alert('une erreur c\'est produite lors de l\'execution requête : ' + err);
                        this.isSpinning = false;
                    });
                }else{
                    this.isSpinning = false;
                }
            },
             getGeoJsonPointsData: function(refCode, refScale, refDep) {
                 this.geoJsonService.getPdlPoint({code:refCode, scale:refScale, dep:refDep}).then( (response) =>{
                    this.geoData = response.data.data[0].row_to_json;
                    //console.log(response);
                    this.isSpinning = false;
                    this.buildRefLayer(this.geoData);
                 }, (err) => {
                    alert('une erreur c\'est produite : ' + err);
                    this.isSpinning = false;
                 })
            },
            buildMap: function(){
                this.map = this.ol3Process.buildMap('zoneMap', this.zoomLevel, this.layerImageSet);
                this.buildMapInteractions();
             },
            buildMapInteractions(){
                let that = this;
                let info = $('#info');
                this.ol3Process.addMapOverInteraction(this.map, this.vectorLayer);
                //CLick
                this.map.on('click', (evt) => {
                    let pixel = evt.pixel;
                    let featureSelected = evt.map.forEachFeatureAtPixel(pixel, (feature, layer) => feature);
                    that.onSelectFeature(featureSelected);
                });
                //console.log("legendeParams indic : ", this.legendParameters.indic);
                this.map,this.ol3Process.addTooltipInteraction(this.map,document.getElementById('tooltip'),[
                    {label:'Code : ',value:'code'},
                    {label:'label : ',value:'label'},
                    {label:'Indic. : ', value: this.legendParameters.indic},
                    ]);
            },
            updateLegendMapParams(id){
                let params = this.legendMapParamsService.parameters;
                //params.refGeo = this.refGeo;
                return params[id];
            },
            buildVectorLayer(geoJsonData){
                this.legendParameters = this.updateLegendMapParams(this.currentsMapParamsId);
                let newParams = [["parameters", this.legendParameters], ['displayTooltip',true]];
                (this.vectorLayer)?this.map.removeLayer(this.vectorLayer):null;
                this.vectorLayer = this.ol3Process. buildVectorLayer( geoJsonData, 'vector', newParams);
                this.vectorLayer.setStyle(this.ol3ChoroMapService.buildBasicChoroMapStyle);
                this.map,this.ol3Process.addTooltipInteraction(this.map,document.getElementById('tooltip'),[
                    {label:'Code : ',value:'code'},
                    {label:'label : ',value:'label'},
                    //{label:'Indic. : ', value: (newParams[0][1].indic && String(newParams[0][1].indic).indexOf('.')>=0)?Math.round(Number(newParams[0][1].indic)*100)/100+"zzz":"test"},
                    {label:'Indic. : ', value: newParams[0][1].indic},
                ]);
                this.map.addLayer(this.vectorLayer);
                this.map.getView().fit(this.vectorLayer.getSource().getExtent(), this.map.getSize());
                this.isSpinning = false;
            },
             buildRefLayer(geoJsonData){
                let refLayer = this.ol3Process. buildVectorLayer( geoJsonData, 'refLayer');
                refLayer.setVisible(false);
                this.map.addLayer(refLayer);
                this.map.getView().fit(refLayer.getSource().getExtent(), this.map.getSize());
             },
             buildDrawLayer: function(geoJsonData, theMap)  {
                this.ol3HeatMapService.gradient = ['#0000FF','#00FF00','#ff9900'];
                let drawLayer = null;
                this.ol3PointMapService.map = this.map;
                this.ol3HeatMapService.map = this.map;
                switch(theMap.type){
                    case  'heatMap' :
                    drawLayer = this.ol3HeatMapService.buildHeatMap(geoJsonData, 'draw_layer');
                    break;
                    case 'pointMap':
                    drawLayer = this.ol3PointMapService.buildPointsLayer(geoJsonData,theMap.indic, 2 , theMap.props, 'draw_layer');
                    break;
                }
                this.map.addLayer(drawLayer);
                this.isSpinning = false;
             },
             updateMap: function(ref){
                    this.currentMap =  ref.map;
                    this.currentMap.rubrique =  ref.rubrique;
                    this.currentsMapParamsId = ref.map.id;
                    this.ol3Process.cleanByLayerName(this.map,'draw_layer');
                    ////// TODO CHOIX DES TYPE DE CARTE CHORO OU VOLUME !!!!!!!
                    if(true){
                        this.ol3Process.cleanByLayerName(this.map, 'vector');
                        this.buildVectorLayer(this.geoData);
                    }else{

                        //this.buildDrawLayer(this.geoData, this.currentMap);
                    }
             },
            onSelectFeature: function(feature) {

                if (feature && _.includes(['reg', 'dep','epci','com'], feature.get('scale'))) {
                    this.isSpinning = true;
                    this.refGeo.code = feature.get('code');
                    let indic = feature.get('parameters').indic;
                    //console.log(feature.get('code'),feature.get('scale'), feature.get('dep'),feature.get('label') , "val : ", feature.get(indic) );
                    this.mapTerrPdfLabel = ' | '+(feature.get('label')+' ('+feature.get('code')+')');
                    (feature.get('scale')=='epci')?feature.set('scale','com'):null;
                    //console.log('parameters : ',feature.get('parameters'));
                    //console.log(feature);
                    this.getGeoJsonTerritoryData(feature.get('code'), feature.get('scale'), feature.get('dep'), feature.get('label'));
                }
            },

            reloadLevel: function(refCode, refScale, refDep) {
                this.map.removeLayer(this.vectorLayer);
                this.isSpinning = true;
                //console.log(refCode, refScale, refDep);
                this.getGeoJsonTerritoryData(refCode, refScale, refDep);
            },
            buildLegendeCanvas: function(exportFormat = 'img'){

                this.tabIndex = 1;
                let that = this

              let legendeElement = document.getElementById('legende');
              legendeElement.style.left = 0;
              legendeElement.style.top = 0;
              window.scrollTo(0,0);

              html2canvas(legendeElement ).then(function (canvasLegende) {
                  console.log(canvasLegende.width, canvasLegende.height);
                  let canvasData = document.body.appendChild(canvasLegende);
                  canvasData.setAttribute("id", "canvasLegende");
                  switch (exportFormat) {
                    case 'pdf':
                      that.exportMapPDF(canvasLegende);
                      break;
                    default:
                      that.exportMapImg(canvasLegende);
                  }
                });
            },

            exportMapImg: function(legendeCanvas){

                    let that = this;
                    this.map.once('postcompose', function(event) {

                        let canvas = event.context.canvas;
                        // build canvas total
                        let canvasData = document.createElement("canvas");
                        canvasData.setAttribute("id", "canvasMap");
                        canvasData.height = canvas.height;
                        canvasData.width = canvas.width + legendeCanvas.width;
                        document.body.appendChild(canvasData);

                        let finalContext =  canvasData.getContext('2d');
                        finalContext.drawImage(canvas, 0,0);
                        finalContext.drawImage(legendeCanvas,canvas.width,0);
                        finalContext.font = "9px Arial";
                        finalContext.fillText('source : '+that.currentMap.source,5,canvasData.height - 10);

                        let the_time = new Date().getTime();

                        // if IE save
                        if (navigator.msSaveBlob) {
                            navigator.msSaveBlob(canvasData.msToBlob(), that.currentMap.title+'_'+the_time+'.png');
                            let oldCanvas = document.getElementById('canvasMap');
                            oldCanvas.parentNode.removeChild(oldCanvas);
                            let oldLegende = document.getElementById('canvasLegende');
                            oldLegende.parentNode.removeChild(oldLegende);

                        } else { // else save
                            canvasData.toBlob(function(blob) {
                                FileSaver.saveAs(blob, that.currentMap.title+'_'+the_time+'.png');
                                let oldCanvas = document.getElementById('canvasMap');
                                oldCanvas.parentNode.removeChild(oldCanvas);
                                let oldLegende = document.getElementById('canvasLegende');
                                oldLegende.parentNode.removeChild(oldLegende);
                            });
                        }
                    });
                    this. map.renderSync();
            },

            exportMapPDF: function(legendeCanvas){

                this.isSpinning = true;
                let that = this;

                let title = that.currentMap.title;
                let source = that.currentMap.source;
                let rubrique = that.currentMap.rubrique;
                let toolName = '';
                let logoAnah =  that.logoDataService.logoDataAnah();
                let warningMention = '';
                let attribution = document.getElementsByClassName("ol-attribution")[0].getElementsByTagName("ul")[0].getElementsByTagName("li")[1].textContent;

                switch(that.section){
                case 'pppi':
                    toolName = 'Parc Privé Potentiellement Indigne 2019';
                    warningMention = 'Avertissement : Cette carte ne peut en aucun cas servir pour localiser des situations réelles d\'habitat indigne, mais permet de fournir des indicateurs d\'alerte. La méthode dite SQUARE de pré-repérage du Parc Privé Potentiellement Indigne (PPPI) nécessite une confrontation avec l\'ensemble des autres sources disponibles auprès des acteurs locaux, dont les services de l\'Etat (Préfecture, ARS, DDT, etc.), de l\'Anah, ainsi que des SCHS. En ce sens, il s\'agit uniquement d\'un document de travail et de discussion avec vos partenaires.';
                    break;
                case 'ppdom':
                    toolName = 'Synthèse Parc Privé DOM 2019';
                    warningMention = '';
                    break;
                }

                let doc = new window.JsPDF({
                    orientation: 'portrait',
                });

                doc.addImage(logoAnah, 'JPEG', 10, 10, 26, 22);
                doc.setFillColor(96, 121, 150);
                //doc.rect(0, 5, 100, 290, 'F');
                doc.setFontSize(9);
                doc.text('Outils du Parc Privé - Anah - https://outils-parc-prive.anah.fr ', 40, 25);
                doc.setFontSize(17);
                doc.setTextColor(100, 100, 100);
                doc.text(toolName, 40, 31);
                doc.setLineWidth(0.2);
                doc.setDrawColor(96, 121, 150);
                doc.line(10, 35, 200, 35);

                //doc.setTextColor(96, 121, 150);

                doc.setFontSize(9);
                //doc.text( rubrique + that.mapTerrPdfLabel, 10, 68);

                doc.setFontSize(12);
                doc.setTextColor(96, 121, 150);
                doc.text( title, 10, 75);

                doc.setLineWidth(0.2);
                doc.setDrawColor(200,200,200);
                doc.line(10, 77, 200, 77);

                this.map.once('postcompose', function(event){

                    let canvas = event.context.canvas;

                    doc.addImage(legendeCanvas, 'JPG', 10, 80, 40, 75);

                    doc.addImage(canvas, 'JPG',  50, 80, 150, 75);
                    //doc.rect(40, 80, 160, 76);
                    doc.rect(50, 80, 150, 75);
                    doc.setFontSize(4);
                    doc.text( attribution , 195, 155, 'right');
                    doc.setFontSize(6);
                    doc.text( source , 200, 160, 'right');
                    doc.setLineWidth(0.2);
                    doc.setDrawColor(200,200,200);
                    doc.line(10, 280, 200, 280);

                    doc.setFontSize(5);

                    let mentionRef =  doc.splitTextToSize(warningMention, 180);

                    doc.text( mentionRef , 10, 285);

                    let oldLegende = document.getElementById('canvasLegende');
                    oldLegende.parentNode.removeChild(oldLegende);

                    let the_time = new Date().getTime();

                    that.isSpinning = false;

                    doc.save(that.currentMap.title+'_'+the_time);
                });
                this. map.renderSync();
            },
        }
    }
</script>
<style>

    .tooltip-fbm {
    @include box-shadow(5,5,5,#000);
        border-radius: 4px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        padding: 10px;
        background: rgb(223, 227, 236);
        color: rgb(78, 74, 111);
        opacity: 0.9;
        white-space: nowrap;
        font-size: 0.8em;
        border: rgba(255, 255, 255, 0.87) 2px solid;

    }
    .tooltip-fbm .line
    {
        width: 100%;
        border-bottom: 1px solid #000;
    }
    .tooltip-fbm .line .label
    {
        color: #6c007d;
        font-weight: 900;
    }
    .tooltip-fbm s.line .value
    {
        color: #00FF00;
    }

    #info {
        position: relative;
        height: 1px;
        width: 1px;
        z-index: 70000;
    }
    .map-breadcrumb{
        font-size: smaller;
        position: relative;
        top: -51px;
        float: right;
        list-style: none;
    }
    li.breadcrumb-item + li.breadcrumb-item::before {
        display: inline-block;
        font-family: FontAwesome;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        color: #51564b;
        content: "\F0E2";
        font-size: 1.3em;
    }
    li.breadcrumb-item .btn.btn-primary{
        color: #FFF;
    }

    .draw-tools {
        display: block;
        position: absolute;
        top: 84px;
        right: 61px;
        z-index: 800;
    }

</style>
