"use strict";

export class ExportDataService {


    constructor(){

        this.headers = new Headers({'Content-Type': 'application/json'});
    }

    exporPPData(db, tblName, reg, date=null, section=null)
    {
        let params = {
            db: db,
            tblName: tblName,
            reg: reg,
            date: date,
            section: section,
        };

        return axios.post('/data/export', params, this.headers);

    }

}