<template>

    <div>
        <div class="card">
            <div class="card-body">
                <p><span class="badge badge-primary"><i class="fa fa-map-marker" aria-hidden="true"></i> Zonage 1</span>
                    : {{ terr1.scaleLabel }} <span class="text-primary">{{ terr1.label }} ({{ terr1.codegeo }})</span>
                </p>
                <p><span class="badge badge-primary"><i class="fa fa-map-marker" aria-hidden="true"></i> Zonage 2</span>
                    : {{ terr2.scaleLabel }} <span class="text-primary">{{ terr2.label }} ({{ terr2.codegeo }})</span>
                </p>
                <p><span class="badge badge-primary"><i class="fa fa-map-marker" aria-hidden="true"></i> Zonage 3</span>
                    : {{ terr3.scaleLabel }} <span class="text-primary">{{ terr3.label }} ({{ terr3.codegeo }})</span>
                </p>
                <p><span class="badge badge-primary"><i class="fa fa-map-marker" aria-hidden="true"></i> Zonage 4</span>
                    : {{ terr4.scaleLabel }} <span class="text-primary">{{ terr4.label }} ({{ terr4.codegeo }})</span>
                </p>
                <div class="row">
                    <div class="col">
                        <a :href="zonagePath" class="btn btn-primary"><i class="fa fa-undo" aria-hidden="true"></i>
                            Modifier vos zonages</a>
                    </div>
                    <div class="col text-right">
                        <b-btn variant="outline-primary2" v-b-tooltip.hover
                            title="'Exporter les données sélectionnées aux formats Excel'"
                            @click="callExport('excel')"><i class="fa fa-cloud-download" aria-hidden="true"></i>
                            XLS</b-btn>
                        <b-btn variant="outline-primary2" v-b-tooltip.hover
                            title="'Exporter les données sélectionnées aux formats LibreOffice'"
                            @click="callExport('ods')"><i class="fa fa-cloud-download" aria-hidden="true"></i>
                            ODS</b-btn>
                        <b-btn variant="outline-primary2" v-b-tooltip.hover
                            title="'Exporter les données sélectionnées aux formats PDF'" @click="callExport('pdf')"><i
                                class="fa fa-file-pdf-o" aria-hidden="true"></i> PDF</b-btn>
                    </div>
                    <br>
                    <b-btn variant="primary2" size="sm" v-b-tooltip.hover
                        title="Sélectionner / désélectionner l'ensemble des thématiques" @click="toggleSelectAll()"><i
                            class="fa fa-cloud-download" aria-hidden="true"></i><i class="fa fa-arrows-v"
                            aria-hidden="true"></i></b-btn>
                </div>
            </div>
        </div>
        <b-card no-body>
            <b-tabs card>
                <fbm-rotate-spinner :test="isSpinning"></fbm-rotate-spinner>
                <b-tab title="Données synthétiques" id="tab1">
                    <b-card no-body class="mb-1" v-for="(section, index) in structureData" :key="index"
                        :id="'tab_' + index">
                        <b-card-header header-tag="header" class="p-1" role="tab"
                            :style="{ backgroundColor: ((querry_table === 'querry_pp' && index === 9) || (querry_table === 'querry_ppdom_filoc' && index === 8)) ? '#7f9cbd' : '#607996' }">
                            <div class="row justify-content-between">
                                <div class="col-11">
                                    <b-btn block href="#" v-b-toggle="'section_' + String(index)" variant="primary2"
                                        :style="{ border: 'none', backgroundColor: ((querry_table === 'querry_pp' && index === 9) || (querry_table === 'querry_ppdom_filoc' && index === 8)) ? '#7f9cbd' : '#607996' }"
                                        class="text-left">
                                        <i class="fa fa-list" aria-hidden="true"></i>
                                        <span class="badge badge-pill badge-primary">{{ index + 1 }}</span> -
                                        {{ section.section.section }}
                                    </b-btn>
                                </div>
                                <div class="col-1 text-right">
                                    <input type="checkbox" class="form-check-input" :id="'export_section_' + index"
                                        :name="'export_section_' + index">
                                    <label class="form-check-label" :for="'export_section_' + index"> <i
                                            class="fa fa-cloud-download text-light" aria-hidden="true"></i></label>
                                </div>
                            </div>
                        </b-card-header>
                        <b-collapse :id="'section_' + (String(index))" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <p v-if="querry_table === 'querry_ppdom_filoc' && index === 4">
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                    <em>
                                        <small>
                                            Les données sur les ménages propriétaires occupants sous les plafonds de
                                            l’Anah en vigueur en métropole sont données à titre indicatif pour les DOM,
                                            les aides de l’Anah dans les DOM ne concernant pas les propriétaires
                                            occupants mais uniquement les propriétaires bailleurs.
                                        </small>
                                    </em>
                                </p>
                                <table class="table table-sm table-bordered table-anah-pp">
                                    <col width="36%">
                                    <col width="16%">
                                    <col width="16%">
                                    <col width="16%">
                                    <col width="16%">
                                    <tr>
                                        <td class="col1 text-center">
                                        </td>
                                        <td class="col3 text-center" v-b-tooltip.hover
                                            :title="terr1.scaleLabel + ' (' + terr1.codegeo + ') ' + terr1.label">
                                            <span class="badge badge-primary">
                                                <div v-if="terr1.label.length <= 16"> 1 - {{ terr1.label }}</div>
                                                <div v-else>1 - {{ terr1.label.substring(0, 15) }}...</div>
                                            </span>
                                        </td>
                                        <td class="col5 text-center" v-b-tooltip.hover
                                            :title="terr2.scaleLabel + ' (' + terr2.codegeo + ') ' + terr2.label">
                                            <span class="badge badge-primary">
                                                <div v-if="terr2.label.length <= 16"> 2 - {{ terr2.label }}</div>
                                                <div v-else>2 - {{ terr2.label.substring(0, 15) }}...</div>
                                            </span>
                                        </td>
                                        <td class="col7 text-center" v-b-tooltip.hover
                                            :title="terr3.scaleLabel + ' (' + terr3.codegeo + ') ' + terr3.label">
                                            <span class="badge badge-primary">
                                                <div v-if="terr3.label.length <= 16"> 3 - {{ terr3.label }}</div>
                                                <div v-else>3 - {{ terr3.label.substring(0, 15) }}...</div>
                                            </span>
                                        </td>
                                        <td class="col9 text-center" v-b-tooltip.hover
                                            :title="terr4.scaleLabel + ' (' + terr4.codegeo + ') ' + terr4.label">
                                            <span class="badge badge-primary">
                                                <div v-if="terr4.label.length <= 16"> 4 - {{ terr4.label }}</div>
                                                <div v-else>4 - {{ terr4.label.substring(0, 15) }}...</div>
                                            </span>
                                        </td>
                                    </tr>

                                </table>

                                <table class="table table-sm table-bordered table-anah-pp"
                                    v-for="(rubrique, index2) in section.section.rub"
                                    :id="'sec_' + String(index) + 'tab_' + index2">
                                    <col width="36%">
                                    <col width="10%">
                                    <col width="6%">
                                    <col width="10%">
                                    <col width="6%">
                                    <col width="10%">
                                    <col width="6%">
                                    <col width="10%">
                                    <col width="6%">
                                    <tr v-if="rubrique.rubrique.substring(0, 6) == 'Taille'" class=""
                                        style="background-color:#e4ecf1;">
                                        <td><i class="fa fa-arrow-circle-o-right text-primary" aria-hidden="true"></i>
                                            <span class="badge badge-pill badge-primary">{{ index2 + 1 }}</span> - <span
                                                class="text-primary">{{ rubrique.rubrique }}</span>
                                        </td>
                                        <td class="fbm-header-tab-info align-middle">Nbr.</td>
                                        <td class="fbm-header-tab-info align-middle"></td>
                                        <td class="fbm-header-tab-info align-middle">Nbr.</td>
                                        <td class="fbm-header-tab-info align-middle"></td>
                                        <td class="fbm-header-tab-info align-middle">Nbr.</td>
                                        <td class="fbm-header-tab-info align-middle"></td>
                                        <td class="fbm-header-tab-info align-middle">Nbr.</td>
                                        <td class="fbm-header-tab-info align-middle"></td>
                                    </tr>
                                    <tr v-else class="" style="background-color:#e4ecf1;">
                                        <td><i class="fa fa-arrow-circle-o-right text-primary" aria-hidden="true"></i>
                                            <span class="badge badge-pill badge-primary">{{ index2 + 1 }}</span> - <span
                                                class="text-primary">{{ rubrique.rubrique }}</span>
                                        </td>
                                        <td class="fbm-header-tab-info align-middle">Nbr.</td>
                                        <td class="fbm-header-tab-info align-middle">%</td>
                                        <td class="fbm-header-tab-info align-middle">Nbr.</td>
                                        <td class="fbm-header-tab-info align-middle">%</td>
                                        <td class="fbm-header-tab-info align-middle">Nbr.</td>
                                        <td class="fbm-header-tab-info align-middle">%</td>
                                        <td class="fbm-header-tab-info align-middle">Nbr.</td>
                                        <td class="fbm-header-tab-info align-middle">%</td>
                                    </tr>

                                    <tr v-for="(dataSrc, index3) in rubrique.data">
                                        <!-- POUR AFFICHER LA REF DATA AVEC LE LABEL POR DEBUG -->
                                        <!--  <td class="col1 text-left small" v-html="dataSrc.label+' = '+dataSrc.label_ref" ></td>-->
                                        <td class="col1 text-left small" v-html="dataSrc.label"></td>
                                        <!-- COL 2 -->
                                        <td v-if="terr1.codegeo == 'FR' && querry_table == 'querry_pp' && index == 5"
                                            class="col9 text-center"
                                            style="font-size: 0.8rem; background-color: #f4fafd">
                                            <p>S. O.</p>
                                        </td>
                                        <td v-else class="col2 text-right">
                                            <span class="font-weight-bold" style="font-size: 0.8rem;">
                                                <div v-for="(tableOrder, ind) in tableOrderSrc">
                                                    <div
                                                        v-if="alldata[allDataIndex][tableOrder] && alldata[allDataIndex][tableOrder][0].length > 0">
                                                        <div v-for="(refAn, indexAnne) in tableNbrAnne"
                                                            :id="dataSrc.label_ref">
                                                            <span
                                                                v-if="querry_table == 'querry_ppdom' && dataSrc.label_ref && dataSrc.label_ref.includes('evol_')" />
                                                            <span v-else>
                                                                {{ (alldata[allDataIndex][tableOrder][0][indexAnne] &&
                                                                    (dataSrc.annee == refAn || dataSrc.annee == '')) ?
                                                                    alldata[allDataIndex][tableOrder][0][indexAnne][String(dataSrc.table + "_" + dataSrc.label_data)] : ''
                                                                | formatNumber }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                        </td>
                                        <!-- COL 3 -->
                                        <td v-if="terr1.codegeo == 'FR' && querry_table == 'querry_pp' && index == 5"
                                            class="col9 text-center"
                                            style="font-size: 0.8rem; background-color: #f4fafd">
                                            <p>S. O.</p>
                                        </td>
                                        <td v-else class="col3 text-right " style="font-size: 0.8rem;">
                                            <div v-for="tableOrder in tableOrderSrc">
                                                <div v-if="alldata[allDataIndex][tableOrder][0].length > 0">
                                                    <div
                                                        v-if="dataSrc.label_ref && dataSrc.label_ref.substring(0, 2) == 'tx'">
                                                        <span class="text-primary">
                                                            {{ (alldata[allDataIndex][tableOrder][0][0] &&
                                                                alldata[allDataIndex][tableOrder][0][0][String(dataSrc.label_ref)]
                                                                != null &&
                                                                alldata[allDataIndex][tableOrder][0][0][String(dataSrc.label_ref)]
                                                                !=
                                                                0) ? String(Math.round((alldata[allDataIndex][tableOrder][0][0][String(dataSrc.table + "_" + dataSrc.label_data)] / alldata[allDataIndex][tableOrder][0][0][String(dataSrc.label_ref)]) * 1000) / 10) + "%" : ''
                                                            }}</span>
                                                    </div>
                                                    <div
                                                        v-else-if="dataSrc.label_ref && dataSrc.label_ref.substring(0, 4) == 'calc'">
                                                        <span class="text-primary">{{
                                                            (alldata[allDataIndex][tableOrder][0][0]
                                                                &&
                                                                alldata[allDataIndex][tableOrder][0][0][String(dataSrc.label_ref)]
                                                            ) ? String(alldata[allDataIndex][tableOrder][0][0][String(dataSrc.label_ref)]) + '%' : ''
                                                            }}</span>
                                                    </div>
                                                    <div
                                                        v-else-if="dataSrc.label_ref && dataSrc.label_ref.substring(0, 3) == 'tmm'">
                                                        <span class="text-primary">{{
                                                            (alldata[allDataIndex][tableOrder][0][0]
                                                                &&
                                                                alldata[allDataIndex][tableOrder][0][0][String(dataSrc.label_ref)]
                                                            ) ? String(alldata[allDataIndex][tableOrder][0][0][String(dataSrc.label_ref)]) + '' : ''
                                                            }}</span>
                                                    </div>
                                                    <div v-else>
                                                        <span class="text-info">{{
                                                            (alldata[allDataIndex][tableOrder][0][0]
                                                                &&
                                                                alldata[allDataIndex][tableOrder][0][0][String(dataSrc.label_ref)]
                                                            ) ? String(alldata[allDataIndex][tableOrder][0][0][String(dataSrc.label_ref)]) + '%' : ''
                                                            }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <!-- COL 4 -->
                                        <td v-if="terr2.codegeo == 'FR' && querry_table == 'querry_pp' && index == 5"
                                            class="col9 text-center"
                                            style="font-size: 0.8rem; background-color: #f4fafd">
                                            <p>S. O.</p>
                                        </td>
                                        <td v-else class="col4 text-right"><span class="font-weight-bold"
                                                style="font-size: 0.8rem;">

                                                <div v-for="tableOrder in tableOrderSrc">
                                                    <div v-if="alldata[allDataIndex][tableOrder][1].length > 0">

                                                        <div v-for="(refAn, indexAnne) in tableNbrAnne">
                                                            {{ (alldata[allDataIndex][tableOrder][1][indexAnne] &&
                                                                (dataSrc.annee == refAn || dataSrc.annee == '')) ?
                                                                alldata[allDataIndex][tableOrder][1][indexAnne][String(dataSrc.table + "_" + dataSrc.label_data)] : ''
                                                            | formatNumber }}
                                                        </div>

                                                    </div>
                                                </div>
                                            </span></td>
                                        <!-- COL 5 -->
                                        <td v-if="terr2.codegeo == 'FR' && querry_table == 'querry_pp' && index == 5"
                                            class="col9 text-center"
                                            style="font-size: 0.8rem; background-color: #f4fafd">
                                            <p>S. O.</p>
                                        </td>
                                        <td v-else class="col5 text-right text-primary" style="font-size: 0.8rem;">
                                            <div v-for="tableOrder in tableOrderSrc">
                                                <div v-if="alldata[allDataIndex][tableOrder][1].length > 0">
                                                    <div
                                                        v-if="dataSrc.label_ref && dataSrc.label_ref.substring(0, 2) == 'tx'">
                                                        <span class="text-primary">
                                                            {{ (alldata[allDataIndex][tableOrder][1][0] &&
                                                                alldata[allDataIndex][tableOrder][1][0][String(dataSrc.label_ref)]
                                                                != null &&
                                                                alldata[allDataIndex][tableOrder][1][0][String(dataSrc.label_ref)]
                                                                !=
                                                                0) ? String(Math.round((alldata[allDataIndex][tableOrder][1][0][String(dataSrc.table + "_" + dataSrc.label_data)] / alldata[allDataIndex][tableOrder][1][0][String(dataSrc.label_ref)]) * 1000) / 10) + "%" : ''
                                                            }}</span>
                                                    </div>
                                                    <div
                                                        v-else-if="dataSrc.label_ref && dataSrc.label_ref.substring(0, 4) == 'calc'">
                                                        <span class="text-primary">{{
                                                            (alldata[allDataIndex][tableOrder][1][0]
                                                                &&
                                                                alldata[allDataIndex][tableOrder][1][0][String(dataSrc.label_ref)]
                                                            ) ? String(alldata[allDataIndex][tableOrder][1][0][String(dataSrc.label_ref)]) + '%' : ''
                                                            }}</span>
                                                    </div>
                                                    <div
                                                        v-else-if="dataSrc.label_ref && dataSrc.label_ref.substring(0, 3) == 'tmm'">
                                                        <span class="text-primary">{{
                                                            (alldata[allDataIndex][tableOrder][1][0]
                                                                &&
                                                                alldata[allDataIndex][tableOrder][1][0][String(dataSrc.label_ref)]
                                                            ) ? String(alldata[allDataIndex][tableOrder][1][0][String(dataSrc.label_ref)]) + '' : ''
                                                            }}</span>
                                                    </div>
                                                    <div v-else>
                                                        <span class="text-info">{{
                                                            (alldata[allDataIndex][tableOrder][1][0]
                                                                &&
                                                                alldata[allDataIndex][tableOrder][1][0][String(dataSrc.label_ref)]
                                                            ) ? String(alldata[allDataIndex][tableOrder][1][0][String(dataSrc.label_ref)]) + '%' : ''
                                                            }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <!-- COL 6 -->
                                        <td v-if="terr3.codegeo == 'FR' && querry_table == 'querry_pp' && index == 5"
                                            class="col9 text-center"
                                            style="font-size: 0.8rem; background-color: #f4fafd">
                                            <p>S. O.</p>
                                        </td>
                                        <td v-else class="col6 text-right"><span class="font-weight-bold"
                                                style="font-size: 0.8rem;">
                                                <div v-for="tableOrder in tableOrderSrc">
                                                    <div v-if="alldata[allDataIndex][tableOrder][2].length > 0">
                                                        <div v-for="refAn, indexAnne in tableNbrAnne">
                                                            {{ (alldata[allDataIndex][tableOrder][2][indexAnne] &&
                                                                (dataSrc.annee == refAn || dataSrc.annee == '')) ?
                                                                alldata[allDataIndex][tableOrder][2][indexAnne][String(dataSrc.table + "_" + dataSrc.label_data)] : ''
                                                            | formatNumber }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </span></td>
                                        <!-- COL 7 -->
                                        <td v-if="terr3.codegeo == 'FR' && querry_table == 'querry_pp' && index == 5"
                                            class="col9 text-center"
                                            style="font-size: 0.8rem; background-color: #f4fafd">
                                            <p>S. O.</p>
                                        </td>
                                        <td v-else class="col7 text-right text-primary" style="font-size: 0.8rem;">
                                            <div v-for="tableOrder in tableOrderSrc">
                                                <div v-if="alldata[allDataIndex][tableOrder][2].length > 0">
                                                    <div
                                                        v-if="dataSrc.label_ref && dataSrc.label_ref.substring(0, 2) == 'tx'">
                                                        <span class="text-primary">{{
                                                            (alldata[allDataIndex][tableOrder][2][0]
                                                                &&
                                                                alldata[allDataIndex][tableOrder][2][0][String(dataSrc.label_ref)]
                                                                != null &&
                                                                alldata[allDataIndex][tableOrder][2][0][String(dataSrc.label_ref)]
                                                                !=
                                                                0) ? String(Math.round((alldata[allDataIndex][tableOrder][2][0][String(dataSrc.table + "_" + dataSrc.label_data)] / alldata[allDataIndex][tableOrder][2][0][String(dataSrc.label_ref)]) * 1000) / 10) + "%" : ''
                                                            }}</span>
                                                    </div>
                                                    <div
                                                        v-else-if="dataSrc.label_ref && dataSrc.label_ref.substring(0, 4) == 'calc'">
                                                        <span class="text-primary">{{
                                                            (alldata[allDataIndex][tableOrder][2][0]
                                                                &&
                                                                alldata[allDataIndex][tableOrder][2][0][String(dataSrc.label_ref)]
                                                            ) ? String(alldata[allDataIndex][tableOrder][2][0][String(dataSrc.label_ref)]) + '%' : ''
                                                            }}</span>
                                                    </div>
                                                    <div
                                                        v-else-if="dataSrc.label_ref && dataSrc.label_ref.substring(0, 3) == 'tmm'">
                                                        <span class="text-primary">{{
                                                            (alldata[allDataIndex][tableOrder][2][0]
                                                                &&
                                                                alldata[allDataIndex][tableOrder][2][0][String(dataSrc.label_ref)]
                                                            ) ? String(alldata[allDataIndex][tableOrder][2][0][String(dataSrc.label_ref)]) + '' : ''
                                                            }}</span>
                                                    </div>
                                                    <div v-else>
                                                        <span class="text-info">{{
                                                            (alldata[allDataIndex][tableOrder][2][0]
                                                                &&
                                                                alldata[allDataIndex][tableOrder][2][0][String(dataSrc.label_ref)]
                                                            ) ? String(alldata[allDataIndex][tableOrder][2][0][String(dataSrc.label_ref)]) + '%' : ''
                                                            }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <!-- COL 8 -->
                                        <td v-if="terr4.codegeo == 'FR' && querry_table == 'querry_pp' && index == 5"
                                            class="col9 text-center"
                                            style="font-size: 0.8rem; background-color: #f4fafd">
                                            <p>S. O.</p>
                                        </td>
                                        <td v-else class="col8 text-right"><span class="font-weight-bold"
                                                style="font-size: 0.8rem;">

                                                <div v-for="tableOrder in tableOrderSrc">
                                                    <div v-if="alldata[allDataIndex][tableOrder][3].length > 0">
                                                        <div v-for="refAn, indexAnne in tableNbrAnne">
                                                            <div
                                                                v-if="(alldata[allDataIndex][tableOrder][3][indexAnne] && (dataSrc.annee == refAn || dataSrc.annee == ''))">
                                                                {{
                                                                    alldata[allDataIndex][tableOrder][3][indexAnne][String(dataSrc.table + '_' + dataSrc.label_data)]
                                                                    | formatNumber }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span></td>
                                        <!-- COL 9 -->
                                        <td v-if="terr4.codegeo == 'FR' && querry_table == 'querry_pp' && index == 5"
                                            class="col9 text-center "
                                            style="font-size: 0.8rem; background-color: #f4fafd">
                                            <p>S. O.</p>
                                        </td>
                                        <td v-else class="col9 text-right text-primary" style="font-size: 0.8rem;">
                                            <div v-for="tableOrder in tableOrderSrc">
                                                <div v-if="alldata[allDataIndex][tableOrder][3].length > 0">
                                                    <div
                                                        v-if="dataSrc.label_ref && dataSrc.label_ref.substring(0, 2) == 'tx'">
                                                        <span class="text-primary">
                                                            {{ (alldata[allDataIndex][tableOrder][3][0] &&
                                                                alldata[allDataIndex][tableOrder][3][0][String(dataSrc.label_ref)]
                                                                != null &&
                                                                alldata[allDataIndex][tableOrder][3][0][String(dataSrc.label_ref)]
                                                                !=
                                                                0) ? String(Math.round((alldata[allDataIndex][tableOrder][3][0][String(dataSrc.table + "_" + dataSrc.label_data)] / alldata[allDataIndex][tableOrder][3][0][String(dataSrc.label_ref)]) * 1000) / 10) + "%" : ''
                                                            }}</span>
                                                    </div>
                                                    <div
                                                        v-else-if="dataSrc.label_ref && dataSrc.label_ref.substring(0, 4) == 'calc'">
                                                        <span class="text-primary">{{
                                                            (alldata[allDataIndex][tableOrder][3][0]
                                                                &&
                                                                alldata[allDataIndex][tableOrder][3][0][String(dataSrc.label_ref)]
                                                            ) ? String(alldata[allDataIndex][tableOrder][3][0][String(dataSrc.label_ref)]) + '%' : ''
                                                            }}</span>
                                                    </div>
                                                    <div
                                                        v-else-if="dataSrc.label_ref && dataSrc.label_ref.substring(0, 3) == 'tmm'">
                                                        <span class="text-primary">{{
                                                            (alldata[allDataIndex][tableOrder][3][0]
                                                                &&
                                                                alldata[allDataIndex][tableOrder][3][0][String(dataSrc.label_ref)]
                                                            ) ? String(alldata[allDataIndex][tableOrder][3][0][String(dataSrc.label_ref)]) + '' : ''
                                                            }}</span>
                                                    </div>
                                                    <div v-else>
                                                        <span class="text-info">{{
                                                            (alldata[allDataIndex][tableOrder][3][0]
                                                                &&
                                                                alldata[allDataIndex][tableOrder][3][0][String(dataSrc.label_ref)]
                                                            ) ? String(alldata[allDataIndex][tableOrder][3][0][String(dataSrc.label_ref)]) + '%' : ''
                                                            }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="9" class="tab-source" v-html="rubrique.source"></td>
                                    </tr>
                                    <tr>
                                        <td colspan="9" class="tab-link" v-if="rubrique.url != null"><a
                                                :href="rubrique.url"><i class="fa fa-info-circle fa-2x"
                                                    aria-hidden="true"></i> {{ rubrique.text_url }} <i
                                                    class="fa fa-arrow-circle-o-right " aria-hidden="true"></i></a></td>
                                    </tr>

                                </table>

                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </b-tab>
            </b-tabs>
        </b-card>
        <b-modal id="exportmodal" ref="exportModal" title="Exportation des données" :no-close-on-backdrop="true"
            :ok-only="true" ok-title="Fermer" header-bg-variant="primary" header-text-variant="grisClaire"
            class="text-center">

            <i class="fa fa-exclamation-triangle fa-3x" aria-hidden="true"></i><br>
            <p class="my-4">Merci de sélectionner au moins une thématique à exporter !</p>
        </b-modal>
    </div>
</template>

<script>
import { PGService } from '../../services/PGService';
import { DataStructureService } from "../../services/DataStructureService";
import { Table2CSVService } from "../../services/Table2CSVService";
import { LogoDataService } from "../../services/logoDataService";
import { ExportPdfService } from "../../services/ExportPdfService";

import Papa from 'papaparse';
import XLSX from 'xlsx';
import XLSXSTYLE from 'xlsx-style';

export default {
    name: "ppDisplay1",
    props: ['terr1', 'terr2', 'terr3', 'terr4', 'alldata', 'querry_table'],
    data: function () {
        return {
            isSpinning: true,
            isChecked: false,
            structureData: [],
            allDataIndex: 0,
            zonagePath: '',
            structureSection: [],
            structureRubrique: [],
            n1: 4,
            n2: 3,
            n3: 2,
            n4: 1,
            n5: 0,
            tableOrderSrc: [],
            tableNbrAnne: [],
        }
    },
    mounted() {

        console.log('alldata : ', this.alldata);
        console.log('querry_table: ', this.querry_table);
        console.log('terr 1 : ', this.terr1, 'terr 2 : ', this.terr2, 'terr 3 : ', this.terr3, 'terr 4 : ', this.terr4);


        switch (this.querry_table) {
            case 'querry_pp':
                this.tableOrderSrc = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
                this.zonagePath = '/pprive_actu';
                this.tableNbrAnne = ['n1', 'n2', 'n3', 'n4', 'n5'];
                this.titleApp = 'Synthèse Parc Privé 2019';
                this.appFileName = 'synth_parc_prive_2019';
                this.allDataIndex = 0;
                break;
            case 'querry_pppi':
                this.tableOrderSrc = [0, 1];
                this.zonagePath = '/pppi_actu';
                this.tableNbrAnne = ['n1', 'n3', 'n4'];
                this.titleApp = 'Parc Privé Potentiellement Indigne 2019';
                this.appFileName = 'pppi_2019';
                this.allDataIndex = 0;
                break;
            case 'querry_ppdom_filoc':
                this.tableOrderSrc = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
                this.tableOrderSrc = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
                this.zonagePath = '/ppdom_actu';
                this.tableNbrAnne = ['n1', 'n2', 'n3', 'n4', 'n5'];
                this.titleApp = 'Synthèse Parc Privé DOM 2019';
                this.appFileName = 'synth_parc_prive_dom_2019';
                this.allDataIndex = 0;
                break;
            case 'querry_ppdom':
                this.tableOrderSrc = [0, 1];
                this.zonagePath = '/ppdom_actu';
                this.tableNbrAnne = ['n1', 'n2'];
                this.titleApp = 'Synthèse Parc Privé DOM 2019';
                this.appFileName = 'synth_parc_prive_dom_2019';
                this.allDataIndex = 1;
        };
        this.dataStructureService = new DataStructureService();
        this.table2CSVService = new Table2CSVService();
        this.logoDataService = new LogoDataService();
        this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {

            let currentPanel = $('#' + collapseId);
            let currentPanelHeight = currentPanel.offset().top;
            let panelHeadingHeight = $('#tab1').height();
            //console.log(panelHeadingHeight);
            $('html,body').animate({
                scrollTop: currentPanelHeight
            }, 100);
        })
        this.preparComp();
    },
    methods: {

        preparComp: function () {
            // Get all structure
            this.dataStructureService.getStructure('pp', this.querry_table, 'section').then((result) => {

                this.structureData = result.data.data;
                //console.log(result.data.data);
                for (let i = 0; i < this.structureData.length; i++) {
                    this.structureData[i].section = JSON.parse(this.structureData[i].section);
                    this.isSpinning = false;
                }
                //console.log("Structure :",  this.structureData);
                this.exportPdfService = new ExportPdfService(this.structureData, this.appFileName);

            }, (err) => {
                alert("Un problème c'est produit avec votre requête.");
                this.isSpinning = false;
            });
        },
        toggleSelectAll() {
            this.isChecked = !this.isChecked;
            for (let i = 0; i < this.structureData.length; i++) {
                document.getElementById('export_section_' + i).checked = this.isChecked;
            }
        },

        callExport: function (typeExport) {

            let refTab = [];
            let refSectionTitle = [];
            for (let i = 0; i < this.structureData.length; i++) {
                if (document.getElementById('export_section_' + i).checked) {
                    refTab.push({ sec: i, nb_tab: this.structureData[i].section.rub.length });
                    refSectionTitle.push({ sec: i, nb_tab: this.structureData[i].section.section });
                }
            }


            ///////////// Suprimer les liens dans le tableau pour l'exportation ////////////
            if (this.querry_table === 'querry_pppi') {

                let linkRow = document.getElementsByClassName('tab-link');
                for (let i = 0; i < linkRow.length; i++) {
                    linkRow[i].parentNode.parentNode.removeChild(linkRow[i].parentNode);
                }
            }
            ////////// FIN de la supperssion des lien pour export //////////////////////////

            if (refTab.length <= 0) {
                this.$refs.exportModal.show();
                return;
            }

            switch (typeExport) {
                case 'excel':
                    this.exportExcel(refTab, 'xlsx');
                    break;
                case 'ods':
                    this.exportExcel(refTab, 'ods');
                    break;
                case 'pdf':
                    this.exportPdf(refTab, refSectionTitle);
                    break;
            }
        },
        exportPdf: function (refTab) {

            let refTerr = [this.terr1, this.terr2, this.terr3, this.terr4];
            this.exportPdfService.buildDoc(refTab, this.querry_table, refTerr, this.titleApp);
        },

        exportExcel: function (refTab, format) {
            let allDataObject = [];
            let wb = XLSX.utils.book_new();
            for (let a = 0; a < refTab.length; a++) {

                let section_title = '';

                if (this.structureData[refTab[a].sec] && this.structureData[refTab[a].sec].section && this.structureData[refTab[a].sec].section.section) {

                    section_title = (refTab[a].sec + 1) + '-' + this.structureData[refTab[a].sec].section.short_section.substring(0, 20);
                    let oneSection = { title: section_title, data: [] };
                    let refTerritoires = [
                        ['Type territoires', this.terr1.scaleLabel, '', this.terr2.scaleLabel, '', this.terr3.scaleLabel, '', this.terr4.scaleLabel, '',],
                        ['Nom territoires', this.terr1.label, '', this.terr2.label, '', this.terr3.label, '', this.terr4.label, '',],
                        ['code territoires', this.terr1.codegeo, '', this.terr2.codegeo, '', this.terr3.codegeo, '', this.terr4.codegeo, '',],
                        //['','Nbr', '%','Nbr', '%','Nbr', '%','Nbr', '%'],
                        ['', '', '', '', '', '', '', '', ''],
                    ];

                    let ws2 = XLSX.utils.aoa_to_sheet(refTerritoires);

                    for (let i = 0; i < refTab[a].nb_tab; i++) {
                        let elem = document.getElementById('sec_' + refTab[a].sec + 'tab_' + i);
                        let csv = this.table2CSVService.tableToCSV(elem);
                        if (csv) {
                            let resultJson = Papa.parse(csv, {
                                delimiter: ";",	// auto-detect
                                transform: function (val, columnIndex) {
                                    try {
                                        if (columnIndex > 0) {
                                            if (val.length > 1) {
                                                val = val.split("%").join("");
                                            }
                                            val = val.split(" ").join("");
                                        }
                                        return val;
                                    } catch (err) {
                                        return val;
                                    }
                                },
                                dynamicTyping: true,
                                newline: "\r\n",	// auto-detect
                                quoteChar: '"',
                                header: false,
                            });

                            oneSection.data = resultJson.data;
                            ws2 = XLSX.utils.sheet_add_aoa(ws2, resultJson.data, { origin: -1 });// };
                            //////
                            let cell = ws2["A3"];
                            cell.s = {
                                "fill": {
                                    "patternType": "solid",
                                    "bgColor": {
                                        rgb: '003d79'
                                    },
                                    "fgColor": {
                                        rgb: '003d79'
                                    }
                                },
                                "font": {
                                    "color": {
                                        rgb: 'fff'
                                    },
                                    "bold": true
                                }
                            };
                        }
                    }
                    // Rajout d'une mention PPPI
                    if (this.querry_table == 'querry_pppi' && a == 0) {

                        let mentionPPPI_1 = 'Ces données ont vocation à alimenter la définition et la mise en œuvre des politiques locales de lutte contre l’habitat indigne, et ne doivent être diffusées que dans le cadre d\'un partenariat formalisé ';
                        let mentionPPPI_2 = 'avec les services de l\'État ou l\'Anah. Ces données sont soumises aux règles strictes de diffusion, leur exploitation impose des précautions méthodologiques.  Les valeurs entre crochets correspondent ';
                        let mentionPPPI_3 = 'aux valeurs secrètes (comprises entre 1 et 10 inclus et exprimées ]0;11[) ou masquées pour ne pas permettre de reconstituer une valeur secrète (dans ce cas les valeurs  entre crochets';
                        let mentionPPPI_4 = 'sont supérieures à 10 par exemple [11:20[ signifiant que la donnée est comprise en 11 et 19 unités. ';
                        let mentionPPPI_5 = 'Attention, pour cette version du PPPI, la définition de la suroccupation a été modifiée pour se rapprocher des normes utilisées par la CAF. ';

                        let tot_mention = [
                            [mentionPPPI_1],
                            [mentionPPPI_2],
                            [mentionPPPI_3],
                            [mentionPPPI_4],
                            [mentionPPPI_5]
                        ]

                        ws2 = XLSX.utils.sheet_add_aoa(ws2, tot_mention, { origin: -1 });

                    }
                    // fin mention PPPI

                    allDataObject.push(oneSection);
                    XLSX.utils.book_append_sheet(wb, ws2, oneSection.title);

                }
            }
            /* generate a download */
            function s2ab(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            if (format == 'xlsx') {

                let wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
                let blobContent = s2ab(wbout);
                FileSaver.saveAs(new Blob([blobContent], { type: "application/octet-stream" }), this.appFileName + ".xlsx");
            }
            if (format == 'ods') {
                let wbout = XLSX.write(wb, { bookType: 'ods', type: 'binary' });
                let blobContent = s2ab(wbout);
                FileSaver.saveAs(new Blob([blobContent], { type: "application/octet-stream" }), this.appFileName + ".ods");
            }
        }
    }
}
</script>

<style scoped>
td.col1 {
    width: 200px;
}

td.col2,
td.col3,
td.col4,
td.col5,
td.col6,
td.col7,
td.col8,
td.col9 {
    width: 40px;
}

td.col1,
td.col3,
td.col5,
td.col7,
td.col9 {
    border-right: 2px solid #cccccc;
}

td.col1 {
    padding-left: 20px;
}

.fbm-header-tab-info {
    font-size: 70%;
    color: #0d47a1;
    text-align: center;
}

.tab-source {
    font-size: 60%;
    padding-left: 20px;

}

.tab-link {
    font-size: 85%;
    padding-left: 20px;

}

::v-deep div.pp-label-sub {
    padding-left: 9px;
    border-left: #91adbe 1px dotted;
    color: #0a4c98;
}
</style>