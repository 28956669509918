<template>
    <div class="row mb-4">
        <div class="col d-flex justify-content-between ">
            <a  href="/copro_actu" class="btn btn-lg btn-block" :class="[(tag =='export') ? 'btn-primary' : 'btn-outline-primary']"><i class="fa fa-cloud-download text-primary2" aria-hidden="true"></i> Exportation des données brutes</a>
            <a  href="/copro_doc/1" class="btn btn-lg " :class="[(tag =='doc') ? 'btn-primary' : 'btn-outline-primary']"><i class="fa fa-stack-overflow text-primary2" aria-hidden="true"></i> Zone documentaire</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "pp_navbar",
        props: ['tag', 'selectedReg', 'selectedRegLabel'],
        data: function(){
            return {

            }
        },
        mounted(){
            if(localStorage.currentsection!='copro' ) {
                $("#convModal").modal('show');
            }
            localStorage.currentsection = 'copro';
        },
        watch: {

        },
        methods:{


        }
    };
</script>

<style scoped>

</style>