<template>
    <div>
        <div class="card">
            <h5 class="card-header">
                <div class="row">
                    <div class="col" v-if="section !=='ppdom' && section !=='ppdom_filoc'">
                       <p v-if="section === 'pppi'" class="pull-right small"> Zonages de référence :  {{cog_milesimes[0].text}}</p>
                       <p v-else class="pull-right small"> Zonages de référence :  {{cog_milesimes[2].text}}</p>
                    <!--<b-form-group class="col text-right small" label="Zonage territorial au 1<sup>er</sup> Janvier :">
                        <b-form-radio-group id="radio_millesim" v-model="millesime" :options="cog_milesimes" name="radioMillesime"></b-form-radio-group>
                    </b-form-group>-->
                        <br>
                    <h3><i class="fa fa-bar-chart" aria-hidden="true"></i> Région d'étude : <strong><span class="text-info">{{selectedReg}}</span> <span class="text-primary">{{selectedRegLabel}}</span></strong></h3>
                  </div>
                </div>
            </h5>
            <div class="card-body">
                <div v-if="!selectedReg">
                <b-form-select v-model="selectedReg" class="mb-3" >
                    <option value="" selected>Sélection d'une région d'étude</option>
                    <option v-for="reg in regs" :value="reg.codegeo">{{reg.label}} ({{reg.codegeo}})</option>
                </b-form-select>
                </div>
                <div v-if="selectedReg !=='' ">
                    <div class="row ">
                        <div class="col">
                            <button class="btn btn-sm btn-outline-primary" @click="resetReg()" v-if="(section !=='ppdom'&& section !=='ppdom_filoc') && regs.length > 1">
                              Selectionner une autre région <i class="fa fa-undo" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div class="col text-right">
                            <form :id="'displayForm'+section" method="post"  :action="actionPath">
                            <input type="hidden" name="_token" :value="csrf">
                            <input id="terrSelectedInput1" type="hidden" name="terrSelection1" :value="terrSelectionString1">
                            <input id="terrSelectedInput2" type="hidden" name="terrSelection2" :value="terrSelectionString2">
                            <input id="terrSelectedInput3" type="hidden" name="terrSelection3" :value="terrSelectionString3">
                            <input id="terrSelectedInput4" type="hidden" name="terrSelection4" :value="terrSelectionString4">
                            <input id="regCodeSelected" type="hidden" name="regCodeSelected" :value="selectedReg">
                            <input id="regLabelSelected" type="hidden" name="regLabelSelected" :value="selectedRegLabel">
                            </form>
                            <button id="btnvisualize" class="btn btn-primary" @click="displayData" >Visualiser les données <i class="fa fa-arrow-circle-right" aria-hidden="true"></i></button>
                            <b-popover  ref="popover2" placement="lefttop" target="btnvisualize" title=" 2 - AFFICHAGE">
                                <template slot="title">
                                    <b-btn @click="onClose" class="close text-white" aria-label="Close">
                                        <span class="d-inline-block" aria-hidden="true">&times;</span>
                                    </b-btn>
                                    Information :
                                </template>
                                <h6 class="">.... et visualiser les données synthétiques pour votre sélection</h6>
                            </b-popover>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col" id="col_territoires"><br>
                            <b-popover  ref="popover1" placement="topleft" target="select_terr1" title="1 - SÉLECTION">
                                <template slot="title">
                                    <b-btn @click="onClose" class="close text-white" aria-label="Close">
                                        <span class="d-inline-block" aria-hidden="true">&times;</span>
                                    </b-btn>
                                    Information :
                                </template>
                                <h6 class="">Définissez votre set de territoires ci dessous ...</h6>
                            </b-popover>

                            <select-one-terr id="select_terr1" order="1" :refScale="initCOG[0]" :section="section" :userRefTerr="userRefTerr"> </select-one-terr>
                            <select-one-terr id="select_terr2" order="2" :refScale="initCOG[1]" :section="section" :userRefTerr="userRefTerr"> </select-one-terr>
                            <select-one-terr id="select_terr3" order="3" :refScale="initCOG[2]" :section="section" :userRefTerr="userRefTerr"> </select-one-terr>
                            <select-one-terr id="select_terr4" order="4" :refScale="initCOG[3]" :section="section" :userRefTerr="userRefTerr"> </select-one-terr>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <fbm-rotate-spinner :test="isSpinning"></fbm-rotate-spinner>
    </div>
</template>
<script>
    import {PGService} from '../services/PGService';
    export default{
        props : ['userRegCode', 'userRegLabel', 'section'],
        data: function(){
            return {
                csrf: "",
                isSpinning: false,
                selectedReg: "",
                selectedRegLabel: "",
                display_info1: true,
                display_info2: false,
                displayPopus: true,
                millesime: '15',
                userRefTerr: null,
                cog_milesimes : [{text: "2020", value:"20", disabled: true }, {text: "2016", value:"16", disabled: false }, {text: "2020", value: "20", disabled: false},{text: "2022", value: "22", disabled: false}],
                initCOG: [],//["COM","EPCI","DEP","REG", "CANT", "ZE", "AU", "UU", "EPCIDOM", "DOM"],
                scales: [],
                regs : [],
                cogs : [],
                terrSelection: [
                    {level: 1, codegeo: null, scale: null, scaleLabel:null, label:null},
                    {level: 2, codegeo: null, scale: null, scaleLabel:null, label:null},
                    {level: 3, codegeo: null, scale: null, scaleLabel:null, label:null},
                    {level: 4, codegeo: null, scale: null, scaleLabel:null, label:null},
                ],
                terrSelectionString1 : '',
                terrSelectionString2 : '',
                terrSelectionString3 : '',
                terrSelectionString4 : '',
            };
        },
        mounted()
        {

            this.pgService = new PGService();
            this.csrf = window.csrfToken;

            this.selectedReg = String(this.userRegCode);
            this.selectedRegLabel = this.userRegLabel;

            if(localStorage.currentsection!=='pppi' && this.section==='pppi') {
                $("#convModal").modal('show');
            }else if(localStorage.currentsection!=='pprive' && this.section==='pprive'){
                $("#convModal").modal('show');
            }else if(localStorage.currentsection!=='ppdom_filoc' && this.section==='ppdom_filoc'){
              $("#convModal").modal('show');

            }


            /*
            /////////////////////////////////////////////
            /////////////// FOR tempo message ???////////
            /////////////////////////////////////////////
           // display infos on page load
            setTimeout((obj) => {
                this.$refs.popover1.$emit('open');
            }, 1000);
            setTimeout((obj) => {
                this.$refs.popover1.$emit('close');
                this.$refs.popover1.$emit('disable');
                this.$refs.popover2.$emit('open');
            }, 4000);
            setTimeout((obj) => {
                this.$refs.popover2.$emit('close');
                this.$refs.popover2.$emit('disable');
                this.$refs.popover1.$emit('disable');

            }, 7000);
            */
            // end diaplay infos on  page load


            switch(this.section)
            {
            case 'pppi':
                this.actionPath = '/pppi_affiche/1';
                this.initCOG = ["COM", "EPCI","DEP","ZE"];
                this.millesime = '22';
                this.cog_milesimes = [{text: "2022", value:"22", disabled: false }],
                this.scales = [
                    {codegeo: 'COM', label : 'Communes'},
                    {codegeo: 'EPCI', label : 'EPCI'},
                    {codegeo: 'DEP', label : 'Départements'},
                    //{codegeo: 'AU', label : 'Aires Urbaines'},
                    {codegeo: 'UU', label : 'Unités Urbaines'},
                    {codegeo: 'ZE', label : 'Zones d\'Emploi'},
                ];
                break;
            case 'ppdom_filoc':
              this.actionPath = '/ppdom_filoc_affiche/1';
              this.initCOG = ["COM" ,"EPCI","DEP","DOM" ];
              this.millesime = '22';
              this.cog_milesimes = [{text: "2022", value:"20", disabled: false }, {text: "2016", value:"16", disabled: false }, {text: "2022", value: "22", disabled: true}];
                this.scales = [
                {codegeo: 'COM', label : 'Communes'},
                {codegeo: 'EPCIDOM', label : 'EPCI'},
                {codegeo: 'DEP', label : 'Départements'},
                {codegeo: 'DOM', label : 'DOM(s)'},
              ];

              break;
            case 'ppdom':
                this.actionPath = '/ppdom_affiche/1';
                this.initCOG = ["COM","EPCIDOM","DEP","4DOM"];
                this.millesime = '22';
                this.cog_milesimes = [{text: "2022", value:"20", disabled: false }, {text: "2016", value:"16", disabled: false }, {text: "2022", value: "22", disabled: true}];

                this.scales = [
                    {codegeo: 'COM', label : 'Communes'},
                    {codegeo: 'EPCIDOM', label : 'EPCI'},
                    {codegeo: 'DEP', label : 'Départements'},
                    {codegeo: '4DOM', label : 'DOM(s)'},
                ];
                break;
            case 'pprive':
                this.actionPath = '/pprive_affiche/1';
                this.initCOG = ["COM","EPCI","DEP","FR"];
                this.millesime = '22';
                this.cog_milesimes = [{text: "2022", value:"20", disabled: false }, {text: "2016", value:"16", disabled: false }, {text: "2022", value: "22", disabled: true}];

                  this.scales = [
                    {codegeo: 'COM', label : 'Communes'},
                    {codegeo: 'CANT', label : 'Cantons'},
                    {codegeo: 'UU', label : 'Unités Urbaines'},
                    {codegeo: 'ZE', label : 'Zones d\'Emploi'},
                    {codegeo: 'EPCI', label : 'EPCI'},
                    {codegeo: 'DEP', label : 'Départements'},
                ];
                break;
            }

            this.prepareComp();
            // Recupération des données selectionnées dans le modul SelectOneTerr
            EventBus.$on('UPDATE_SELECTED_TER', (evt)=>{

                for(let i=0;i<this.terrSelection.length;i++){

                    if(this.terrSelection[i].level = evt.level){
                        this.terrSelection[i].codegeo = evt.codegeo;
                        this.terrSelection[i].scale = evt.scale;
                        this.terrSelection[i].scaleLabel = evt.scaleLabel;
                        this.terrSelection[i].label = evt.label;
                    }
                }

                this.stringifySelection();

            });
            EventBus.$on('UPDATE_REG', (evt)=>{
                this.userRefTerr = null;
            })
        },
        watch: {

            millesime: function(val){

                this.getCog('REG', val);
            },
            selectedReg: function(val){

                this.cogs = [];
                let that = this;
                this.userRefTerr = null;

                if(val && val!==''){
                    this.getAllCog();
                    let selectIndex = _.findIndex(this.regs, function(o) {
                        return String(o.codegeo) === String(that.selectedReg);
                    });
                    if(selectIndex && selectIndex>=0){

                        this.selectedRegLabel = this.regs[selectIndex].label;
                        let params = {'userLabelReg' :  this.selectedRegLabel, 'userCodeReg' :  this.regs[selectIndex].codegeo};
                        axios.post('/geodb/userTerr', params).then((result)=>{

                            //console.log('update user Terr : ', result.data);
                        });

                        EventBus.$emit('UPDATE_REG', {'code': val ,'nom':this.selectedRegLabel});
                    }
                }
            },
            terrSelection: function(val){
                this.stringifySelection();
            },
            regs: function(val){
                this.selectedReg = this.userRegCode;
            }
        },
        methods:{
            persist (){
                localStorage.currentsection = this.section;
            },
            onClose () {
                this.$refs.popover2.$emit('close');
                this.$refs.popover1.$emit('close');
                this.$refs.popover2.$emit('disable');
                this.$refs.popover1.$emit('disable');
                this.displayPopus = false;
            },
            prepareComp: function(){
                localStorage.currentsection = this.section;
                if(!this.reg){
                    if(this.millesime=='15'){
                        this.getOldReg(this.userRegCode);
                    }
                     this.getCog('REG', this.millesime);
                }else{
                    this.selectedReg = this.reg;
                }
                this. getUserStoredData();
            },
            getUserStoredData(){

                this.pgService.getSecteurGeo().then((result)=>{

                    let allRefs = result.data.data;
                    if(allRefs && allRefs!='' && allRefs!=undefined){

                        allRefs.forEach((elem) =>{
                            if(elem.section==this.section){
                                this.userRefTerr = elem;
                                return;
                            }else{
                                this.userRefTerr = null;
                            }
                            ;
                        });
                    };
                },(err)=>{ console.log('Pas de donnée user code terrs')});
            },
            resetReg: function(){
                this.selectedReg = "";
                this.selectedRegLabel = "";

            },
            stringifySelection: function(){

                for(let i=0;i<this.terrSelection.length;i++){

                    switch(this.terrSelection[i].level){
                    case '1':
                        this.terrSelectionString1 =  JSON.stringify(this.terrSelection[0]);
                        break;
                    case '2':
                        this.terrSelectionString2 =  JSON.stringify(this.terrSelection[1]);
                        break;
                    case '3':
                        this.terrSelectionString3 =  JSON.stringify(this.terrSelection[2]);
                        break;
                    case '4':
                        this.terrSelectionString4 =  JSON.stringify(this.terrSelection[3]);
                        break;
                    }
                }
            },
            getOneCOG: function(  id, scaleCode, millesime, regCode){


                this.pgService.get_cog(scaleCode, millesime, regCode, this.section ).then((result)=>{

                    this.cogs = _.concat(this.cogs, result.data.data);
                    this.count += 1;
                    (this.count == this.scales.length)?this.completeCOG():null;

                },(err)=>{ alert("pbm de récupération de données niveau "+id)});

            },
            completeCOG: function(){

                    this.cogs.push({'scale':'REG','codegeo': (this.selectedReg)?this.selectedReg:'', 'label': (this.selectedRegLabel)?this.selectedRegLabel:''});
                    this.cogs.push({'scale':'FR','codegeo': 'FR', 'label': 'France métropolitaine'});
                    this.cogs.push({'scale':'4DOM','codegeo': '4DOM', 'label': 'Ensemble des DOMs'});
                    this.cogs.push({'scale':'DOM','codegeo': 'DOM', 'label': 'Ensemble des DOMs'});
                    EventBus.$emit('END_GET_REF_COG',this.cogs);

                    this.isSpinning = false;
            },
            getAllCog: function(){

                this.isSpinning = true;
                this.count = 0;

                for(let i = 0; i < this.scales.length; i++) {

                    this.isSpinning = true;
                    this.getOneCOG(i, this.scales[i].codegeo, this.millesime, this.selectedReg);
                };
            },
            getOldReg(newReg){

                this.isSpinning = true;
                this.pgService.get_oldreg(newReg).then((result)=>{

                    this.regs = result.data.data;
                    this.selectedReg = this.userRegCode;
                    this.isSpinning = false;
                    this.updateRegLabel(this.userRegCode);
                }, (err) => {
                    alert("Un problème c'est produit avec votre requête.");
                    this.isSpinning = false;
                })
            },
            getCog(scale, millesime){

                this.isSpinning = true;
                this.pgService.get_cog(scale, millesime).then((result)=>{
                    this.regs = result.data.data;
                    this.selectedReg = this.userRegCode;
                    this.isSpinning = false;

                    this.updateRegLabel(this.userRegCode);

                }, (err) => {
                    alert("Un problème c'est produit avec votre requête.");
                    this.isSpinning = false;
                })
            },

            updateRegLabel: function(regCode){

                this.isSpinning = true;
                let that = this;

                let index = _.findIndex(this.regs, (o)=>{
                    return String(o.codegeo) == String(regCode);
                })
                if(index && index >=0){
                    this.selectedRegLabel = that.regs[index].label;
                    this.pgService.updateSecteurGeo(regCode,this.selectedRegLabel).then((result)=>{
                        this.isSpinning = false;
                    }, (err) => {
                       console.log('Pbm enregistrement ref region dans secteurGeo');
                        this.isSpinning = false;
                    });
                }
            },

            displayData: function(e){

                e.preventDefault();
                this.isSpinning = true;
                this.stringifySelection();
               document.getElementById('displayForm'+this.section).submit()
            }
        }
    }
</script>
<style>

    body{
        background-color:#fff;
    }
    .popover{
        z-index: 1000000;
        max-width: 450px;
        border-radius: 3px;
        box-shadow: 5px 5px 3px #a4a4a4;
    }
    .popover-header{
        background-color: #ce005d;
        color: #FFFF;
        font-size: 15px;
        font-weight: 200;
    }
    .popover-body {
        color: #706e7b;
        font-size: 20px;
    }


</style>
