import axios from "axios";

export default class CogApiService {
  getRegions() {
    let promise = new Promise((resolve, reject) => {
      axios
        .get("https://geo.api.gouv.fr/regions?fields=nom,code")
        .then((result) => {
          resolve(result.data);
        });

      return promise;
    });
  }

  async getRegByDep(dep) {
    let result = await axios.get(
      `https://geo.api.gouv.fr/departements/${dep}?fields=codeRegion`
    );
    return result.data;
  }

  async getDepByRegions(reg) {
    let result = await axios.get(
      `https://geo.api.gouv.fr/regions/${reg}/departements?fields=nom,code`
    );
    return result.data;
  }

  async getComByDep(dep) {
    let result = await axios.get(
      `https://geo.api.gouv.fr/departements/${dep}/communes?fields=nom,code`
    );
  }

  async getEpciByCom(com) {
    let result = await axios.get(
      `https://geo.api.gouv.fr/communes/${com}?fields=nom,code,codeEpci,codeDepartement,codeRegion`
    );
    return result.data;
  }

  async getComByEpci(epci) {
    let result = await axios.get(
      `https://geo.api.gouv.fr/communes/${com}/epci?fields=nom,code`
    );
    return result.data;
  }
}
