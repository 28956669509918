<template>
    <ol class="map-breadcrumb " :hidden=" refGeo.scale=='reg' ">
        <li class="breadcrumb-item"><a class="btn btn-outline-primary2 btn-sm rounded text-primary2" @click="reloadLevel('00000', 'reg', '')"><i class="fa fa-home" aria-hidden="true"></i></a></li>
        <li class="breadcrumb-item"><a class="btn btn-outline-primary2 btn-sm rounded text-primary2" @click="reloadLevel(refGeo.dep, 'dep', refGeo.dep)"> Dép.({{refGeo.dep}})</a></li>
        <li class="breadcrumb-item" :hidden="refGeo.scale == 'reg' || refGeo.scale == 'dep' || refGeo.epci==null" ><a class="btn btn-outline-primary2 btn-sm rounded text-primary2" @click="reloadLevel(refGeo.epci, 'epci', refGeo.dep)"> EPCI ({{refGeo.epci}})</a></li>
        <li class="breadcrumb-item" :hidden="refGeo.scale == 'reg' || refGeo.scale == 'dep' || refGeo.scale == 'epci'"><a class="btn btn-outline-primary2 btn-sm rounded text-primary2" @click="reloadLevel(refGeo.commune, 'com', refGeo.dep)">Com.({{refGeo.commune}})</a></li>
        <li class="breadcrumb-item" :hidden="refGeo.scale == 'reg' || refGeo.scale == 'dep' || refGeo.scale == 'epci' || refGeo.scale == 'com' "><a class="btn btn-outline-primary2 btn-sm rounded text-primary2" @click="reloadLevel(refGeo.sec, 'secc', refGeo.dep)">Section Cad.({{refGeo.sec}})</a></li>
    </ol>

</template>
<script>
    export default{
        props: ['refGeo'],
        data () {
            return {
            }
        },
        mounted(){
            //console.log(this.refGeo);
        },
        methods:{
            reloadLevel: function(level, scale, dep){
                EventBus.$emit('RELOAD_LEVEL', {level:level,scale:scale,dep:dep});
            }
        }
    }

</script>
<style>
    .map-breadcrumb>li{
        display: inline-block;
    }
    li.breadcrumb-item + li.breadcrumb-item::before {

        display: inline-block;
        font-family: FontAwesome;
        padding-right: 0.1rem;
        padding-left: 0.1rem;
        color: #51564b;
        content: "\F0DA";
        font-size: 1.1em;

    }
    li.breadcrumb-item .btn.btn-primary{
        color: #FFF;
    }

</style>