/**
 * Created by fbmfbm on 21/12/2016.
 */

export class GeoJsonService {

    //protected pgGeoDataDB: string;
    //protected headers: Headers;

    constructor() {

    this.pgGeoDataDB = 'anah_pp';
    this.headers = new Headers({ 'Content-Type': 'application/json' });
    }
    ////// BUILD GENERALE QUERY FOR POSTGRES /////////
    buildSqlQuerry(refCode , refScale , refDep = '', section=null ) {

        let tableCartoPPPI = 'pppi_carto19';
        let tableCartoPPDOM = 'ppdom_carto14';
        let query = {propQuery: '', geomQuery: '', filterQuery: ''};
        //console.log('test', refScale);
        switch (refScale) {
        case 'reg':
            query.propQuery = ' SELECT \'dep\' AS scale, \'Département\' AS scalelabel, codegeo as dep, codegeo AS code, libgeo AS label ';
            query.geomQuery = ' ST_AsGeoJSON(ST_TRANSFORM(lg.geom,3857),4)::json As geometry, ';
            if(section=='pppi' ){
                query.filterQuery = ' FROM geodep15 As lg WHERE reg in (\''+refCode+'\') AND geom IS NOT NULL ORDER BY libgeo ';
            }else{
                query.filterQuery = ' FROM geodep15 As lg WHERE old_reg in (\''+refCode+'\') AND geom IS NOT NULL ORDER BY libgeo ';
            }
            break;
        case 'dom':
            query.propQuery = ' SELECT \'dep\' AS scale, \'DOM()\' AS scalelabel, codegeo as dep, codegeo AS code, libgeo AS label ';
            query.geomQuery = ' ST_AsGeoJSON(ST_TRANSFORM(lg.geom,3857),4)::json As geometry, ';
            query.filterQuery = ' FROM geodep15 As lg WHERE old_reg in (\'01\', \'02\', \'03\', \'04\',  \'06\') AND geom IS NOT NULL  ORDER BY libgeo ';
            break;
        case 'dep':
            if(section=='ppdom' ){
                query.propQuery = ' SELECT \'com\' AS scale, \'Commune\' AS scalelabel, dep as dep, insee AS code, nom AS label, a91, a183, calc_dom2014_a1_dom2014_a91, calc_dom2014_a2_dom2014_a91, calc_dom2014_a3_dom2014_a91, tx_dom2014_a93,  tmm_dom2014_a181_dom2014_a91, calc_dom2014_a131_dom2014_a91, calc_dom2014_a136_dom2014_a91, calc_dom2014_a141_dom2014_a91  ';
                query.geomQuery = ' ST_AsGeoJSON(ST_TRANSFORM(lg.geom,3857),5)::json As geometry, ';
                query.filterQuery = ' FROM geocom15_'+ refDep +' As lg LEFT JOIN '+tableCartoPPDOM+' as n on lg.insee = substring(n.codgeo,1,5)  WHERE dep in (\'' + refDep + '\') AND lg.geom IS NOT NULL   ORDER BY nom ';
            }else{
                query.propQuery = ' SELECT \'com\' AS scale, \'Commune\' AS scalelabel, dep as dep, insee AS code, nom AS label, pppi1_a1, pppi1_a2, pppi1_a4, pppi1_a16, pppi1_a53, pppi1_a72, pppi1_a88, pppi1_a92, pppi1_a96, pppi1_a188, pppi1_a4_cadrage_a68, pppi1_a1_pppi1_a4, pppi1_a2_pppi1_a4, pppi1_a16_pppi1_a4 ,pppi1_a53_pppi1_a4, pppi1_a72_pppi1_a4, pppi1_a8_pppi1_a4, pppi1_a60_pppi1_a4, pppi1_a88_pppi1_a4 ';
                query.geomQuery = ' ST_AsGeoJSON(ST_TRANSFORM(lg.geom,3857),5)::json As geometry, ';
                query.filterQuery = ' FROM geocom16 As lg LEFT JOIN '+tableCartoPPPI+' as n on lg.insee = n.codgeo  WHERE dep in (\'' + refDep + '\') AND lg.geom IS NOT NULL   ORDER BY nom ';
            }
            break;
        case 'com':
                query.propQuery = '  SELECT \'secc\' AS scale, \'Sec. Cadastrale.\' AS scalelabel,id_section AS code, section AS label, code_dep as dep   ';
                query.geomQuery = ' ST_AsGeoJSON(ST_TRANSFORM(lg.geom,3857))::json As geometry, ';
                query.filterQuery = '  FROM sec_cad_idf_2013 As lg LEFT JOIN idf_pdl_indicateurs_1_2 as n on lg.id_section = substring(n.idpdl,1,10) LEFT JOIN idf_pdl_result as p on p.idpdl = n.idpdl WHERE code_insee like \'' + refCode + '\' AND lg.geom IS NOT NULL group by substring(n.idpdl,1,10), lg.geom, lg.id_section, lg.section, lg.code_dep    ORDER BY id_section ';
            break;
        case 'epci':
            if(section=='ppdom' ) {
                query.propQuery = '  SELECT \'epci\' AS scale, \'EPCI\' AS scalelabel, codepci AS code, libepci AS label, dep as dep, a91, a183, calc_dom2014_a1_dom2014_a91, calc_dom2014_a2_dom2014_a91, calc_dom2014_a3_dom2014_a91, tx_dom2014_a93,  tmm_dom2014_a181_dom2014_a91, calc_dom2014_a131_dom2014_a91, calc_dom2014_a136_dom2014_a91, calc_dom2014_a141_dom2014_a91  ';
                query.geomQuery = ' ST_AsGeoJSON(ST_TRANSFORM(t1.geom,3857))::json As geometry, ';
                //query.filterQuery = '  FROM ( SELECT e."EPCI" as codepci, "LIBEPCI" as libepci,"DEP" as dep, ST_UNION(geom) as geom from cog_epci15 as e JOIN geocom15 as lg on e."CODGEO" = lg.codegeo WHERE "DEP" = \'' + refDep + '\' GROUP BY e."EPCI", "LIBEPCI", "DEP") t1 LEFT JOIN ' + tableCartoPPPI + ' as c on t1.codepci = substring(c.codgeo,1,9) ';
                query.filterQuery = '  FROM ( select "EPCI" as codepci, "LIBEPCI" as libepci, dep, reg, ST_UNION(geom) as geom from cog_epci15 as c LEFT JOIN (\n' + 'SELECT * from geocom15_'+ refDep +' ) d on c."CODGEO" = d.insee  WHERE  "CODGEO" like \'97%\' AND "LIBEPCI" <> \'Sans objet\' and geom is not null GROUP BY codepci, libepci, dep, reg) t1 LEFT JOIN '+tableCartoPPDOM+'  as c on t1.codepci = substring(c.codgeo,1,9) ';
            }else if (section=='pppi') {
                query.propQuery = '  SELECT \'epci\' AS scale, \'EPCI\' AS scalelabel, codepci AS code, libepci AS label, dep as dep, pppi1_a1, pppi1_a2, pppi1_a4, pppi1_a16, pppi1_a53, pppi1_a72, pppi1_a88, pppi1_a92, pppi1_a96, pppi1_a188, pppi1_a4_cadrage_a68, pppi1_a1_pppi1_a4, pppi1_a2_pppi1_a4, pppi1_a16_pppi1_a4 ,pppi1_a53_pppi1_a4, pppi1_a72_pppi1_a4, pppi1_a8_pppi1_a4, pppi1_a60_pppi1_a4, pppi1_a88_pppi1_a4  ';
                query.geomQuery = ' ST_AsGeoJSON(ST_TRANSFORM(t1.geom,3857))::json As geometry, ';
                query.filterQuery = '  FROM ( SELECT e."EPCI" as codepci, "LIBEPCI" as libepci,"DEP" as dep, ST_UNION(geom) as geom from cog_epci20 as e JOIN geocom20 as lg on e."CODGEO" = lg.codegeo WHERE "DEP" = \'' + refDep + '\' GROUP BY e."EPCI", "LIBEPCI", "DEP") t1 LEFT JOIN ' + tableCartoPPPI + ' as c on t1.codepci = substring(c.codgeo,1,9) ';
            }else {
                query.propQuery = '  SELECT \'epci\' AS scale, \'EPCI\' AS scalelabel, codepci AS code, libepci AS label, dep as dep, pppi1_a1, pppi1_a2, pppi1_a4, pppi1_a16, pppi1_a53, pppi1_a72, pppi1_a88, pppi1_a92, pppi1_a96, pppi1_a188, pppi1_a4_cadrage_a68, pppi1_a1_pppi1_a4, pppi1_a2_pppi1_a4, pppi1_a16_pppi1_a4 ,pppi1_a53_pppi1_a4, pppi1_a72_pppi1_a4, pppi1_a8_pppi1_a4, pppi1_a60_pppi1_a4, pppi1_a88_pppi1_a4  ';
                query.geomQuery = ' ST_AsGeoJSON(ST_TRANSFORM(t1.geom,3857))::json As geometry, ';
                query.filterQuery = '  FROM ( SELECT e."EPCI" as codepci, "LIBEPCI" as libepci,"DEP" as dep, ST_UNION(geom) as geom from cog_epci15 as e JOIN geocom15 as lg on e."CODGEO" = lg.codegeo WHERE "DEP" = \'' + refDep + '\' GROUP BY e."EPCI", "LIBEPCI", "DEP") t1 LEFT JOIN ' + tableCartoPPPI + ' as c on t1.codepci = substring(c.codgeo,1,9) ';
            }
                break;
        case 'secc':
            query.propQuery = ' SELECT \'pars\' AS scale, \'Parcelle\' AS scalelabel, COALESCE(pdl.idpdl,lg.idpar) AS code, LTRIM(pdl.dnvoiri,\'0\') || \' \'||pdl.dvoilib AS label, pdl.idpdl,\'' + refDep + '\' as dep, note_indic_th as moy_note_th, note_indic_th as tot_note_th, nb_transac_indic_marche::DEC as nb_transac, globale_note_indic_marche::dec as tot_note_marche,  round((globale_note_indic_marche::dec)/(nb_transac_indic_marche::DEC),1) as moy_note_marche, sum_notes_1_2::dec as moy_note_1_2    ';
            query.geomQuery = ' ST_AsGeoJSON(ST_TRANSFORM(lg.geom,3857))::json As geometry, ';
            query.filterQuery = ' FROM SELECT parcel_' + refDep + '_sm  As lg left join idf_pdl_result AS pdl ON lg.idpar = pdl.idpar LEFT JOIN idf_pdl_indicateurs_1_2 as n on lg.idpar= substring(n.idpdl,1,14) WHERE idsec like \'' + refCode + '\' AND lg.geom IS NOT NULL ';
            break;
        }

        return query;
    }

    /////////// BUILD QUERY FOR SYNTHESE DATA /////////
    buildSynteseQuerry(refCode , refScale , refDep = '' ){
        let query = {propQuery: '', geomQuery: '', filterQuery: ''};
        switch (refScale) {
            case 'reg':
                //query.propQuery = ' SELECT \'dep\' AS scale, \'Département\' AS scalelabel, codegeo as dep, codegeo AS code, libgeo AS label ';
                //query.geomQuery = ' ST_AsGeoJSON(ST_TRANSFORM(lg.geom,3857),4)::json As geometry, ';
                //query.filterQuery = ' FROM geodep15 As lg WHERE reg in (\'11\') AND geom IS NOT NULL  ORDER BY libgeo ';
                break;
            case 'dep':
                query.propQuery = ' SELECT x\'com\' AS scale, \'Commune\' AS scalelabel, dep as dep, insee AS code, nom AS label,max(note_indic_th) as max_note_th,min(note_indic_th) as max_note_th, round(avg(note_indic_th),1) as moy_note_th, sum(note_indic_th) as tot_note_th, sum(nb_transac_indic_marche::DEC) as nb_transac, count(distinct idpdl) as nbr_copro, max(globale_note_indic_marche::dec) as max_note_marche, min(globale_note_indic_marche::dec) as min_note_marche, round(sum(globale_note_indic_marche::dec)/sum(nb_transac_indic_marche ::DEC),1) as moy_note_marche, sum(globale_note_indic_marche::dec) as tot_note_marche, round(avg(sum_notes_1_2::dec),1) as moy_note_1_2 ';
                query.geomQuery = ' ST_AsGeoJSON(ST_TRANSFORM(lg.geom,3857),5)::json As geometry, ';
                query.filterQuery = ' FROM geocom15 As lg LEFT JOIN idf_pdl_indicateurs_1_2 as n on lg.insee = n.idcom LEFT JOIN idf_pdl_result as p on p.idpdl = n.idpdl WHERE dep in (\'' + refDep + '\') AND lg.geom IS NOT NULL group by n.idcom, lg.geom, lg.dep, lg.insee, lg.nom ORDER BY nom ';
                break;
            case 'epci':
                break;
            case 'com':
                break;
            case 'secc':
                break;

        }

        return query;

    }

    getTerritory(refCode , refScale , refDep  = '', isFiltered=true, section ) {
        let query = this.buildSqlQuerry(refCode, refScale, refDep, section );

        let params = {
            isFiltered:isFiltered,
            refCode: refCode,
            refScale: refScale,
            refDep: refDep,
            propQuery: query.propQuery,
            filterQuery: query.filterQuery,
            geomQuery: query.geomQuery
        };

        return axios.post('/geodb', params);
    }

    getSynthData(refCode, refScale,refDep='', isFiltered=true){

        //let query = this.buildSynteseQuerry(refCode, refScale, refDep);

        //console.log('test 1');

        let params = {
            isFiltered:isFiltered,
            refCode: refCode,
            refScale: refScale,
            refDep: refDep,
            refGeo: null

        }
        return axios.post('/geodb/synth', params);
    }


    getTerritoryFromBound(minX, minY, maxX, maxY, scale, refDep, refInsee) {

        let params = {
            minX: minX,
            minY: minY,
            maxX: maxX,
            maxY: maxY,
            scale:scale,
            refDep:refDep,
            refInsee:refInsee,
        };

        return axios.post('/geodb/bound', params, this.headers);
    }


    getTerrFromCodes(codes) {
        //console.log(codes);
        let params = {};

        return axios.post('/cartodata', params,  this.headers);
    }


    findTerritoryForSearch(code, type){
        let params = {
            code: code,
            type: type,
        };
         return axios.post('geodb/findTerrRef', params, this.headers);
    }


}



