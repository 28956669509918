<template>
    <div v-if="millesime" class="d-flex flex-column my-5 container">
        <h3 class="col d-flex " v-if="terrCodeSelected && terrLabelSelected && terrNameRef"> Territoire d'étude :
            <span v-if="multiCodes" class="text-primary ml-1"> {{ terrLabelTitleSelected }} ({{ multiCodes.toString()
                }})
            </span>
            <span v-else class="text-primary ml-1"> {{ terrLabelTitleSelected }} ({{ terrCodeSelected }})
                {{ terrLabelSelected }}
            </span>
        </h3>

        <div class="col d-flex flex-column justify-content-between ">
            <h4 class="mb-5">Quelques données Synthètiques <span style="font-size:1rem;">
                    ( <span class="text-primary">{{ millesimeRef.title }}</span> )</span>
            </h4>
            <h5 class="font-weight-bold text-secondary">
                Votre territoire :
                <span v-if="terrLevelSelected === 'multi_dep'" class="text-primary">Département</span>
                <span v-else-if="terrLevelSelected === 'multi_com'" class="text-primary">Commune </span>
                <span v-else class="text-primary">{{ terrLabelSelected }}</span>
                ({{ terrCodeSelected }})
            </h5>
        </div>
        <Loading v-if="loading" />
        <div v-if="stats.length && stats.length > 1" class="row no-gutters row-cols-1 row-cols-md-2 row-cols-lg-6">
            <div v-for="stat in stats" :key="stat.id" :class="`${stat.is_double ? 'col col-lg-4' : 'col'} p-1`">
                <CoproffBlocStat :outlined="false" :double="stat.is_double" :stattype="stat.typeref" :title="stat.title"
                    :ref-indic="stat.ref" :src="stat.src" :color="stat.color" :val="stat.val.refData" />
            </div>
        </div>
        <div class="row h-4">
            <div class="col d-flex flex-column flex-md-row justify-content-between align-items-center mt-1 mb-5">
                <div class="d-flex align-items-center mb-3 sm:mb-0">
                    <div class="btn btn-sm btn-outline-primary coproff-csv-btn mr-2" @click="dowloadDataAsCSVFile">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
                            <path fill="currentColor"
                                d="M15 9H5V5h10m-3 14a3 3 0 0 1-3-3a3 3 0 0 1 3-3a3 3 0 0 1 3 3a3 3 0 0 1-3 3m5-16H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V7l-4-4Z" />
                        </svg>
                    </div>
                    <div class="text-sm">Télécharger ces données de cadrage</div>
                </div>
                <a href="/coproff_export/1" class="btn btn-primary">
                    <i class="fa fa-cloud-download text-white mr-1" aria-hidden="true"></i>
                    Télécharger l'intégralité des données pour votre territoire
                </a>
            </div>
        </div>

        <div class="d-flex content-justify-start align-items-center">
            <h5 class="font-weight-bold text-secondary mt-4 col-4">
                Territoir de référence : <span class="text-primary">{{ terrNameRef }}</span>
            </h5>
            <select v-if="listTerrs" class="form-control font-weight-bold " v-model="selectedCodeTerr"
                @change="handleRefChange">
                <option>Selectionnez un territoire</option>
                <option v-for="(terr, index) in listTerrs" :key="index" :value="terr.code">{{ terr.nom }} ({{ terr.code
                    }})
                </option>
            </select>
        </div>
        <Loading v-if="loading2" />
        <div v-else-if="stats2.length && stats2.length > 1"
            class="row no-gutters row-cols-1 row-cols-md-2 row-cols-lg-6">
            <div v-for="statVal in stats2" :key="statVal.id"
                :class="`${statVal.is_double ? 'col col-md-2 col-lg-4' : 'col'} p-1`">
                <CoproffBlocStat :outlined="true" :double="statVal.is_double" :stattype="statVal.typeref"
                    :ref-indic="statVal.ref" :src="statVal.src" :title="statVal.title" :color="statVal.color"
                    :val="statVal.val.refData" />
            </div>
        </div>
    </div>
    <div v-else class="d-flex flex-column my-5 container">
        <p>Désolé. Les données ne sont actuellement pas disponible</p>
    </div>
</template>

<script>
import CoproffBlocStat from './CoproffBlocStat.vue';
import Loading from '../Loading.vue';
import CoproffService from '../../services/CoproffService';
import CogApiService from '../../services/CogApiService';

//const coproffService = new CoproffService();
const cogApiService = new CogApiService();

export default {
    name: "coproff_stats",
    //props: ['tag', 'dfperims', 'terrLevelSelected', 'terrCodeSelected', 'terrLabelSelected', 'millesime', 'section'],
    props: ['tag', 'dfperims', 'terrLevelSelected', 'terrCodeSelected', 'terrLabelSelected', 'millesime', 'section'],
    components: {
        CoproffBlocStat,
        Loading
    },
    data: function () {
        return {
            loading: false,
            loading2: false,
            terrRef: [
                {
                    "code": '11',
                    "label": 'France',
                    "level": 'fra'
                }
            ],
            data: [],
            data2: [],
            stats: [],
            stats2: [],
            listTerrs: [],
            selectedCodeTerr: '',
            terrLevelRef: '',
            terrNameRef: '',
            millesimeRef: '',
            refsReference: [],
            refsVal: null,
            refsVal2: null,
            coproffService: null,
            userPerims: null,
            terrLabelTitleSelected: null,
            ept_coms: null,
            multiCodes: null,
            //terrLevelSelected: null,
            //terrCodeSelected: null,
            //terrLabelSelected: null,
            purData: null
        }
    },
    mounted() {
        this.coproffService = new CoproffService();
        this.millesimeRef = JSON.parse(this.millesime);

        this.userPerims = JSON.parse(this.dfperims);
        console.log("dfperims : ", this.userPerims, this.terrLevelSelected, this.terrCodeSelected, this.terrLabelSelected);
        this.buildDFPerims(this.userPerims);

        if (localStorage.currentsection !== 'coproff' && this.section === 'coproff') {
            $("#convModal").modal('show');
            localStorage.currentsection = 'coproff';
        }
        this.coproffService.getRefs().then(result => {
            this.refsReference = Array.from(JSON.parse(JSON.stringify(result)));
            this.refsVal = Array.from(JSON.parse(JSON.stringify(result)));
            this.refsVal2 = Array.from(JSON.parse(JSON.stringify(result)));
            this.buildUserLevel();
        })
    },
    watch: {},
    methods: {
        persist() {
            localStorage.currentsection = this.section;
        },
        buildDFPerims: function (refPerims) {
            console.log("buildDFPerims(refPerims)", refPerims, this.terrLevelSelected);


            let perims = { "level": null, "code": null };
            //if ("com_by_dep" in refPerims && Object.keys(refPerims)[0] && Array.isArray(Object.values(refPerims)[0]) && Object.values(refPerims)[0].length > 0) {
            if ("reg_complet" in refPerims && Array.isArray(refPerims.reg_complet) && refPerims.reg_complet.length > 0) {
                if (refPerims.reg_complet[0] === "999") {
                    this.terrLevelSelected = "fra";
                    this.terrCodeSelected = "fra";
                    this.terrLabelTitleSelected = "";
                    return { "level": 'fra', "code": 'fra', "label": "France entière" };
                } else {
                    this.terrLevelSelected = "reg";
                    this.terrCodeSelected = refPerims.reg_complet[0];
                    this.terrLabelTitleSelected = "Région ";
                    return { "level": 'reg', "code": refPerims.reg_complet[0], "label": "Régions" };
                }
            } else if ("dep_complet" in refPerims && Array.isArray(refPerims.dep_complet) && refPerims.dep_complet.length > 0) {
                console.log("test :", "dep_complet" in refPerims && Array.isArray(refPerims.dep_complet) && refPerims.dep_complet.length > 0)
                if (refPerims.dep_complet.length > 1) {
                    this.terrLevelSelected = "multi_dep";
                    this.terrLabelTitleSelected = "Multi départemental "
                    this.multiCodes = refPerims.dep_complet;
                    this.terrCodeSelected = refPerims.dep_complet[1];
                } else {
                    this.terrLevelSelected = "dep";
                    this.terrLabelTitleSelected = "Département ";
                    this.terrCodeSelected = refPerims.dep_complet[0];
                }

                return { "level": 'dep', "code": refPerims.dep_complet[0], "label": "Département" };
            } else if ("comm_by_dep" in refPerims && Object.keys(refPerims.comm_by_dep)[0] && Array.isArray(refPerims.comm_by_dep[Object.keys(refPerims.comm_by_dep)[0]])) {
                let coms = [];
                Object.keys(refPerims.comm_by_dep).forEach((keys) => {
                    //console.log(keys);
                    //coms = [...coms, refPerims.comm_by_dep[keys]];
                    //let comsCodes = refPerims.comm_by_dep[keys].map((com) => com.code);
                    coms = [].concat(coms, refPerims.comm_by_dep[keys]);
                });
                this.terrLevelSelected == 'ept' ? this.ept_coms = coms : null;
                return {
                    "level": coms.length > 1 ? 'epci' : 'com',
                    "code": coms.length > 1 ? coms[0] : [],
                    "label": coms.length > 1 ? "Communes " : "Commune"
                };
            } else {
                //console.log("Rien");
                return null;
            }
        },
        buildUserLevel: function () {
            //console.log('CoproffStats.vue buildUserLevel', this.terrLevelSelected, this.terrCodeSelected);
            switch (this.terrLevelSelected) {
                case 'fra':
                    axios.get("https://geo.api.gouv.fr/regions?fields=nom,code")
                        .then((result) => {
                            console.log("regs", result.data);
                            this.listTerrs = result.data;
                        })
                    this.getData('11', 'reg', 2);
                    this.selectedCodeTerr = '11';
                    this.terrLevelRef = "reg";
                    this.terrNameRef = "Région";
                    this.terrLabelSelected = "France entière";
                    this.getData(this.terrCodeSelected, this.terrLevelSelected, 1);
                    break;
                case 'reg':
                    this.terrCodeSelected = (this.terrCodeSelected.length === 1) ? '0' + this.terrCodeSelected : this.terrCodeSelected;
                    axios.get(`https://geo.api.gouv.fr/regions/${this.terrCodeSelected}/departements?fields=nom,code,region`)
                        .then((result) => {
                            console.log("reg result", result.data);
                            this.terrLabelSelected = result.data[0].region.nom;// nom de la région 
                            this.listTerrs = result.data;
                            this.selectedCodeTerr = result.data[0].code;
                            this.getData(result.data[0].code, 'dep', 2);
                        })

                    this.terrLevelRef = "dep";
                    this.terrNameRef = "Département";
                    this.getData(this.terrCodeSelected, this.terrLevelSelected, 1);
                    break;
                case 'dep':
                    this.terrCodeSelected = (this.terrCodeSelected.length === 1) ? '0' + this.terrCodeSelected : this.terrCodeSelected;
                    axios.get(`https://geo.api.gouv.fr/departements/${this.terrCodeSelected}?fields=codeRegion,region`)
                        .then((result) => {
                            console.log("api result : ", result.data);
                            this.listTerrs = [
                                {
                                    "code": result.data.region.code,
                                    "nom": result.data.region.nom
                                }
                            ];
                            this.selectedCodeTerr = result.data.region.code;
                            this.terrLabelSelected = result.data.nom;
                            this.getData(result.data.region.code, 'reg', 2);
                        })
                    this.terrLevelRef = "reg";
                    this.terrNameRef = "Région";
                    this.getData(this.terrCodeSelected, this.terrLevelSelected, 1);
                    break;
                case 'multi_dep':
                    this.terrCodeSelected = (this.terrCodeSelected.length === 1) ? '0' + this.terrCodeSelected : this.terrCodeSelected;
                    this.userPerims.dep_complet.forEach(element => {
                        axios.get(`https://geo.api.gouv.fr/departements/${element}?fields=nom,code`)
                            .then((result) => {
                                this.listTerrs = [
                                    ...this.listTerrs,
                                    {
                                        "code": result.data.code,
                                        "nom": result.data.nom
                                    }
                                ]
                            })
                            .finally(() => {
                                if (this.listTerrs.length === this.userPerims.dep_complet.length) {
                                    this.listTerrs = this.listTerrs.sort((a, b) => a.code > b.code ? 1 : -1);
                                    this.selectedCodeTerr = this.listTerrs[0].code;
                                    this.getData(this.selectedCodeTerr, 'dep', 2);
                                }
                            })
                    });
                    this.terrLevelRef = "dep";
                    this.terrNameRef = "Département";
                    this.getData(this.terrCodeSelected, this.terrLevelSelected, 1);
                    break;
                case 'epci':
                    axios.get(`https://geo.api.gouv.fr/epcis/${this.terrCodeSelected}/communes?fields=code,nom`)
                        .then((result) => {
                            console.log("epci", result.data);
                            this.listTerrs = result.data;
                            this.selectedCodeTerr = this.listTerrs[0].code;
                            //this.terrLabelSelected = this.listTerrs[0].nom;
                            this.getData(this.selectedCodeTerr, 'com', 2);
                        })
                    this.terrLevelRef = "com";
                    this.terrNameRef = "Communes";
                    this.getData(this.terrCodeSelected, this.terrLevelSelected, 1);
                    break;
                case 'ept':
                    this.listTerrs = [];
                    this.ept_coms.forEach((com, index) => {
                        axios.get(`https://geo.api.gouv.fr/communes/${com}?fields=code,nom`)
                            .then((result) => {

                                this.listTerrs = [...this.listTerrs,
                                {
                                    "code": result.data.code,
                                    "nom": result.data.nom
                                }
                                ];

                                if (index === this.ept_coms.length - 1) {
                                    //console.log("listTerrs", this.listTerrs);
                                    this.selectedCodeTerr = this.listTerrs[0].code;
                                    this.getData(this.selectedCodeTerr, 'com', 2);
                                }
                            })

                    })
                    this.terrLevelRef = "com";
                    this.terrNameRef = "Communes";
                    this.getData(this.terrCodeSelected, this.terrLevelSelected, 1);
                    break;
                case 'com':
                    this.terrCodeSelected = (this.terrCodeSelected.length === 4) ? '0' + this.terrCodeSelected : this.terrCodeSelected;
                    axios.get(`https://geo.api.gouv.fr/communes/${this.terrCodeSelected}?fields=departement`)
                        .then((result) => {
                            console.log("result com : ", result.data);
                            this.listTerrs = [
                                {
                                    "code": result.data.departement.code,
                                    "nom": result.data.departement.nom
                                }
                            ];
                            this.selectedCodeTerr = result.data.departement.code;
                            this.terrLabelSelected = result.data.nom;
                            this.getData(result.data.departement.code, 'dep', 2);
                        })
                    this.terrLevelRef = "dep";
                    this.terrNameRef = "Département"
                    this.getData(this.terrCodeSelected, this.terrLevelSelected, 1);
                    break;
                default:
                    break;
            }
        },
        getData: function (inseeCode, terrLevel, order = 1) {
            order === 1 ? this.loading = true : null;
            order === 2 ? this.loading2 = true : null;
            let that = this;
            axios.post('/api/coproff/stats', { "code": inseeCode, "level": terrLevel }).then((result) => {
                if (order === 1) {
                    this.purData = result.data;
                    this.stats = that.coproffService.mergeData(result.data, that.refsVal, order)
                } else {
                    this.stats2 = that.coproffService.mergeData(result.data, that.refsVal2, order)
                }
            }).catch((error) => {
                console.log('CoproffStats.vue getData error', error);
            }).finally(() => {
                order === 1 ? this.loading = false : null;
                order === 2 ? this.loading2 = false : null;
            });
        },
        dowloadDataAsCSVFile: function () {

            let text = "";
            for (const [key, value] of Object.entries(this.purData)) {
                console.log(key, value);
                text += `${key};${value}\n`;
            }
            let filename = 'coproff_tabl_cadrage_' + this.terrCodeSelected + '.csv';
            let element = document.createElement('a');
            element.setAttribute('href', 'data:application/csv;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', filename);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
        handleRefChange: function () {
            console.log('CoproffStats.vue handleRefChange', this.selectedCodeTerr);
            this.stats2 = Array.from(JSON.parse(JSON.stringify(this.refsReference)));
            this.getData(this.selectedCodeTerr, this.terrLevelRef, 2);
        }
    }
};
</script>

<style scoped>
.coproff-csv-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    border-radius: 3px;
    transition: background-color 0.15s ease-in-out;
}
</style>