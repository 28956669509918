<template>
    <div @mouseenter="onMouseOver" class="fbm-v100">
        <div v-if="isEnabled" class="card text-white mb-3 fbm-v100" :class="ref[refIndex].bgColor">
            <div class="card-header">{{ ref[refIndex].subtitle }}</div>
            <img class="card-img-top" :src="ref[refIndex].imgLink" alt="Card image cap">
            <div class="card-body">
                <h4 class="card-title" v-html="ref[refIndex].title"></h4>
                <br>
                <div class="text-center">
                    Accès en ligne <a class="btn btn-outline-claire dark" data-toggle="tooltip" data-placement="top"
                        title="Acceder à l'outil" :href="ref[refIndex].link1">
                        <i class="fa fa-arrow-circle-right fa-3x" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
            <div class="card-footer text-center">
            </div>
        </div>
        <div v-if="!isEnabled" class="card text-white mb-3 inactive-outils fbm-v100">
            <div class="card-header">{{ ref[refIndex].subtitle }}</div>
            <div class="card-body text-center">
                <h4 class="card-title text-primary"><i class="fa fa-ban fa-3x text-primary text-center"
                        aria-hidden="true"></i>
                    <br><span class="font-weight-bold text-primary2" v-html="ref[refIndex].titleDiseabled"></span><br>
                    Vous n'avez pas <br> les droits suffisants <br> pour accéder à cet outil
                </h4>
                <br>
                <p>Le statut de votre compte <br> ne vous permet pas d'accéder <br> à cette section de l'application. <br>
                </p>
                <a class="btn btn-outline-primary " :href="ref[refIndex].convLink">Demander un droit d'accès <i
                        class="fa fa-arrow-circle-o-right" aria-hidden="true"></i></a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "tools-big-btn",
    props: ['section'],
    data() {
        return {
            isEnabled: true,
            ref: [
                {
                    title: 'Métropole 2019<br>',
                    titleDiseabled: 'Métropole 2019',
                    subtitle: 'SYNTHÈSE PARC PRIVÉ',
                    link1: '/pprive',
                    convLink: '/convs/pp',
                    imgLink: 'images/ppriv_metro.png',
                    footer: '',
                    bgColor: 'bg-primary'
                },
                {
                    title: 'PPPI 2019<br><br>',
                    titleDiseabled: 'PPPI 2019<br>',
                    subtitle: 'PPPI 2019',
                    link1: '/pppi_actu',
                    convLink: '/convs/pppi',
                    imgLink: 'images/pppi17.png',
                    footer: '',
                    bgColor: 'bg-info'
                },
                {
                    title: 'DOM 2019<br>',
                    titleDiseabled: 'DOM 2019',
                    subtitle: 'SYNTHÈSE PARC PRIVÉ',
                    link1: '',
                    convLink: '/convs/ppdom',
                    imgLink: 'images/ppriv_dom.png',
                    footer: '',
                    bgColor: 'bg-primary'
                },
                {
                    title: 'COPROPRIÉTÉS <br>FRAGILES 2019',
                    titleDiseabled: 'COPROPRIÉTÉS <br>FRAGILES 2019',
                    subtitle: 'Copropriétés Fragiles 2019',
                    link1: '/copro_actu',
                    convLink: '/convs/copro',
                    imgLink: 'images/copro17.png',
                    footer: '',
                    bgColor: 'bg-taupe2'
                },
                {
                    title: 'COPROFF',
                    titleDiseabled: 'RÉFÉRENTIEL <br>DES COPROPRIÉTÉS <br>ET DES DONNÉES <br> FONCIÈRES',
                    subtitle: 'CoproFF',
                    link1: '/coproff_actu',
                    convLink: '/convs/coproff',
                    imgLink: 'images/coproff.png',
                    footer: '',
                    bgColor: 'bg-coproff'
                },

            ],
            refIndex: 0,

        }
    },
    mounted() {

        switch (this.section) {

            case 'pprive':
                this.refIndex = 0;
                break;
            case 'pppi':
                this.refIndex = 1;
                break;
            case 'ppdom':
                this.refIndex = 2;
                break;
            case 'copro':
                this.refIndex = 3;
                break;
            case 'coproff':
                this.refIndex = 4;
                break;
            default:
                this.refIndex = 0;
        }
        document.getElementById(this.section + '_btn')
    },
    methods: {
        onMouseOver: function (id) {
            this.isEnabled = false;
        },
        onMouseOut: function (id) {
            this.isEnabled = true;
        }
    }

}
</script>

<style scoped></style>