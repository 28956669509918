export class PGService {

    //protected pgGeoDataDB: string;
    //protected headers: Headers;

    constructor(){

        this.headers = new Headers({'Content-Type': 'application/json'});
    }

    get_cog(scale, millesime, reg=null, section=null)
    {
        let params = {
            scale: scale,
            millesime: millesime,
            reg:  reg,
            section: section,
        }

        //console.log("cog params in PGService :",params );
        return axios.post('/geodb/cog', params, this.headers);
    }

    get_oldreg(newReg, section=null){

        let params = {
            scale: 'REG',
            reg:  newReg,
            section: section,
        }

        return axios.post('/geodb/get_old_reg', params, this.headers);

    }

    updateSecteurGeo(code, label){

        let params = {
            code:  code,
            label: label,
        };

        return axios.post('/geodb/secteurGeo', params, this.headers);
    }


    getSecteurGeo(){

        return axios.get('/geodb/secteurGeo',  this.headers);
    }


}