"use strict";

export class ClassementPPPIService {

    //protected pgGeoDataDB: string;
    //protected headers: Headers;

    constructor(){

        this.headers = new Headers({'Content-Type': 'application/json'});
    }

    callQuerry(ref, code){

        let params = {
            section: 'pppi',
            ref_tbl: ref,
            ref_dep: code,
        };

        return axios.post('/geodb/pppiclassement', params, this.headers);

    }

    getstructure(id){

        return [

            {
                "id": "1",
                "base": "base_T01.mdb",
                "table": "PPPI2019",
                "TITRE": "1 - Volume et taux PPPI",
                "COLONNES": [
                    {
                        "NOM": "nom_terr",
                        "COURT": "Territoire",
                        "TAILLE": "110",
                        "SQL": "nom_terr",
                        "COUL": "1",
                        "#text": "Nom du territoire"
                    },
                    {
                        "NOM": "code_terr",
                        "COURT": "Code insee",
                        "TAILLE": "110",
                        "SQL": "code_terr",
                        "COUL": "1",
                        "#text": "Code INSEE"
                    },
                    {
                        "NOM": "type_terr",
                        "COURT": "type terr",
                        "TAILLE": "110",
                        "SQL": "type_terr",
                        "COUL": "1",
                        "#text": "Type territoire"
                    },
                    {
                        "NOM": "rppp",
                        "COURT": "RP  du Parc  Privé",
                        "TAILLE": "70",
                        "SQL": "rppp",
                        "COUL": "2",
                        "#text": "Résidences principales du parc privé"
                    },
                    {
                        "NOM": "nb7pind",
                        "COURT": "PPPI  2019",
                        "TAILLE": "70",
                        "SQL": "nb7pind",
                        "COUL": "2",
                        "#text": "Parc Privé Potentiellement indigne en 2019"
                    },
                    {
                        "NOM": "pctpp",
                        "COURT": "Part  PPPI/ RPPP",
                        "TAILLE": "70",
                        "SQL": "pctpp",
                        "COUL": "2",
                        "#text": "Part du Parc Privé Potentiellement indigne dans l'ensemble des résidences principales du parc privé"
                    },
                    {
                        "NOM": "popppi",
                        "COURT": "Pop.  du  PPPI",
                        "TAILLE": "70",
                        "SQL": "popppi",
                        "COUL": "0",
                        "#text": "Population de PPPI"
                    },
                    {
                        "NOM": "tmoymenpppi",
                        "COURT": "Taille  moyenne ménages  PPPI",
                        "TAILLE": "68",
                        "SQL": "tmoymenpppi",
                        "COUL": "0",
                        "#text": "Taille Moyenne des ménages du PPPI"
                    },
                    {
                        "NOM": "pppic2",
                        "COURT": "PPPI  cat.6",
                        "TAILLE": "60",
                        "SQL": "pppic2",
                        "COUL": "0",
                        "#text": "Nombre de résidences principales privées de catégorie 6, occupées par un ménage au revenu fiscal de référence inférieur  à 70% du  seuil de pauvreté"
                    },
                    {
                        "NOM": "pppic2vr",
                        "COURT": "%  cat.6 /PPPI",
                        "TAILLE": "60",
                        "SQL": "pppic2vr",
                        "COUL": "0",
                        "#text": "Part des ménages vivant dans un logement PPPI de catégorie 6 dans l'ensemble du parc privé potentiellement indigne"
                    },
                    {
                        "NOM": "pppic3",
                        "COURT": "PPPI  cat. 7  ou 8",
                        "TAILLE": "60",
                        "SQL": "pppic3",
                        "COUL": "0",
                        "#text": "Nombre de résidences principales privées de catégories 7 et 8, occupées par un ménage au revenu fiscal de référence inférieur  à 150% du  seuil de pauvreté"
                    },
                    {
                        "NOM": "pppic3vr",
                        "COURT": "%  Cat. 7-8 /PPPI",
                        "TAILLE": "60",
                        "SQL": "pppic3vr",
                        "COUL": "0",
                        "#text": "Part des ménages vivant dans un logement PPPI de catégorie 7 ou 8 dans l'ensemble du parc privé potentiellement indigne"
                    }
                ],
                "SQL": {
                    "ZONNAGE": "WHERE code_dept like \\'"
                }
            },
            {
                "id": "2",
                "base": "base_T01.mdb",
                "table": "PPPI2019",
                "TITRE": "2 - Superficie des logements",
                "COLONNES": [
                    {
                        "NOM": "nom_terr",
                        "COURT": "Territoire",
                        "TAILLE": "110",
                        "SQL": "nom_terr",
                        "COUL": "1",
                        "#text": "Nom du territoire"
                    },
                    {
                        "NOM": "code_terr",
                        "COURT": "Code insee",
                        "TAILLE": "110",
                        "SQL": "code_terr",
                        "COUL": "1",
                        "#text": "Code INSEE"
                    },
                    {
                        "NOM": "type_terr",
                        "COURT": "type terr",
                        "TAILLE": "110",
                        "SQL": "type_terr",
                        "COUL": "1",
                        "#text": "Type territoire"
                    },
                    {
                        "NOM": "nb7pind",
                        "COURT": "PPPI  2019",
                        "TAILLE": "35",
                        "SQL": "nb7pind",
                        "COUL": "2",
                        "#text": "Parc privé potentiellement indigne en 2019"
                    },
                    {
                        "NOM": "pctpp",
                        "COURT": "Part PPPI/ RPPP",
                        "TAILLE": "35",
                        "SQL": "pctpp",
                        "COUL": "2",
                        "#text": "Part du parc privé potentiellement indigne dans l'ensemble des résidences principales du parc privé"
                    },
                    {
                        "NOM": "pctsh1",
                        "COURT": "%  <15m2  /PPPI",
                        "TAILLE": "40",
                        "SQL": "pctsh1",
                        "COUL": "0",
                        "#text": "Part des logements inférieurs à 15m² dans l'ensemble du parc privé potentiellement indigne"
                    },
                    {
                        "NOM": "pctsh1lp",
                        "COURT": "Part des  PPPI  <15m2  LP",
                        "TAILLE": "40",
                        "SQL": "pctsh1lp",
                        "COUL": "0",
                        "#text": "Part des logements locatifs dans le parc privé potentiellement indigne de moins de 15m²"
                    },
                    {
                        "NOM": "pctsh2",
                        "COURT": "% 15 à 34m2 /PPPI",
                        "TAILLE": "40",
                        "SQL": "pctsh2",
                        "COUL": "0",
                        "#text": "Part des logements de 15 à 34m² dans l'ensemble du parc privé potentiellement indigne"
                    },
                    {
                        "NOM": "pctsh2lp",
                        "COURT": "Part des PPPI 15 à 34m2 LP",
                        "TAILLE": "50",
                        "SQL": "pctsh2lp",
                        "COUL": "0",
                        "#text": "Part des logements locatifs dans le parc privé potentiellement indigne de 15 à 34m²"
                    },
                    {
                        "NOM": "pctsh3",
                        "COURT": "%  35 à 54m2  /PPPI",
                        "TAILLE": "50",
                        "SQL": "pctsh3",
                        "COUL": "0",
                        "#text": "Part des logements de 35 à 54m² dans l'ensemble du parc privé potentiellement indigne"
                    },
                    {
                        "NOM": "pctsh3lp",
                        "COURT": "Part des PPPI 35 à 54m2  LP",
                        "TAILLE": "55",
                        "SQL": "pctsh3lp",
                        "COUL": "0",
                        "#text": "Part des logements locatifs dans le parc privé potentiellement indigne de 35 à 54m²"
                    },
                    {
                        "NOM": "pctsh4",
                        "COURT": "% 55 à 74m2 /PPPI",
                        "TAILLE": "50",
                        "SQL": "pctsh4",
                        "COUL": "0",
                        "#text": "Part des logements de 55 à 74m² dans l'ensemble du parc privé potentiellement indigne"
                    },
                    {
                        "NOM": "pctsh4lp",
                        "COURT": "Part des PPPI 55 à 74m2  LP",
                        "TAILLE": "50",
                        "SQL": "pctsh4lp",
                        "COUL": "0",
                        "#text": "Part des logements locatifs dans le parc privé potentiellement indigne de 55 à 74m²"
                    },
                    {
                        "NOM": "pctsh5",
                        "COURT": "% 75 à 94m2 /PPPI",
                        "TAILLE": "45",
                        "SQL": "pctsh5",
                        "COUL": "0",
                        "#text": "Part des logements de 75 à 94m² dans l'ensemble du parc privé potentiellement indigne"
                    },
                    {
                        "NOM": "pctsh5lp",
                        "COURT": "Part des PPPI 75 à 94m2  LP",
                        "TAILLE": "50",
                        "SQL": "pctsh5lp",
                        "COUL": "0",
                        "#text": "Part des logements locatifs dans le parc privé potentiellement indigne de 75 à 94m²"
                    },
                    {
                        "NOM": "pctsh6",
                        "COURT": "%  des PPPI  >94m2",
                        "TAILLE": "50",
                        "SQL": "pctsh6",
                        "COUL": "0",
                        "#text": "Part des logements de plus de 94m² dans le parc privé potentiellement indigne"
                    },
                    {
                        "NOM": "pctsh6lp",
                        "COURT": "Part des PPPI  >94m2  LP",
                        "TAILLE": "50",
                        "SQL": "pctsh6lp",
                        "COUL": "0",
                        "#text": "Part des logements locatifs dans le parc privé potentiellement indigne de plus de 94m²"
                    }
                ],
                "SQL": {
                    "ZONNAGE": "WHERE code_dept like \\'"
                }
            },
            {
                "id": "3",
                "base": "base_T01.mdb",
                "table": "PPPI2019",
                "TITRE": "3 - Statut d'occupation",
                "COLONNES": [
                    {
                        "NOM": "nom_terr",
                        "COURT": "Territoire",
                        "TAILLE": "120",
                        "SQL": "nom_terr",
                        "COUL": "1",
                        "#text": "Nom du territoire"
                    },
                    {
                        "NOM": "code_terr",
                        "COURT": "Code insee",
                        "TAILLE": "110",
                        "SQL": "code_terr",
                        "COUL": "1",
                        "#text": "Code INSEE"
                    },
                    {
                        "NOM": "type_terr",
                        "COURT": "type terr",
                        "TAILLE": "110",
                        "SQL": "type_terr",
                        "COUL": "1",
                        "#text": "Type territoire"
                    },
                    {
                        "NOM": "rppp",
                        "COURT": "RP du parc privé",
                        "TAILLE": "80",
                        "SQL": "rppp",
                        "COUL": "2",
                        "#text": "Résidences principales du parc privé"
                    },
                    {
                        "NOM": "nb7pind",
                        "COURT": "PPPI 2019",
                        "TAILLE": "80",
                        "SQL": "nb7pind",
                        "COUL": "2",
                        "#text": "Parc privé potentiellement indigne en 2019"
                    },
                    {
                        "NOM": "pctpp",
                        "COURT": "Part PPPI  /RPPP",
                        "TAILLE": "80",
                        "SQL": "pctpp",
                        "COUL": "2",
                        "#text": "Part du parc privé potentiellement indigne dans l'ensemble des résidences principales du parc privé"
                    },
                    {
                        "NOM": "pctpo_va",
                        "COURT": "nb de PO PPPI",
                        "TAILLE": "60",
                        "SQL": "pctpo_va",
                        "COUL": "0",
                        "#text": "Nombre de propriétaires occupants de logements du parc privé potentiellement indigne"
                    },
                    {
                        "NOM": "pctpo",
                        "COURT": "%  PO /PPPI",
                        "TAILLE": "60",
                        "SQL": "pctpo",
                        "COUL": "0",
                        "#text": "Part des propriétaires occupants dans l'ensemble du parc privé potentielement indigne"
                    },
                    {
                        "NOM": "pctrppipo",
                        "COURT": "% PPPI /PO",
                        "TAILLE": "60",
                        "SQL": "pctrppipo",
                        "COUL": "0",
                        "#text": "Part des logements de propriétaires occupants du parc privé potentiellement indigne dans l'ensemble des résidences principales privées du parc propriétaire occupants"
                    },
                    {
                        "NOM": "pctlp_va",
                        "COURT": "nb de LP PPPI",
                        "TAILLE": "55",
                        "SQL": "pctlp_va",
                        "COUL": "0",
                        "#text": "Nombre de locataires de logements du parc privé potentiellement indigne"
                    },
                    {
                        "NOM": "pctlp",
                        "COURT": "% LP /PPPI",
                        "TAILLE": "55",
                        "SQL": "pctlp",
                        "COUL": "0",
                        "#text": "Part des locataires dans l'ensemble du parc privé potentielement indigne"
                    },
                    {
                        "NOM": "pctrppilp",
                        "COURT": "% PPPI /LP",
                        "TAILLE": "60",
                        "SQL": "pctrppilp",
                        "COUL": "0",
                        "#text": "Part des logements locatifs du parc privé potentiellement indigne dans l'ensemble des résidences principales privées du parc locatif privé"
                    }
                ],
                "SQL": {
                    "ZONNAGE": "WHERE code_dept like \\'"
                }
            },
            {
                "id": "4",
                "base": "base_T01.mdb",
                "table": "PPPI2019",
                "TITRE": "4 - Age des ménages",
                "COLONNES": [
                    {
                        "NOM": "nom_terr",
                        "COURT": "Territoire",
                        "TAILLE": "130",
                        "SQL": "nom_terr",
                        "COUL": "1",
                        "#text": "Nom du territoire"
                    },
                    {
                        "NOM": "code_terr",
                        "COURT": "Code insee",
                        "TAILLE": "110",
                        "SQL": "code_terr",
                        "COUL": "1",
                        "#text": "Code INSEE"
                    },
                    {
                        "NOM": "type_terr",
                        "COURT": "type terr",
                        "TAILLE": "110",
                        "SQL": "type_terr",
                        "COUL": "1",
                        "#text": "Type territoire"
                    },
                    {
                        "NOM": "rppp",
                        "COURT": "RP  du parc privé",
                        "TAILLE": "80",
                        "SQL": "rppp",
                        "COUL": "2",
                        "#text": "Résidences principales du parc privé"
                    },
                    {
                        "NOM": "nb7pind",
                        "COURT": "PPPI 2019",
                        "TAILLE": "80",
                        "SQL": "nb7pind",
                        "COUL": "2",
                        "#text": "Parc privé potentiellement indigne en 2019"
                    },
                    {
                        "NOM": "pctpp",
                        "COURT": "Part PPPI /RPPP",
                        "TAILLE": "80",
                        "SQL": "pctpp",
                        "COUL": "2",
                        "#text": "Part du parc privé potentiellement indigne dans l'ensemble des résidences principales du parc privé"
                    },
                    {
                        "NOM": "pctjem_va",
                        "COURT": "nb jeunes ménages",
                        "TAILLE": "60",
                        "SQL": "pctjem_va",
                        "COUL": "0",
                        "#text": "Nombre de jeunes ménages (<= 25 ans)"
                    },
                    {
                        "NOM": "pctjem",
                        "COURT": "% jeunes ménages /PPPI",
                        "TAILLE": "70",
                        "SQL": "pctjem",
                        "COUL": "0",
                        "#text": "Part des jeunes ménages dans l'ensemble du parc privé potentiellement indigne"
                    },
                    {
                        "NOM": "pctvm_va",
                        "COURT": "nb ménages âgés",
                        "TAILLE": "70",
                        "SQL": "pctvm_va",
                        "COUL": "0",
                        "#text": "Nombre de ménages âgés (>=60 ans)"
                    },
                    {
                        "NOM": "pctvm",
                        "COURT": "% ménages âgés / PPPI",
                        "TAILLE": "70",
                        "SQL": "pctvm",
                        "COUL": "0",
                        "#text": "Part des ménages âgés dans l'ensemble du parc privé potentiellement indigne"
                    }
                ],
                "SQL": {
                    "ZONNAGE": "WHERE code_dept like \\'"
                }
            },
            {
                "id": "5",
                "base": "base_T01.mdb",
                "table": "PPPI2019",
                "TITRE": "5 - Suroccupation",
                "COLONNES": [
                    {
                        "NOM": "nom_terr",
                        "COURT": "Territoire",
                        "TAILLE": "130",
                        "SQL": "nom_terr",
                        "COUL": "1",
                        "#text": "Nom du territoire"
                    },
                    {
                        "NOM": "code_terr",
                        "COURT": "Code insee",
                        "TAILLE": "110",
                        "SQL": "code_terr",
                        "COUL": "1",
                        "#text": "Code INSEE"
                    },
                    {
                        "NOM": "type_terr",
                        "COURT": "type terr",
                        "TAILLE": "110",
                        "SQL": "type_terr",
                        "COUL": "1",
                        "#text": "Type territoire"
                    },
                    {
                        "NOM": "rppp",
                        "COURT": "RP du parc privé",
                        "TAILLE": "80",
                        "SQL": "rppp",
                        "COUL": "2",
                        "#text": "Résidences principales du parc privé"
                    },
                    {
                        "NOM": "nb7pind",
                        "COURT": "PPPI 2019",
                        "TAILLE": "80",
                        "SQL": "nb7pind",
                        "COUL": "2",
                        "#text": "Parc privé potentiellement indigne en 2019"
                    },
                    {
                        "NOM": "pctpp",
                        "COURT": "Part PPPI /RPPP",
                        "TAILLE": "90",
                        "SQL": "pctpp",
                        "COUL": "2",
                        "#text": "Part du parc privé potentiellement indigne dans l'ensemble des résidences principales du parc privé"
                    },
                    {
                        "NOM": "pctso_va",
                        "COURT": "nb surocc. PPPI",
                        "TAILLE": "60",
                        "SQL": "pctso_va",
                        "COUL": "0",
                        "#text": "Nombre de ménages en suroccupation (Définition CAF) dans l'ensemble du parc privé potentiellement indigne"
                    },
                    {
                        "NOM": "pctso",
                        "COURT": "% surocc. /PPPI",
                        "TAILLE": "60",
                        "SQL": "pctso",
                        "COUL": "0",
                        "#text": "Part des ménages en suroccupation (Définition CAF) dans l'ensemble du parc privé potentiellement indigne"
                    },
                    {
                        "NOM": "pctsopo",
                        "COURT": "Tx surocc PO PPPI",
                        "TAILLE": "80",
                        "SQL": "pctsopo",
                        "COUL": "0",
                        "#text": "Taux de surocupation (définition CAF) chez les propriétaires occupant un logement du parc privé potentiellement indigne"
                    },
                    {
                        "NOM": "pctsolp",
                        "COURT": "Tx surocc LP PPPI",
                        "TAILLE": "60",
                        "SQL": "pctsolp",
                        "COUL": "0",
                        "#text": "Taux de surocupation (définition CAF) chez les locataires occupant un logement du parc privé potentiellement indigne"
                    }
                ],
                "SQL": {
                    "ZONNAGE": "WHERE code_dept like \\'"
                }
            },
            {
                "id": "6",
                "base": "base_T01.mdb",
                "table": "PPPI2019",
                "TITRE": "6 - Risque de saturnisme",
                "COLONNES": [
                    {
                        "NOM": "nom_terr",
                        "COURT": "Territoire",
                        "TAILLE": "120",
                        "SQL": "nom_terr",
                        "COUL": "1",
                        "#text": "Nom du territoire"
                    },
                    {
                        "NOM": "code_terr",
                        "COURT": "code insee",
                        "TAILLE": "110",
                        "SQL": "code_terr",
                        "COUL": "1",
                        "#text": "Code INSEE"
                    },
                    {
                        "NOM": "type_terr",
                        "COURT": "type terr",
                        "TAILLE": "110",
                        "SQL": "type_terr",
                        "COUL": "1",
                        "#text": "Type territoire"
                    },
                    {
                        "NOM": "rppp",
                        "COURT": "RP du parc privé",
                        "TAILLE": "90",
                        "SQL": "rppp",
                        "COUL": "2",
                        "#text": "Résidences principales du parc privé"
                    },
                    {
                        "NOM": "nb7pind",
                        "COURT": "PPPI 2019",
                        "TAILLE": "90",
                        "SQL": "nb7pind",
                        "COUL": "2",
                        "#text": "Parc privé potentiellement indigne en 2019"
                    },
                    {
                        "NOM": "pctpp",
                        "COURT": "Part PPPI /RPPP",
                        "TAILLE": "90",
                        "SQL": "pctpp",
                        "COUL": "2",
                        "#text": "Part du parc privé potentiellement indigne dans l'ensemble des résidences principales du parc privé"
                    },
                    {
                        "NOM": "av48nb6",
                        "COURT": "Nb PPPI d'avt.49 avec enfants <=6ans",
                        "TAILLE": "85",
                        "SQL": "av48nb6",
                        "COUL": "0",
                        "#text": "Nombre de logements du parc privé potentiellement indigne construits avant 1949  hébergeant au moins un enfant de moins de 6 ans"
                    },
                    {
                        "NOM": "av48nb6_vr",
                        "COURT": "Part PPPI d'avt 49 avec enfants <=6 ans /PPPI",
                        "TAILLE": "90",
                        "SQL": "av48nb6_vr",
                        "COUL": "0",
                        "#text": "Part de logements du parc privé potentiellement indigne construits avant 1949  hébergeant au moins un enfant de moins de 6 ans dans l'ensemble du parc privé potentiellement indigne"
                    },
                    {
                        "NOM": "av48enf6",
                        "COURT": "Nb d'enfants <=6 ans en PPPI d'avt. 1949",
                        "TAILLE": "90",
                        "SQL": "av48enf6",
                        "COUL": "0",
                        "#text": "Nombre d'enfants de moins de 6 ans habitant un logement du parc privé potentiellement indigne construit avant 1949"
                    }
                ],
                "SQL": {
                    "ZONNAGE": "WHERE code_dept like \\'"
                }
            },
         ];

    }

}