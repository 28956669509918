export class LegendMapParamsService {



    constructor(section='pppi'){

        this._map = 'Test';
        this._parameters = this.initParams(section);

    }

    initParams(section='pppi'){

        let params = [];

        console.log('La section : ',section);

        switch(section){
        case 'ppdom':
            params = [
                {
                    indic       : 'a91',
                    indicTitle  : "Nb de résidences principales",
                    discretClass: ['20000', '10000', '5000', '1000'],
                    colors      : ['#FF6305', '#FF7F3A', '#E57652', '#BF7B67', '#D9A89B'],
                    nr_color    : '#dfddca',
                    suffix      : null,
                    source      : 'INSEE 2014, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'calc_dom2014_a1_dom2014_a91',
                    indicTitle  : "Part de PO",
                    discretClass: [ '35', '15', '5', '3'],
                    colors      : ['#00244c','#0054b2','#0078ff','#66aeff','#e5f1ff'],
                    nr_color    : '#dfddca',
                    suffix      : '%',
                    source      : 'INSEE 2014, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'calc_dom2014_a2_dom2014_a91',
                    indicTitle  : "Part de LP d'un logement non HLM",
                    discretClass: ['25', '15', '10', '5'],
                    colors      : ['#8806B2', '#DC72FF', '#82B223', '#BEFF3D', '#DAFF90'],
                    nr_color    : '#dfddca',
                    suffix      : '%',
                    source      : 'INSEE 2014, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'calc_dom2014_a3_dom2014_a91',
                    indicTitle  : "Part de Locataires d'un logement HLM",
                    discretClass: ['10', '6', '3', '2'],
                    colors      : ['#FF0000', '#FF6305',  '#E57652', '#BF7B67', '#D9A89B'],
                    nr_color    : '#dfddca',
                    suffix      : '%',
                    source      : 'INSEE 2014, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'tx_dom2014_a93',
                    indicTitle  : "Taux de vacance",
                    discretClass: [ '15', '13', '7', '3'],
                    colors      : ['#580063','#9a00ae','#dd00f9','#ee7ffc','#fbe5fe'],
                    nr_color    : '#dfddca',
                    suffix      : '%',
                    source      : 'INSEE 2014, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'tmm_dom2014_a181_dom2014_a91',
                    indicTitle  : "Taille moyenne des ménages",
                    discretClass: [ '2.8', '2.5', '2.2', '2'],
                    colors      : ['#0d2d52','#1f4d83','#326aab','#508bcf','#8bc1ff'],
                    nr_color    : '#dfddca',
                    suffix      : null,
                    source      : 'INSEE 2014, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'a183',
                    indicTitle  : "Revenu brut imposable médian en €/an/UC - Ensemble des ménages (dgi 2011)",
                    discretClass: ['19000', '16000', '12000', '8000'],
                    colors      : ['#8806B2', '#DC72FF', '#82B223', '#BEFF3D', '#DAFF90'],
                    nr_color    : '#dfddca',
                    suffix      : null,
                    source      : 'INSEE 2014, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'calc_dom2014_a131_dom2014_a91',
                    indicTitle  : "Part de RP sans électricité",
                    discretClass: [ '5', '2', '1', '0.5'],
                    colors      : ['#38991b', '#9ab21f', '#5eff2d', '#ddff2d', '#e7ff6c' ],
                    nr_color    : '#dfddca',
                    suffix      : '%',
                    source      : 'INSEE 2014, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'calc_dom2014_a136_dom2014_a91',
                    indicTitle  : "Part de RP sans eau ou eau froide seulement",
                    discretClass: [ '35', '15', '7', '3'],
                    colors      : ['#38991b', '#9ab21f', '#5eff2d', '#ddff2d', '#e7ff6c' ],
                    nr_color    : '#dfddca',
                    suffix      : '%',
                    source      : 'INSEE 2014, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'calc_dom2014_a141_dom2014_a91',
                    indicTitle  : "Part de RP sans Bain/Douche WC",
                    discretClass: ['75', '45', '35', '10'],
                    colors      : ['#8806B2', '#DC72FF', '#82B223', '#BEFF3D', '#DAFF90'],
                    nr_color    : '#dfddca',
                    suffix      : '%',
                    source      : 'INSEE 2014, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
            ]
            break;

        case 'pppi':
            params = [
                {
                    indic       : 'pppi1_a4',
                    indicTitle  : "Nbr. Lgmt. PPPI",
                    discretClass: ['500', '250', '50', '20'],
                    colors      : ['#ff00d5','#b526cd','#d184d9','#efc0ef','#fbe5fe'],
                    sec_color   : '#7d726a',
                    nr_color    : '#dfddca',
                    suffix      : null,
                    source      : 'FILOCOM 2019, MTE d\'après DGFIP, PPPI, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'pppi1_a96',
                    indicTitle  : "Nb. Lgmt. PPPI cat. 6",
                    discretClass: [ '250', '150', '50', '30'],
                    colors      : ['#00244c','#0054b2','#0078ff','#66aeff','#e5f1ff'],
                    sec_color   : '#7d726a',
                    nr_color    : '#dfddca',
                    suffix      : null,
                    source      : 'FILOCOM 2019, MTE  d\'après DGFIP, PPPI, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'pppi1_a188',
                    indicTitle  : "Nb. Logmt. PPPI cat. 7 et 8",
                    discretClass: ['250', '150', '50', '30'],
                    colors      : ['#8806B2', '#DC72FF', '#82B223', '#BEFF3D', '#DAFF90'],
                    sec_color   : '#7d726a',
                    nr_color    : '#dfddca',
                    suffix      : null,
                    source      : 'FILOCOM 2019, MTE  d\'après DGFIP, PPPI, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'pppi1_a4_cadrage_a68',
                    indicTitle  : "Part du PPPI dans RPP",
                    //discretClass: ['2', '1.5', '1', '0.5'],
                    discretClass: ['8', '5', '2', '1'],
                    colors      : ['#FF0000', '#FF6305',  '#E57652', '#BF7B67', '#D9A89B'],
                    sec_color   : '#7d726a',
                    nr_color    : '#dfddca',
                    suffix      : '%',
                    source      : 'FILOCOM 2019, MTE  d\'après DGFIP, PPPI, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'pppi1_a1',
                    indicTitle  : "Nb. PO dans le PPPI",
                    discretClass: [ '1000', '500', '150', '50'],
                    colors      : ['#580063','#9a00ae','#dd00f9','#ee7ffc','#fbe5fe'],
                    sec_color   : '#7d726a',
                    nr_color    : '#dfddca',
                    suffix      : null,
                    source      : 'FILOCOM 2019, MTE  d\'après DGFIP, PPPI, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'pppi1_a1_pppi1_a4',
                    indicTitle  : "Part des PO dans le PPPI",
                    discretClass: [ '50', '35', '25', '10'],
                    colors      : ['#00244c','#0054b2','#0078ff','#66aeff','#e5f1ff'],
                    sec_color   : '#7d726a',
                    nr_color    : '#dfddca',
                    suffix      : '%',
                    source      : 'FILOCOM 2019, MTE  d\'après DGFIP, PPPI, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'pppi1_a2',
                    indicTitle  : "Nb. LP dans le PPPI",
                    discretClass: [ '300', '150', '50', '20'],
                    colors      : ['#580063','#9a00ae','#dd00f9','#ee7ffc','#fbe5fe'],
                    sec_color   : '#7d726a',
                    nr_color    : '#dfddca',
                    suffix      : null,
                    source      : 'FILOCOM 2019, MTE  d\'après DGFIP, PPPI, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'pppi1_a2_pppi1_a4',
                    indicTitle  : "Part de LP dans le PPPI",
                    discretClass: [ '20', '15', '10', '5'],
                    colors      : ['#38991b', '#9ab21f', '#5eff2d', '#ddff2d', '#e7ff6c' ],
                    sec_color   : '#7d726a',
                    nr_color    : '#dfddca',
                    suffix      : '%',
                    source      : 'FILOCOM 2019, MTE  d\'après DGFIP, PPPI, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'pppi1_a16',
                    indicTitle  : "Nbr. PPPI en LI",
                    discretClass: [ '350', '150', '70', '30'],
                    colors      : ['#38991b', '#9ab21f', '#5eff2d', '#ddff2d', '#e7ff6c' ],
                    sec_color   : '#7d726a',
                    nr_color    : '#dfddca',
                    suffix      : null,
                    source      : 'FILOCOM 2019, MTE  d\'après DGFIP, PPPI, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'pppi1_a16_pppi1_a4',
                    indicTitle  : "Part PPPI en LI",
                    discretClass: [ '50', '35', '15', '5'],
                    colors      : ['#38991b', '#9ab21f', '#5eff2d', '#ddff2d', '#e7ff6c' ],
                    sec_color   : '#7d726a',
                    nr_color    : '#dfddca',
                    suffix      : '%',
                    source      : 'FILOCOM 2019, MTE  d\'après DGFIP, PPPI, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'pppi1_a53',
                    indicTitle  : "Nbr. PPPI en Copro.",
                    discretClass: [ '350', '150', '70', '30'],
                    colors      : ['#38991b', '#9ab21f', '#5eff2d', '#ddff2d', '#e7ff6c' ],
                    sec_color   : '#7d726a',
                    nr_color    : '#dfddca',
                    suffix      : null,
                    source      : 'FILOCOM 2019, MTE  d\'après DGFIP, PPPI, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'pppi1_a53_pppi1_a4',
                    indicTitle  : "Part Copro en PPPI",
                    discretClass: [ '50', '35', '15', '5'],
                    colors      : ['#38991b', '#9ab21f', '#5eff2d', '#ddff2d', '#e7ff6c' ],
                    sec_color   : '#7d726a',
                    nr_color    : '#dfddca',
                    suffix      : '%',
                    source      : 'FILOCOM 2019, MTE  d\'après DGFIP, PPPI, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'pppi1_a72',
                    indicTitle  : "Nbr. PPPI ménage âgé",
                    discretClass: [ '350', '150', '70', '30'],
                    colors      : ['#38991b', '#9ab21f', '#5eff2d', '#ddff2d', '#e7ff6c' ],
                    sec_color   : '#7d726a',
                    nr_color    : '#dfddca',
                    suffix      : null,
                    source      : 'FILOCOM 2019, MTE  d\'après DGFIP, PPPI, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'pppi1_a72_pppi1_a4',
                    indicTitle  : "Part des ménage âgé en PPPI",
                    discretClass: [ '50', '35', '15', '5'],
                    colors      : ['#38991b', '#9ab21f', '#5eff2d', '#ddff2d', '#ff1f13' ],
                    sec_color   : '#7d726a',
                    nr_color    : '#dfddca',
                    suffix      : '%',
                    source      : 'FILOCOM 2019, MTE  d\'après DGFIP, PPPI, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'pppi1_a8_pppi1_a4',
                    indicTitle  : "Taille Moy. ménages PPPI",
                    discretClass: [ '4', '3', '2', '1'],
                    colors      : ['#38991b', '#9ab21f', '#5eff2d', '#ddff2d', '#e7ff6c' ],
                    sec_color   : '#7d726a',
                    nr_color    : '#dfddca',
                    suffix      : null,
                    source      : 'FILOCOM 2019, MTE  d\'après DGFIP, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'pppi1_a60_pppi1_a4',
                    indicTitle  : "Taux de surroc. PPPI",
                    discretClass: [ '50', '35', '15', '5'],
                    colors      : ['#38991b', '#9ab21f', '#5eff2d', '#ddff2d', '#e7ff6c' ],
                    sec_color   : '#7d726a',
                    nr_color    : '#dfddca',
                    suffix      : '%',
                    source      : 'FILOCOM 2019, MTE  d\'après DGFIP, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'pppi1_a88',
                    indicTitle  : "Nbr. PPPI av. 49 avec enf. -6 ans",
                    discretClass: [ '50', '35', '10', '5'],
                    colors      : ['#38991b', '#9ab21f', '#5eff2d', '#ddff2d', '#e7ff6c' ],
                    sec_color   : '#7d726a',
                    nr_color    : '#dfddca',
                    suffix      : null,
                    source      : 'FILOCOM 2019, MTE  d\'après DGFIP, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'pppi1_a88_pppi1_a4',
                    indicTitle  : "Part PPPI av. 49 avec enf. -6 ans",
                    discretClass: [ '50', '35', '15', '5'],
                    colors      : ['#38991b', '#9ab21f', '#5eff2d', '#ddff2d', '#e7ff6c' ],
                    sec_color   : '#7d726a',
                    nr_color    : '#dfddca',
                    suffix      : '%',
                    source      : 'FILOCOM 2019, MTE  d\'après DGFIP, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
                {
                    indic       : 'pppi1_a92',
                    indicTitle  : "Nbr. d'enfants de -6 ans dans PPPI av. 49",
                    discretClass: [ '30', '15', '10', '5'],
                    colors      : ['#38991b', '#9ab21f', '#5eff2d', '#ddff2d', '#e7ff6c' ],
                    sec_color   : '#7d726a',
                    nr_color    : '#dfddca',
                    suffix      : null,
                    source      : 'FILOCOM 2019, MTE  d\'après DGFIP, traitement Anah',
                    refGeo      : {scale: 'Test',},
                },
            ]
            break;
        }



        return params;
    }

        ///// ACCESSORS ///////

        get parameters(){
            return this._parameters;
        }

        set parameters(params){
            this._parameters = params;
        }

}


