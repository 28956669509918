<template>
    <div class="text-center">
        <div class="btn-group" role="group" aria-label="boutton echelles">
            <a href="#/" class="btn btn-primary2 btn-sm" @click="toggleScale('COM')" :class="{ active: scale=='COM' }" v-b-tooltip.hover.top title="Afficher les données à l'échelle communale">Comm.</a>
            <!--<a href="#/" class="btn btn-primary2 btn-sm diseable" @click="toggleScale('CANT')" :class="{ active: scale=='CANT' }" v-b-tooltip.hover.top title="Afficher les données à l'échelle cantonale">Cantons</a>-->
            <a href="#/" class="btn btn-primary2 btn-sm" @click="toggleScale('EPCI')" :class="{ active: scale=='EPCI' }" v-b-tooltip.hover.top title="Afficher les données à l'échelle des EPCI">EPCI</a>
        </div>
        <br>
        <br>
        <div role="tablist">
            <b-card no-body class="mb-1" v-for="(rubrique, index) in rubriques" :key="rubrique.id" :id="'tab_'+index" >
                <b-card-header header-tag="header" class="" role="tab" style="small">
                    <div class="row justify-content-between">
                        <div class="small">
                            <a href="#/" class="btn btn-sm btn-secondary text-left" v-b-toggle="'rubrique_'+String(index)" v-html="'<span class=fbm-badge>'+(index+1)+'</span>'+' '+rubrique.subtitle" ></a>
                            <!--<b-btn block href="#" v-b-toggle="'rubrique_'+String(index)" size="sm" variant="" class="small">{{index+1}} {{rubrique.subtitle}}</b-btn>-->
                        </div>
                    </div>
                </b-card-header>
                <b-collapse :id="'rubrique_'+(String(index))"  :visible="index==0" accordion="my-accordion"  role="tabpanel">
                    <ul class="list-group list-group-flush">
                        <button type="button" v-b-tooltip.hover.top :title="map.resum" :class="{active: map.isActive}" class="list-group-item list-group-item-action small" v-for="(map, index2) in rubrique.maps" :key="map.id" @click="selectedMap(map, rubrique)">
                            <i  class="fa fa-arrow-right text-primary2" aria-hidden="true" ></i> {{index+1}}-{{index2+1}} {{map.title}}
                        </button>
                    </ul>
                </b-collapse>
            </b-card>
        </div>
    </div>
</template>
<script>
    import {PGService} from '../services/PGService';
    import {DataStructureService} from '../services/DataStructureService';
    export default{
        props: ['section'],
        data(){
            return{
                scale: 'COM',
                selectedSection: this.section,
                rubriques : [ ]
                }
        },
        mounted(){
                this.dataStructureService = new DataStructureService();
                (this.selectedSection==undefined || this.selectedSection==null)?this.selectedSection='pppi':null;
                this.initMapList(this.section, 'COM');
                EventBus.$on('wait_for_first_map', (evt)=>{

                    this.selectedMap(this.rubriques[0].maps[0], this.rubriques[0]);
                });
        },
         methods:{
            initMapList: function(section, scale){

                this.rubriques  = [];
                let that = this;
                this.dataStructureService.getMapList(section).then((result)=>{
                    let refs = result.data.data;
                    if(refs.length>0){
                        for(let i = 0; i<refs.length;i++){
                            let maps = [];
                            refs[i].refs.forEach((item)=>{
                                if(item.scale==that.scale){
                                    item.maps.forEach((item2)=>{
                                        maps.push(item2);
                                    })
                                }
                            });
                            if (maps.length>0){
                                that.rubriques.push({
                                    id: refs[i].id,
                                    title: refs[i].title,
                                    icon: refs[i].icon,
                                    color: refs[i].color,
                                    titleColor: refs[i].titleColor,
                                    subtitle: refs[i].subtitle,
                                    maps: maps})
                            }
                        }
                    }else{
                        alert('Votre référentiel de cartes ne contient aucun élément');
                    }
                    //console.log('les rubriques : ', this.rubriques);
                }, (err)=>{
                    alert('Un problème est survenu lors de la recupération du référentiel des cartes ');
                })
            },
            selectedMap : function(mapRef, rubriqueRef){

                    this.rubriques.forEach((elem)=>{
                            elem.maps.forEach((mapElem)=>{
                                mapElem.isActive = false;
                            })
                    })
                    mapRef.isActive = true;
                    let ref = {map : mapRef, rubrique: rubriqueRef.title}
                    EventBus.$emit('display_synth_map', ref);
            },
             toggleScale: function(ref){

                switch(ref){
                case 'COM':
                    this.scale = 'COM';
                    EventBus.$emit('DISPLAY_SCALE', 'dep');

                    break;
                case 'CANT':
                    this.scale = 'CANT';
                    EventBus.$emit('DISPLAY_SCALE', 'cant');
                    break;
                case 'EPCI':
                    this.scale = 'EPCI';
                    EventBus.$emit('DISPLAY_SCALE', 'epci');
                    break;
                default:
                    this.scale = 'COM';
                    EventBus.$emit('DISPLAY_SCALE', 'dep');
                    break;

                }

             }

        }
    }
</script>

<style>

    .fbm-badge{
        color: #FFFFFF;
        padding: 0 5px;
        background-color: #8a6f81;;

    }

</style>
