  <template>
    <div>
        <br>
        <hr>
            <form id="form_actes"  method="post" :action="'/convs/'+conv.ref" role="form" enctype="multipart/form-data">
                <input type="hidden" name="_token" :value="csrfToken" >
                <input type="hidden" name="ref" id="ref" :value="conv">
                <input type="hidden" name="convTitle" id="convTitle" :value="conv.title">
                <input type="hidden" name="convRef" id="convRef" :value="conv.ref">
                <meta name="csrf-token" :content="csrfToken">
                <div class="form-group row">
                    <div class="col">
                        <h5>Je suis :</h5>
                        <b-form-select v-model="type_user" :options="options_user" :required="true" class="" id="type_utilisateur" name="type_utilisateur" aria-describedby="type_utilisateur"></b-form-select>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col">
                        <h5>Objet de la demande :</h5>
                        <b-form-select v-model="type_demande" :options="options_demande" :required="true" class="" id="selectedDemandeObject" name="selectedDemandeObject" aria-describedby="type_demande"></b-form-select>
                    </div>
                </div>
              <!-- patch pour suppression des actes d'engagements  = test 1+1==3   --->
                <div v-if="1+1==3 && type_user != null && type_demande != null && conv.slug != 'ref_parc_priv_dom'">
                    <br>
                <h4 class="text-primary"><i class="fa fa-cloud-download text-primary" aria-hidden="true" ></i> Télécharger les actes d'engagement : </h4>
                    <br>
                    <div class="text-center">
                        <div class="alert alert-primary2" role="alert">
                            <i class="fa fa-2x fa-info-circle" aria-hidden="true"></i><br>
                            <p v-if="type_user == 'autre_dtt_dreal' && type_demande=='acces_etat' && conv.ref=='pppi'">Vous devez remplir, imprimer, signer et faire signer par le responsable de la structure les documents suivants :</p>
                            <p v-else >Vous devez remplir, imprimer et signer le document suivant :</p>
                            <p class="text-primary" v-if="type_demande == 'acces_etat'">
                                <a  v-for="acte, index in actes" target="_blank"  :href="'/storage/annexes/'+acte.slug+'.'+acte.type" class="list-group-item list-group-item-action" v-if="(acte.slug).indexOf('ae-utilisateur')>=0">
                                    <span class=""></span><i class="fa fa-download" aria-hidden="true"></i> <span class="text-primary">{{acte.short_title}}</span> ({{acte.type}})
                                </a>
                            </p>
                            <p class="text-primary" v-if="type_user == 'autre_dtt_dreal' && type_demande=='acces_etat'">
                                <a  v-for="acte, index in actes" target="_blank"  :href="'/storage/annexes/'+acte.slug+'.'+acte.type" class="list-group-item list-group-item-action" v-if="(acte.slug).indexOf('designation-utilisateur')>=0">
                                    <span class=""></span><i class="fa fa-download" aria-hidden="true"></i> <span class="text-primary">{{acte.short_title}}</span> ({{acte.type}})
                                </a>
                            </p>
                            <p class="text-primary" v-if="type_demande == 'extract_collectivite'">
                                <a  v-for="acte, index in actes" target="_blank"  :href="'/storage/annexes/'+acte.slug+'.'+acte.type" class="list-group-item list-group-item-action" v-if="(acte.slug).indexOf('ae-collective')>=0">
                                    <span class=""></span><i class="fa fa-download" aria-hidden="true"></i> <span class="text-primary">{{acte.short_title}}</span> ({{acte.type}})
                                </a>
                            </p>
                            <p class="text-primary" v-if="type_demande == 'extract_be'">
                                <a  v-for="acte, index in actes" target="_blank"  :href="'/storage/annexes/'+acte.slug+'.'+acte.type" class="list-group-item list-group-item-action" v-if="(acte.slug).indexOf('ae-be')>=0">
                                    <span class=""></span><i class="fa fa-download" aria-hidden="true"></i> <span class="text-primary">{{acte.short_title}}</span> ({{acte.type}})
                                </a>
                            </p>

                            <p v-if="type_user == 'autre_dtt_dreal' && type_demande=='acces_etat' && conv.ref=='pppi'"  >Une fois signés, ces documents peuvent être scannés et soumis dans le formulaire accessible ci-dessous.</p>
                            <p v-else>Une fois signé, ce document peut être scanné et soumis dans le formulaire accessible ci-dessous.</p>
                        </div>
                    </div>

                      <br>
                      <!---pprive-->
                      <div class="alert alert-taupe2 mt-2" v-if="conv.ref=='pp'">
                        <i class="fa fa-info-circle text-primary2" aria-hidden="true"></i><span class="font-weight-bold  "> Attention, pour chaque outil, un nombre limité d'utilisateurs par territoire est autorisé :</span>
                        <br>
                        <span class="font-weight-bold">Pour les synthèses Parc privé :</span>
                        <br>
                        <span class="font-weight-bold">DREAL :</span> 2 utilisateurs en charge de l'activité de l'Anah, 1 statisticien régional, 1 autre utilisateur
                        <br>
                        <span class="font-weight-bold">DDT :</span> 2 utilisateurs en charge de l’activité de l'Anah, 1 autre utilisateur
                      </div>
                      <!---pppi-->
                      <div class="alert alert-taupe2 mt-2" v-if="conv.ref=='pppi'">
                        <i class="fa fa-info-circle text-primary2" aria-hidden="true"></i><span class="font-weight-bold "> Attention, pour chaque outil, un nombre limité d'utilisateurs par territoire est autorisé :</span>
                        <br>
                        <span class="font-weight-bold">Pour le Parc Privé Potentiellement Indigne (PPPI) :</span>
                        <br>
                        <span class="font-weight-bold">DREAL :</span> 1 utilisateur en charge de l'activité de l'Anah, 1 statisticien régional, 1 autre utilisateur
                        <br>
                        <span class="font-weight-bold">DDT :</span> 2 utilisateurs en charge de l’activité de l'Anah, 1 autre utilisateur
                      </div>
                      <!---copro-->
                      <div class="alert alert-taupe2 mt-2" v-if="conv.ref=='copro'">
                        <i class="fa fa-info-circle text-primary2" aria-hidden="true"></i><span class="font-weight-bold  "> Attention, pour chaque outil, un nombre limité d'utilisateurs par territoire est autorisé :</span>
                        <br>
                        <span class="font-weight-bold">Pour le fichier de repérage des copropriétés fragiles :</span>
                        <br>
                        <span class="font-weight-bold">DREAL :</span> tous les référents en charge de l'activité de l'Anah, 3 autres utilisateurs
                        <br>
                        <span class="font-weight-bold">DDT :</span> tous les référents en charge de l’activité de l'Anah, 3 autres utilisateurs
                      </div>
                </div>
                <br>
                <div class="text-center">
                            <!-- <b-button  variant="primary" @click="toggleForme()" v-if="type_user != null && type_demande != null">Formulaire</b-button>-->
                </div>

                  <div id="form_inputs" v-if="display_form" class="text-left">
                      <p class="alert-info p-3 text-center">Merci de bien vouloir renseigner ces informations :</p>
                      <hr>
                      <div class="form-group">
                          <div v-if="type_demande!='acces_etat'">Bénéficiaire de l'extraction des données  :</div>
                          <br>
                          <label for="structure_name">Raison sociale </label>
                          <input type="text" class="form-control" id="structure_name" name="structure_name" aria-describedby="srtructure_name" placeholder="Précisez..." required>
                      </div>

                      <div class="form-group row">
                                  <div class="col">
                                  <div class="form-check form-check-inline">
                                      <label class="form-check-label">
                                          <input class="form-check-input" type="radio" name="sex"  value="h" checked>
                                          Monsieur
                                      </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                      <label class="form-check-label">
                                          <input class="form-check-input" type="radio" name="sex"  value="F">
                                                 Madame
                                      </label>
                                  </div></div>
                      </div>
                      <div class="form-group row">
                          <div class="col">
                              <input type="text" class="form-control" id="forname" name="forname" placeholder="Prénom" required>
                          </div>
                          <div class="col">
                              <input type="text" class="form-control" id="name" name="name" placeholder="Nom" required>
                          </div>
                      </div>
                      <div class="form-group">
                        <label for="email">Adresse mail  </label>
                        <input type="email" class="form-control" id="email" name="email" aria-describedby="emailHelp" placeholder="adresse mail" required>
                        <small id="emailHelp" class="form-text text-muted">Ce mail servira de contact administratif</small>
                      </div>
                      <div class="form-group">
                          <input type="texte" class="form-control" id="fonction" name="fonction" aria-describedby="fonctionHelp" placeholder="Fonction" required>
                      </div>
                      <div class="form-group">
                          <input type="texte" class="form-control" id="service" name="service" aria-describedby="servicenHelp" placeholder="Service" >
                      </div>

                      <div class="form-group">
                          <b-form-select v-model="type_etude" :options="options_type" :required="true" class="" id="type_etude" name="type_etude" aria-describedby="type_etude"></b-form-select>
                      </div>
                      <div class="form-group mb-3">
                          <input type="texte" class="form-control" id="title_etude" name="title_etude" aria-describedby="title_etude" placeholder="Préciser le titre de l'étude..." >
                      </div>
                      <br>
                      <div class="form-group  p-2" v-if="conv.ref != 'ppdom' && 1+1==3">
                          <input type="file" id="file" name="file" class="file">
                          <div class="input-group col-xs-12">
                              <span class="input-group-addon"><i class="fa fa-file-text-o" aria-hidden="true"></i></span>
                              <input type="text" class="form-control input-lg" disabled placeholder="Pas de fichier actuellement selectionné" required>
                              <span class="input-group-btn">
                                  <button class="browse btn btn-primary input-lg" type="button"><i class="glyphicon glyphicon-search"></i> Choisir un fichier à transmettre</button>
                              </span>
                              <br>
                          </div>
                          <br>
                          <button  class="btn btn-outline-grisBleuClaire btn-sm" @click=" display_file2 = ! display_file2 " >Ajouter un document supplémentaire
                               <i class="fa fa-plus-square text-secondary" aria-hidden="true" v-if="!display_file2"></i>
                               <i class="fa fa-minus-square text-secondary" aria-hidden="true" v-if="display_file2"></i>
                          </button>
                      </div>
                      <div  class="form-group  p-2" v-if="conv.ref != 'ppdom' && display_file2 && 1+1==3">
                          <input type="file" id="file2" name="file2" class="file2">
                          <div class="input-group col-xs-12">
                              <span class="input-group-addon"><i class="fa fa-file-text-o" aria-hidden="true"></i></span>
                              <input type="text" class="form-control input-lg" disabled placeholder="Pas de fichier actuellement selectionné">
                              <span class="input-group-btn">
                                  <button class="browse btn btn-primary input-lg" type="button"><i class="glyphicon glyphicon-search"></i> Choisir un fichier à transmettre</button>
                              </span>
                          </div>
                      </div>
                      <br>
                    <div class="form-check text-left">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" required>
                        Je reconnais avoir pris connaissance <a href="/storage/annexes/modalites-utilisation-outils-plateforme.pdf" target="_blank">des conditions générales d’utilisation</a> des outils de connaissance du parc privé
                      </label>
                    </div>
                    <div class="form-check text-left" v-if="type_user != 'autre_dtt_dreal' && type_demande!='acces_etat'">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" required>
                        Je m’engage à transmettre les conditions générales d'utilisation des outils de connaissance du parc privé dont je vais fournir des extractions, ainsi que la notice méthodologique associée à l’outil en téléchargement dans la rubrique
                        <a href="/pprive_doc/1" v-if="conv.ref == 'pp'" target="_blank">« Zone documentaire »</a>
                        <a href="/pppi_doc/1" v-else-if="conv.ref == 'pppi'" target="_blank">« Zone documentaire »</a>
                        <a href="/copro_doc/1" v-else-if="conv.ref == 'copro'" target="_blank">« Zone documentaire »</a>
                        <span v-else>« Zone documentaire »</span>
                      </label>
                    </div>
                      <br>
                      <button type="submit" class="btn btn-primary">Envoyer</button>
                  </div><!-- end hidden input -->
            </form>
    </div>
</template>
<script>

    import moment from 'moment';
    import Swal from 'sweetalert2';

    export default {
        name: "acte_engagement_forme",
        props: ['conv', 'structure_type', 'actes', 'user_category', 'popup_conges'],
        data(){
            return{
                csrfToken : null,
                display_form: false,
                display_file2: false,
                upFile:null,
                nb_acte : 0,
                type_etude: null,
                type_demande: null,
                type_user: null,
                options_user: [
                   { value: null, text: 'Merci de sélectionner votre statut...'},
                   //{ value: 'anah_dtt_dreal', text: 'En charge de l’activité de l’Anah DDT ou DREAL'},
                   //{ value: '  statiticien_dreal', text: 'Statisticien régional DREAL'},
                   //{ value: 'autre_dtt_dreal', text: 'Autre cas : DDT, DREAL, Ministère...'},
                ],
                options_demande: [
                    { value: null, text: 'Merci de sélectionner l\'objet de la demande ...'},
                    { value: 'acces_etat', text: 'Demande d\'accès utilisateur État'},
                    { value: 'extract_collectivite', text: 'Transmission d\'extractions Collectivité'},
                    { value: 'extract_be', text: 'Transmission d\'extractions Bureau d\'études'},
                ],
                options_type:    [
                        { value: null, text: 'Merci de choisir l\'objet de l\'étude...'},
                        { value: 'definition_politique_locale', text: 'Aide à la définition des politiques locales de l\'habitat' },
                        { value: 'programmmation_logement social', text: 'Aide à la programmation du logement social' },
                        { value: 'observation_politiques_local', text: 'Aide à l\'observation et à la mise en oeuvre des politiques locales de l\'habitat' },
                        { value: 'evaluation_politiques_publiques', text: 'Évaluation des politiques publiques' },
                    ],
            }
        },
        watch: {
          type_etude: function(val){
            (val != null && this.type_demande != null)?this.display_form=true:this.display_form=false;
          },
          type_demande: function(val){
            (val != null && this.type_demande != null)?this.display_form=true:this.display_form=false;
          }
        },
        mounted(){

            this.csrfToken = csrfToken;

            this.prepareComp();


            if(this.conv.ref=='ppdom'){
                this.options_type.push({ value: 'etude_autre', text: 'Autre' });
                this.display_form=true
            }



            //console.log(this.actes);

            //(this.conv.ref=='ppdom')?this.display_form=true:null;

            $(document).on('click', '.browse', function(){
                var file = $(this).parent().parent().parent().find('.file');
                file.trigger('click');
            });

            $(document).on('click', '.browse', function(){
                var file = $(this).parent().parent().parent().find('.file2');
                file.trigger('click');
            });

            $(document).on('change', '.file',(msg)=>{
                console.log(msg.currentTarget.files[0]);
            });

            $(document).on('change', '.file', function(){
                $(this).parent().find('.form-control').val($(this).val().replace(/C:\\fakepath\\/i, ''));
            });

            $(document).on('change', '.file2', function(){
                $(this).parent().find('.form-control').val($(this).val().replace(/C:\\fakepath\\/i, ''));
            });
        },
        methods:{
            prepareComp:function(){

              ////// TEMPO : POPUP/MESSAGE ABSENCE RÉPONSE RAPIDE DURANT LA PERIODE

              let popup_start_at = (this.popup_conges)?this.popup_conges.start_at:'19990101';
              let popup_end_at = (this.popup_conges)?this.popup_conges.end_at:'19990102';

              moment.locale('fr');

              (moment().isBetween(popup_start_at, popup_end_at))
                  ?Swal.fire({
                    title: 'Information',
                    html: '<span >Attention</span>, votre demande d\'accès ne pourra être traitée qu\'à compter du <span class="text-primary"> '+moment(popup_end_at).format('LL')+'</span>. <br><br/>Merci de votre compréhension.',
                    icon:'info',
                    confirmButtonText: 'Continuer'
                  }
                  ) :null;

              //////////   FIN TEMPO

              //console.log(this.user_category);
                for(let i = 0; i<this.user_category.length; i++){
                    this.options_user.push({value: this.user_category[i].name, text:this.user_category[i].label });
                }
            },
            submitQuerry: function(evt){
                //console.log('go');
            },
            getActesEngag: function(){
            },
            toggleForme: function(){
                (this.display_form==true)? this.display_form=false:this.display_form=true;
            }
        }
    }
</script>

<style scoped>

        .file{
            visibility: hidden;
            position: absolute;
        }

        .file2{
            visibility: hidden;
            position: absolute;
        }

</style>