"use strict";

export class Table2CSVService {

    //protected pgGeoDataDB: string;
    //protected headers: Headers;
    //protected delimiter: string;

    constructor(){
        //this.headers = new Headers({'Content-Type': 'application/json'});
        this.delimiter = ';';
    }

    getTable($table, filename) {

        let $headers = $table.find('tr:has(th)');
        let $rows = $table.find('tr:has(td)');
            // Temporary delimiter characters unlikely to be typed by keyboard
            // This is to avoid accidentally splitting the actual contents
        let tmpColDelim = String.fromCharCode(11);// vertical tab character
        let tmpRowDelim = String.fromCharCode(0);//
            // actual delimiter characters for CSV format
        let colDelim = this.delimiter;
        let rowDelim = '"\r\n"';
        // Grab text from table into CSV formatted string
        let csv = '"';
        csv += this.formatRows($headers.map(this.grabRow));
        csv += rowDelim;
        csv += this.formatRows($rows.map(this.grabRow)) + '"';

        // Data URI
        let csvData = 'data:application/csv;charset=utf-8,' + encodeURIComponent(csv);
        // For IE (tested 10+)
        if (window.navigator.msSaveOrOpenBlob) {
            let blob = new Blob([decodeURIComponent(encodeURI(csv))], {
                type: "text/csv;charset=utf-8;"
            });
            navigator.msSaveBlob(blob, filename);
        } else {
            $(this)
                .attr({
                    'download': filename
                    ,'href': csvData
                    //,'target' : '_blank' //if you want it to open in a new window
                });
        }

    }

    //------------------------------------------------------------
    // Helper Functions
    //------------------------------------------------------------
    // Format the output so it has the appropriate delimiters
    formatRows(rows){
        return rows.get().join(tmpRowDelim)
            .split(tmpRowDelim).join(rowDelim)
            .split(tmpColDelim).join(colDelim);
    }
    // Grab and format a row from the table
    grabRow(i,row){

        var $row = $(row);
        //for some reason $cols = $row.find('td') || $row.find('th') won't work...
        var $cols = $row.find('td');
        if(!$cols.length) $cols = $row.find('th');

        return $cols.map(grabCol)
            .get().join(tmpColDelim);
    }
    // Grab and format a column from the table
    grabCol(j,col){
        var $col = $(col),
            $text = $col.text();

        return $text.replace('"', '""'); // escape double quotes
    }
    // This must be a hyperlink
    save(htmlTableElem, outputFile){
        // var outputFile = 'export'
        //let outputFile = window.prompt("What do you want to name your output file (Note: This won't have any effect on Safari)") || 'export';
        outputFile = outputFile.replace('.csv','') + '.csv'
        // CSV
        this.getTable(htmlTableElem, outputFile);
        // IF CSV, don't do event.preventDefault() or return false
        // We actually need this to be a typical hyperlink
    }

    toXLS(tableId, filename) {
        //this._filename = (typeof filename == 'undefined') ? tableId : filename;
        //var ieVersion = this._getMsieVersion();
        //Fallback to CSV for IE & Edge
        //if ((this._getMsieVersion() || this._isFirefox()) && this._fallbacktoCSV) {
        //    return this.toCSV(tableId);
        //} else if (this._getMsieVersion() || this._isFirefox()) {
        //    alert("Not supported browser");
        //}
//
        //Other Browser can download xls
        var htmltable = document.getElementById(tableId);
        var html = htmltable.outerHTML;

        this._downloadAnchor("data:application/vnd.ms-excel" + encodeURIComponent(html), 'xls');
    }

    toCSV(tableId, filename){

        let _filename = (typeof filename==='undefined')?tableId: filename;
        // Generate our CSV string from out HTML Table
        let csv = this.tableToCSV(document.getElementById(tableId));
        // Create a CSV Blob
        let blob = new Blob([csv], {type: "text/csv"});

        // Determine which approach to take for the download
        if(navigator.msSaveOrOpenBlob){
            // Works for Internet Explorer and Microsoft Edge
            navigator.msSaveOrOpenBlob(blob, this._filename + ".csv");
        }else{
            this._downloadAnchor(URL.createObjectURL(blob), 'csv');
        }
    }


    tableToCSV(table) {

        // We'll be co-opting `slice` to create arrays
        var slice = Array.prototype.slice;

        let that = this;
        if(table){

            return slice
                .call(table.rows)
                .map(function(row) {
                    return slice
                        .call(row.cells)
                        .map(function(cell) {
                            return '"t"'.replace("t", cell.textContent.trim());
                        })
                        .join(';');
                })
                .join("\r\n");
        }else{
            return;
        }
    }


}