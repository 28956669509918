<template>
    <div class="h-100">
        <div v-if="!outlined"
            :class="`d-flex flex-column ${color == 'primary' ? 'bg-primary' : ''} ${color == 'secondary' ? 'bg-tersiary ' : ''} ${color == 'tertiary' ? 'bg-info' : ''}  text-white justify-content-between align-items-center p-2 h-100 `">
            <div class="text-center">
                <h6>{{ title }}
                    <span @click="handleModal" :class="`rounded-full-call   `">
                        ?
                    </span>
                </h6>
            </div>
            <div v-if="double" class="w-100">
                <div v-for="stat in val"
                    class="w-100 d-flex justify-content-between align-items-center coproff-text-xs">
                    <div class="font-weight-bold">{{ stat.label }}</div>
                    <div if="stat.nb && stat.taux">{{ String(stat.nb) | formatNumber }} ({{ stat.taux }}%)</div>
                </div>
            </div>
            <div v-else class="text-center">
                <div v-if="val.length && val.length > 0 && val[0].nb" class="coproff-big-nbr">
                    {{ String(val[0].nb) | formatNumber }}
                    <span class="coproff-unity" v-if="val[0].unity">{{ val[0].unity }}</span>
                </div>
                <div v-if="val.length && val.length > 0 && val[0].taux" class="coproff-prct">
                    <span style="font-size:0.85rem;font-weight:500;"
                        v-if="title.includes('Évolution du taux de copropriétés immatriculées') && val[0].taux <= 0">
                        Non Applicable
                    </span>
                    <span v-else>
                        <span v-if="title.includes('Évolution') && val[0].taux > 0">+</span>
                        {{ val[0].taux }}%
                    </span>
                </div>
            </div>
        </div>
        <div v-else
            :class="`d-flex flex-column ${color == 'primary' ? 'coproff-outline-primary' : ''} ${color == 'secondary' ? 'coproff-outline-secondary ' : ''} ${color == 'tertiary' ? 'coproff-outline-tersiary' : ''}  justify-content-between align-items-center p-2 h-100 `">
            <div class="text-center">
                <h6>{{ title }}</h6>
                <span @click="handleModal"
                    :class="`rounded-full-call-white  ${color == 'primary' ? 'hover:text-primary' : ''} ${color == 'secondary' ? 'hover:text-tersiary ' : ''} ${color == 'tertiary' ? 'hover:text-info' : ''}  `">
                    ?
                </span>
            </div>
            <div v-if="double" class="w-100">
                <div v-for="stat in val"
                    class="w-100 d-flex justify-content-between align-items-center coproff-text-xs">
                    <div class="font-weight-bold">{{ stat.label }}</div>
                    <div if="stat.nb && stat.taux">{{ String(stat.nb) | formatNumber }} ({{ stat.taux }}%)</div>
                </div>
            </div>
            <div v-else class="text-center">
                <div v-if="val.length && val.length > 0 && val[0].nb" class="coproff-big-nbr">
                    {{ String(val[0].nb) | formatNumber }}
                    <span class="coproff-unity" v-if="val[0].unity">{{ val[0].unity }}</span>
                </div>
                <div v-if="val.length && val.length > 0 && val[0].taux" class="coproff-prct">
                    <span style="font-size:0.85rem;font-weight:500;"
                        v-if="title.includes('Évolution du taux de copropriétés immatriculées') && val[0].taux <= 0">
                        Non Applicable
                    </span>
                    <span v-else>
                        <span v-if="title.includes('Évolution') && val[0].taux > 0">+</span>
                        {{ val[0].taux }}%
                    </span>
                </div>
            </div>
        </div>
        <dialogModal :displayDialog="displayDialog" @close="displayDialog = false">
            <div slot="content">
                <h5 class="font-weight-bold text-primary">{{ title }}</h5>
                <div class="pp-coproff-src">Source : {{ src }}</div>
                <div class="pp-coproff-src-ref">
                    <div v-html="refIndic"></div>
                </div>
            </div>
        </dialogModal>
    </div>
</template>

<script>
import DialogModal from "../DialogModal.vue";
export default {
    name: "coproff_bloc_stats",
    components: {
        dialogModal: DialogModal
    },
    props: {
        "double": {
            type: Boolean,
            required: true,
            default: false
        },
        "outlined": {
            type: Boolean,
            required: true,
            default: true
        },
        "stattype": {
            type: String,
            required: false
        },
        "title": {
            type: String,
            required: true
        },
        "val": {
            type: [Array, Object],
            required: true
        },
        "color": {
            type: String,
            required: true
        },
        "refIndic": { "type": String, required: false, default: "..." },
        "src": { type: String, required: false, default: "" }
    },
    data: function () {
        return {
            displayDialog: false,
        }
    },
    mounted() {
        //console.log('CoproffBlocSats mounted', this.stattype, this.val, this.color);
    },
    watch: {

    },
    methods: {
        handleModal() {
            this.displayDialog = !this.displayDialog;
        }
    }
};
</script>

<style>
.pp-coproff-calc {
    display: block !important;
    font-size: 1.0rem !important;
    color: #6F647D !important;
    font-weight: 600 !important;
    text-align: center !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}
</style>

<style scoped>
.coproff-unity {
    font-size: 1.0rem;
}

.bg-tersiary {
    background-color: #6F647D;
}

.bg-white {
    background-color: #fff;
}

.text-tersiary {
    color: #6F647D;
}

.rounded-full-call {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    line-height: 0.7rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5rem;
    font-size: 0.5rem;
    line-height: 0.8rem;
    font-weight: 600;
    padding: 0.0 0.20rem;
    border-radius: 9999px;
    cursor: pointer;
    background-color: unset;
    color: #FFF;
    border: 1px solid #fff;

    &:hover {
        background-color: #FFF;
        color: #6F647D;
        border: 1px solid #fff;
    }
}

.rounded-full-call-white {
    font-size: 0.5rem;
    line-height: 0.5rem;
    font-weight: 600;
    padding: 0.03rem 0.20rem;
    border-radius: 9999px;
    cursor: pointer;
    color: #6F647D;
    border: 1px solid #6F647D;

    &:hover {
        color: #fff;
        background-color: #6F647D;
    }
}

.pp-coproff-src {
    font-size: 0.95rem;
    color: #6F647D;
    font-weight: 600;
    margin-bottom: 1rem;

}

.pp-coproff-src-ref {
    font-size: 0.95rem;
    color: #6F647D;
}




.coproff-outline-primary {
    color: #d10056;
    border: 1px solid #d10056;
}

.coproff-outline-secondary {
    color: #557ca8;
    border: 1px solid #557ca8;
}

.coproff-outline-tersiary {
    color: #6F647D;
    border: 1px solid #6F647D;
}

.coproff-big-nbr {
    font-size: 1.9rem;
    font-weight: bold;
}

.coproff-prct {
    font-weight: 400;
    font-size: 1.2rem;
}

.coproff-text-xs {
    font-size: 0.85rem;
}
</style>