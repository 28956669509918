<template>
    <div>
        <h5 class="text-primary">Tableaux thématiques :</h5>

        <br>

        <b-form-select v-model="selectedDep" :options="deps" class="mb-3" ></b-form-select>
        <br>


        <div v-if="selectedDep != null " >
                <table class="table table-bordered">
                    <tr v-for="tableRef in tableRefs">
                        <td width="95%" >
                            <span><i class="fa fa-arrow-circle-right text-primary" aria-hidden="true"></i> {{tableRef.TITRE}}</span>
                        </td>
                        <td class="text-right" width="5%">
                            <button class="btn btn-sm btn-primary" @click="loaddata(tableRef)" v-b-tooltip.hover title="'Télécharger les données aux formats CSV'"> Exporter <i class="fa fa-cloud-download" aria-hidden="true"></i></button>
                        </td>
                    </tr>
                </table>
        </div>
        <fbm-rotate-spinner :test="isSpinning"></fbm-rotate-spinner>
    </div>
    
</template>

<script>
    import {PGService} from '../services/PGService';
    import {ClassementPPPIService} from '../services/ClassementPPPIService';
    export default {
        name: "pppi-classement",
        props: ['selectedReg', 'selectedRegLabel'],
        data: function(){
            return {
                isSpinning: false,
                deps: [{'value': null, 'text': 'Merci de selectionner un département...'}],
                selectedDep : null,
                selectedDepLabel: '',
                tableRefs :  [],

            }
        },
        mounted(){
            this.classementPPPIService = new ClassementPPPIService();
            this.tableRefs = this.classementPPPIService.getstructure();
            this.pgService = new PGService();
            this.getDep(this.selectedReg);
        },
        watch: {
            selectedDep : function(val){

                for(let i=0;i<this.deps.length;i++){

                    (this.deps[i].value==val)?this.selectedDepLabel = this.deps[i].text:null;
                    //console.log(this.selectedDepLabel);
                }
            }
        },
        methods:{

            getDep: function(regCode){

                //console.log(regCode);
                this.isSpinning = true;

                this.pgService.get_cog('DEP', '20', regCode , this.section ).then((result)=>{


                    for(let i =0; i<result.data.data.length; i++)
                    {
                        this.deps.push({'value': result.data.data[i].codegeo, 'text': result.data.data[i].label+' ('+result.data.data[i].codegeo+')'});
                        (i==result.data.data.length-1)?this.isSpinning=false:null;
                    }

                },(err)=>{ alert("pbm de récupération de données départementales "); this.isSpinning = false;});
            },
            loaddata: function(ref){

                this.isSpinning = true;

                this.classementPPPIService.callQuerry(ref.COLONNES, this.selectedDep).then((result)=>{

                    //console.log(ref);
                    //console.log(result.data.data);

                    let baseData = result.data.data;
                    let theCSV = this.buildHeader(ref);

                    for(let a=0;a<ref.COLONNES.length;a++)
                    {
                        theCSV += ref.COLONNES[a].COURT+";";
                    }
                    theCSV += "\n";

                    for(let i=0;i<baseData.length; i++){

                        for(let a=0;a<ref.COLONNES.length;a++)
                        {
                          let dataValue = ( ref.COLONNES[a] && ref.COLONNES[a].NOM && baseData[i][ref.COLONNES[a].NOM])?baseData[i][ref.COLONNES[a].NOM].replace(/,/g, '.'): baseData[i][ref.COLONNES[a].NOM]; // convert ',' as '.' for numeric opperations
                          dataValue = (a>2 && dataValue!='s' && Math.round(dataValue*100)/100!=null )?Math.round(dataValue*100)/100:dataValue;//round value if numeric !!!
                          theCSV += dataValue+";";
                          (a==ref.COLONNES.length-1)?theCSV+="\n":null;
                        }
                    }

                    //console.log(theCSV);
                    this.saveCSVFile(theCSV, ref);
                    this.isSpinning = false;

                },(err)=>{ alert("pbm de récupération de données départementales "); this.isSpinning = false;});

            },
            saveCSVFile(csv, ref){

                let fileName = 'pppi_2019_'+this.slugifyText(ref.TITRE)+'_'+this.selectedDepLabel+'.csv';
                let blob = new Blob([csv], {type: "text/csv;encoding:utf-8"});
                FileSaver.saveAs(blob, fileName);
            },
            slugifyText(val){

                {
                    return val.toString().toLowerCase()
                        .replace(/\s+/g, '-')           // Replace spaces with -
                        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
                        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
                        .replace(/^-+/, '')             // Trim - from start of text
                        .replace(/-+$/, '');            // Trim - from end of text
                }
            },

            buildHeader(ref){

                let segment = "\uFEFFPARC PRIVÉ POTENTIELLEMENT INDIGNE - 2019\n";
                segment += ref.TITRE+";;;;;;\n";
                segment += this.selectedDepLabel+";;;;;;\n";
                segment += ";;;;;;\n";
                segment += "Source : MTE - Filocom 2019 d'après la DGFiP, traitement PPPI Anah\n";
                segment += ";;;;;;\n"
                segment += "Ces données sont soumises à des règles de diffusion. Par ailleurs, leur interprétation et leur exploitation imposent des précautions méthodologiques. Se reporter aux rubriques « Règles d'utilisation et de diffusion » et « Méthodologie PPPI » pour plus de précisions.;\n";
                segment += ";;;;;;\n";

                return segment;
            }
        }

    }
</script>

<style scoped>

</style>