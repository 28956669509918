<template>
    <div class="w-100 d-flex flex-column ">
        <h3 class="col d-flex " v-if="terrCodeSelected && terrLabelSelected">
            <span class="mr-1"> Territoire d'étude :</span>
            <span v-if="terrLevelSelected == 'multi_code'" class="text-primary ml-2">
                Multi départemental ({{ userPerims.dep_complet.toString() }})
            </span>
            <span v-else class="text-primary"> {{ terrLabelTitleSelected }} ({{ terrCodeSelected }})
                {{ terrLabelSelected }}</span>
        </h3>
        <h3><i class="fa fa-download  text-primary" aria-hidden="true"></i> Exportation des données</h3>
        <div class="card">
            <Loading v-if="isSpinning" class="text-center" />
            <div v-else class="card-body">
                <div class="bg-primary p1 text-center text-white" style="width:100px;">Millesimes</div>
                <div class="form-group" v-if="millesimes && millesimes.length > 0">
                    <select class="form-control" id="millesimerefs" v-model="selectedmillesime">
                        <option v-for="ref in millesimes" :key="ref.id" :value="ref.id">{{ ref.title }}</option>
                    </select>
                </div>
                <div v-else class="text-center my-4">
                    <p>Désolé, aucun millésime de données n'est disponbile pour cette séléction.</p>
                </div>
                <p class="font-italic text-primary" style="font-size:0.77rem;line-height:0.75rem;">
                    Les données d'un millésime d'une année N sont construites à partir des données foncières au
                    1<sup>er</sup> janvier et les données RNIC arrêtées au
                    1<sup>er</sup> septembre de cette même année N
                </p>
                <div class="bg-primary mt-4 p1 text-center text-white" style="width:100px;">Filtres</div>
                <div
                    class="d-flex flex-column justify-content-start align-items-start border border-primary rounded p-2">

                    <div class="d-flex flex-column  flex-md-row w-100 align-items-center ">
                        <div class="form-check col-12 col-md-5 pp-coprof-export-check-correction">
                            <div>Immatriculations</div>
                            <div class="pp-coprof-export-check-input">
                                <input class="form-check-input" type="checkbox" v-model="no_immat_only"
                                    id="noImmatCheck" :checked="no_immat_only">
                                <label class="form-check-label" for="noImmatCheck">
                                    Copropriétés non immatriculées uniquement
                                </label>
                            </div>
                        </div>
                        <div v-if="displaySearch" class="form-group col-12 col-md-3">
                            <SearchTerr :level="'com'" :parentterr="terrCodeSelected" @selected="handleSelectedTerr">
                                Filtrer une commune
                            </SearchTerr>
                        </div>
                        <div class="form-group col-12 col-md-3" v-if="zonage && zonage.length > 0 && !displaySearch">
                            <label for="exampleFormControlSelect1">Zonage</label>
                            <select class="form-control" id="millesimerefs" v-model="zonageSelected"
                                @change="handleSublevelChange">
                                <option :value="null">Tous mes territoires</option>
                                <option v-for="(terr, index) in zonage" :key="index" :value="terr">
                                    ({{ terr.code }}){{ terr.nom }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-12 col-md-1 " v-if="terrLevelSelected === 'dep' || displaySearch">
                            <div class="d-flex justify-content-between align-item-center">

                                <div class="mx-2 text-center text-xs" style="font-size:0.6rem;margin-top:25px;"> ou <br>
                                    filtrer </div>
                                <div @click="displaySearch = !displaySearch">
                                    <div class="pp-btn-search">
                                        <svg v-if="!displaySearch" xmlns="http://www.w3.org/2000/svg" width="25"
                                            height="25" viewBox="0 0 16 16">
                                            <path fill="currentColor"
                                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0" />
                                        </svg>
                                        <svg v-else xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                            viewBox="0 0 16 16">
                                            <path fill="currentColor"
                                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8L4.646 5.354a.5.5 0 0 1 0-.708" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-12 col-md-3">
                            <label for="exampleFormControlSelect1">Taille des copropriétés</label>
                            <select class="form-control" id="coproSize" v-model="coproSize">
                                <option value="null">Toutes les copropriétés</option>
                                <option value="10">Moins de 10 logmt</option>
                                <option value="10_49">de 10 à 49 logmt</option>
                                <option value="49_99">de 50 à 99 logmt</option>
                                <option value="100">plus de 100 logmt</option>
                            </select>
                        </div>
                    </div>
                    <div class="row col-12 d-flex flex-row justify-content-center aligne-items-center ">
                        <div class="form-check my-3">
                            <input class="form-check-input" type="checkbox" v-model="useNull" id="noNull"
                                :checked="useNull">
                            <label class="form-check-label" for="noNull">
                                <svg width="30" height="30" viewBox="0 0 37 38" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_60_432)">
                                        <path
                                            d="M20.5025 4.98434L33.8133 28.6615C34.0162 29.0226 34.1231 29.4321 34.1231 29.849C34.1231 30.2659 34.0162 30.6754 33.8133 31.0365C33.6103 31.3975 33.3184 31.6973 32.9669 31.9058C32.6153 32.1143 32.2166 32.224 31.8107 32.224H5.18915C4.78323 32.224 4.38446 32.1143 4.03292 31.9058C3.68138 31.6973 3.38947 31.3975 3.18651 31.0365C2.98355 30.6754 2.87671 30.2659 2.87671 29.849C2.87671 29.4321 2.98356 29.0226 3.18653 28.6615L16.4973 4.98434C17.3868 3.40101 19.6114 3.40101 20.5025 4.98434ZM18.4999 23.75C18.091 23.75 17.6989 23.9168 17.4098 24.2138C17.1207 24.5107 16.9582 24.9134 16.9582 25.3333C16.9582 25.7533 17.1207 26.156 17.4098 26.4529C17.6989 26.7499 18.091 26.9167 18.4999 26.9167C18.9088 26.9167 19.3009 26.7499 19.59 26.4529C19.8791 26.156 20.0416 25.7533 20.0416 25.3333C20.0416 24.9134 19.8791 24.5107 19.59 24.2138C19.3009 23.9168 18.9088 23.75 18.4999 23.75ZM18.4999 12.6667C18.1223 12.6667 17.7578 12.8091 17.4757 13.0668C17.1935 13.3245 17.0132 13.6796 16.969 14.0648L16.9582 14.25V20.5833C16.9587 20.9869 17.1091 21.3751 17.3789 21.6685C17.6486 21.962 18.0173 22.1386 18.4095 22.1622C18.8018 22.1859 19.1881 22.0548 19.4894 21.7958C19.7907 21.5367 19.9844 21.1693 20.0308 20.7686L20.0416 20.5833V14.25C20.0416 13.8301 19.8791 13.4274 19.59 13.1304C19.3009 12.8335 18.9088 12.6667 18.4999 12.6667Z"
                                            fill="#D10056" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_60_432">
                                            <rect width="37" height="38" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                Remarques : les copropriétés dont la valeur filtrée n’est pas renseignée seront exclues
                                de votre resultat. Afin d’obtenir également les entités non renseignées, veuillez cocher
                                explicitement cette case
                            </label>
                        </div>
                    </div>
                    <div class=" w-100 d-flex flex-row justify-content-end align-items-center my-3 text-right">
                        <div class="text-primary">Télécharger mes données (format csv recommandé)</div>
                        <button class="btn btn-primary ml-2" @click="handleEportData('csv')">CSV</button>
                        <button class="btn btn-primary ml-2" @click="handleEportData('xlsx')">XLSX</button>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="loadmodal" ref="loadmodal" ok-diseabled="true" ok-title="ok" :no-close-on-backdrop="true"
            cancel-title="Annuler" title="Exportation de données en cours" header-bg-variant="primary"
            header-text-variant="fbmWhite">
            <div class="text-center">
                <i class="fa fa-cloud-download fa-3x text-primary" aria-hidden="true" v-if="!isSpinning"></i>
                <br>
                <p class="my-4" v-html="exportMsg"></p>
                <a v-if="exportPath && displayDownloadBtn && !isSpinning" :href="exportPath" class="btn btn-primary">
                    Télécharger mes données
                </a>
                <fbm-rotate-spinner v-if="isSpinning"></fbm-rotate-spinner>
            </div>
            <template slot="modal-footer" class="w-100">
                <b-btn size="sm" class="float-right" variant="primary" @click="hideModal"> Fermer </b-btn>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { PGService } from '../../services/PGService';
import { ExportDataService } from '../../services/ExportDataService';
import Loading from '../Loading.vue';
import SearchTerr from './SearchTerr.vue';

export default {
    name: "coproff_export",
    props: ['section', 'dfperims', 'siret', 'terrLevelSelected', 'terrCodeSelected', 'terrLabelSelected'],
    components: {
        Loading, SearchTerr
    },
    data: function () {
        return {
            csrf: null,
            base_zonnage: null,
            isSpinning: false,
            tablesRef: null,
            exportMsg: '',
            displayDownloadBtn: true,
            exportDispo: false,
            exportPath: '',
            exportCountRow: null,
            selectedmillesime: null,
            millesimes: null,
            zonage: [],
            zonageSelected: null,
            coproSize: null,
            useNull: false,
            no_immat_only: false,
            myReg: "11",
            userPerims: null,
            multiCodes: null,
            //terrLevelSelected: null,
            terrLabelTitleSelected: null,
            //terrCodeSelected: null,
            //terrLabelSelected: null,
            displaySearch: false,
            subLevel: null,
            ept_coms: null,
            that: this
        }
    },
    mounted() {
        this.isSpinning = true;
        this.userPerims = JSON.parse(this.dfperims);
        console.log("dfperims : ", this.userPerims, this.terrLevelSelected, this.terrCodeSelected, this.terrLabelSelected);
        this.base_zonnage = this.buildDFPerims(this.userPerims);

        axios.get("/api/coproff-refs").then((response) => {
            console.log("millesimes : ", response.data, typeof (response.data));
            this.millesimes = response.data;
            this.selectedmillesime = response.data.filter((m) => m.status === "current")[0].id;
            this.isSpinning = false;

        }).catch((e) => {
            console.log("Erreur de récupeation des millésimes de données : ", e);
            this.isSpinning = false;
        });

        this.buildUserLevel();
    },
    watch: {
        zonageSelected: (val) => {
            //console.log("zonage selected", val.code);
        },
        coproSize: (val) => {
            console.log("coproSize", val);
        },
        selectedmillesime: (val) => {
            console.log("selectedmillesime", val);
        },
        useNull: (val) => {
            console.log("useNull", val);
        },
        immat_only: (val) => {
            console.log("immat_only", val);
        },
        displaySearch: {
            handler: function (val) {
                console.log("displaySearch", val);
                if (val == false) {
                    this.buildDFPerims(this.userPerims);
                    this.buildUserLevel();
                }
            }
        }
    },
    methods: {

        hideModal: function () {
            this.$refs.loadmodal.hide();
        },
        buildDFPerims: function (refPerims) {
            let perims = { "level": null, "code": null };
            if ("reg_complet" in refPerims && Array.isArray(refPerims.reg_complet) && refPerims.reg_complet.length > 0) {
                if (refPerims.reg_complet[0] === "999") {
                    this.terrLevelSelected = "fra";
                    this.terrCodeSelected = "fra";
                    this.terrLabelTitleSelected = "";
                    return { "level": 'fra', "code": 'fra', "label": "France entière" };
                } else {
                    this.terrLevelSelected = "reg";
                    this.terrCodeSelected = refPerims.reg_complet[0];
                    this.terrLabelTitleSelected = "Région ";
                    return { "level": 'reg', "code": refPerims.reg_complet[0], "label": "Régions" };
                }
            } else if ("dep_complet" in refPerims && Array.isArray(refPerims.dep_complet) && refPerims.dep_complet.length > 0) {
                if (refPerims.dep_complet.length > 1) {
                    this.terrLevelSelected = "multi_dep";
                    this.terrLabelTitleSelected = "Multi Départemental ";
                    this.terrCodeSelected = null;
                    return { "level": 'dep', "code": null, "label": "Département" };
                } else {
                    this.terrLevelSelected = "dep";
                    this.terrLabelTitleSelected = "Département ";
                    this.terrCodeSelected = refPerims.dep_complet[0];
                    return { "level": 'dep', "code": refPerims.dep_complet[0], "label": "Département" };
                }

            } else if ("comm_by_dep" in refPerims && Object.keys(refPerims.comm_by_dep)[0] && Array.isArray(refPerims.comm_by_dep[Object.keys(refPerims.comm_by_dep)[0]])) {
                let coms = [];
                Object.keys(refPerims.comm_by_dep).forEach(keys => {
                    coms = [].concat(coms, refPerims.comm_by_dep[keys]);
                });
                this.terrLevelSelected == 'ept' ? this.ept_coms = coms : null;
                if (coms.length > 1) {
                    //this.terrLevelSelected = !this.terrLevelSelected ? "epci" : this.terrLevelSelected;
                    this.terrCodeSelected = this.siret.length > 9 ? this.siret.substring(0, 9) : this.siret;
                    this.terrLabelTitleSelected = "EPCI ";
                    return { "level": this.terrLevelSelected, "code": this.terrCodeSelected, "label": "EPCI " };
                }
                this.terrLevelSelected = "com";
                this.terrCodeSelected = coms[0];
                this.terrLabelTitleSelected = "Commune ";
                return { "level": 'com', "code": coms.length > 0 ? coms[0] : [], "label": "Communes" };
            } else {
                return null;
            }
        },
        buildUserLevel: function () {
            switch (this.terrLevelSelected) {
                case 'fra':
                    axios.get("https://geo.api.gouv.fr/regions?fields=nom,code")
                        .then((result) => {
                            this.terrLabelSelected = "France entière";
                            this.zonage = result.data;
                        })
                    break;
                case 'reg':
                    this.terrCodeSelected = (this.terrCodeSelected && this.terrCodeSelected.length === 1) ? '0' + this.terrCodeSelected : this.terrCodeSelected;
                    axios.get(`https://geo.api.gouv.fr/regions/${this.terrCodeSelected}/departements?fields=nom,code,region`)
                        .then((result) => {
                            this.terrLabelSelected = result.data[0].region.nom;
                            this.zonage = result.data;
                        })
                    break;
                case ('multi_dep'):
                    this.terrCodeSelected = (this.terrCodeSelected && this.terrCodeSelected.length === 1) ? '0' + this.terrCodeSelected : this.terrCodeSelected;
                    this.zonage = [];
                    this.userPerims.dep_complet.forEach((elem) => {
                        elem = elem.length === 1 ? '0' + elem : elem;
                        axios.get(`https://geo.api.gouv.fr/departements/${elem}?fields=nom,cod,codeRegion,region`)
                            .then((result) => {
                                this.terrLabelSelected = result.data.nom;
                                this.zonage = [
                                    ...this.zonage,
                                    {
                                        "code": result.data.code,
                                        "nom": result.data.nom
                                    }
                                ]
                                this.zonage = this.zonage.sort((a, b) => a.code > b.code ? 1 : -1);
                            })
                    })

                    break;
                case ('dep'):
                    this.terrCodeSelected = (this.terrCodeSelected && this.terrCodeSelected.length === 1) ? '0' + this.terrCodeSelected : this.terrCodeSelected;
                    this.zonage = [];
                    this.userPerims.dep_complet.forEach((elem) => {
                        elem = elem.length === 1 ? '0' + elem : elem;
                        axios.get(`https://geo.api.gouv.fr/departements/${elem}?fields=nom,cod,codeRegion,region`)
                            .then((result) => {
                                this.terrLabelSelected = result.data.nom;
                                this.zonage = [
                                    ...this.zonage,
                                    {
                                        "code": result.data.code,
                                        "nom": result.data.nom
                                    }
                                ]
                            })
                    })
                    break;
                case 'epci':
                    axios.get(`https://geo.api.gouv.fr/epcis/${this.terrCodeSelected}/communes?fields=nom,code,codesDepartements`)
                        .then((result) => {
                            console.log('coms for epci', result.data);
                            this.zonage = result.data;
                            this.subLevel = "com";
                            this.terrLabelSelected = result.data.nom;
                        })
                    break;
                case 'ept':
                    this.zonage = [];
                    this.ept_coms.forEach((com, index) => {
                        axios.get(`https://geo.api.gouv.fr/communes/${com}?fields=code,nom`)
                            .then((result) => {
                                this.zonage = [...this.zonage,
                                {
                                    "code": result.data.code,
                                    "nom": result.data.nom
                                }
                                ];

                                if (index === this.ept_coms.length - 1) {
                                    this.subLevel = "com";
                                    //this.terrLabelSelected = result.data.nom;
                                }
                            })
                    })
                    break;
                case 'com':
                    this.terrCodeSelected = (this.terrCodeSelected.length === 4) ? '0' + this.terrCodeSelected : this.terrCodeSelected;
                    axios.get(`https://geo.api.gouv.fr/communes/${this.terrCodeSelected}?fields=departement`)
                        .then((result) => {
                            this.terrLabelSelected = result.data.nom;
                            this.subLevel = "com";
                            this.zonage = [{
                                "code": result.data.code,
                                "nom": result.data.nom
                            }]
                        })
                    break;
                default:
                    this.zonage = null;
                    break;
            }
        },
        handleSelectedTerr: function (terr, level) {
            this.zonageSelected = terr;
            this.terrLevelSelected = level;
        },
        handleSublevelChange: function (e) {
            let that = this;
            switch (this.terrLevelSelected) {
                case 'fra':
                    this.subLevel = "reg";
                    break;
                case 'reg':
                    this.subLevel = "dep";
                    break;
                case 'multi_dep':
                    this.subLevel = 'dep';
                case 'dep':
                    this.subLevel = "dep";
                    break;
                case 'epci':
                    that.subLevel = "com";
                    break;
                case 'ept':
                    that.subLevel = "com";
                    break;
                case 'com':
                    this.subLevel = null;
                    break;
                default:
                    this.subLevel = null;
                    break;
            }

        },
        handleEportData: function (fileFrmt = 'csv') {
            this.isSpinning = true;
            this.$refs.loadmodal.show();
            this.exportMsg = "Exportation de données en cours, veuillez patienter...";

            this.terrLevelSelected === 'multi_dep' && !this.terrCodeSelected ? this.terrCodeSelected = this.userPerims.dep_complet : null;

            axios.post("/coproff_extract", {
                file_format: fileFrmt,
                millesime: this.selectedmillesime,
                terr_code: this.subLevel && this.zonageSelected ? this.zonageSelected.code : this.terrCodeSelected,
                terr_label: this.subLevel && this.zonageSelected ? this.zonageSelected.nom : null,
                terr_level: this.subLevel && this.zonageSelected ? this.subLevel : this.terrLevelSelected,
                copro_size: this.coproSize,
                use_null: this.no_immat_only,
                immat_only: this.no_immat_only
            }).then((response) => {
                this.exportCountRow = response.data.data.count;
                if (this.exportCountRow > 0) {
                    this.exportMsg = "Exportation terminée, cliquez sur le lien pour télécharger vos données archivées ";
                    this.displayDownloadBtn = true;
                    this.exportPath = response.data.data.zip;
                } else {
                    this.displayDownloadBtn = false;
                    this.exportMsg = "Votre recherche ne renvoie aucun résultat, veuillez réessayer avec d'autres critères de recherche ";
                }
                this.isSpinning = false;
                this.buildDFPerims(this.userPerims);
                this.buildUserLevel()
                this.resetZonage();
                this.displaySearch = false;

            }).catch((e) => {
                console.log("Erreur de récupeation des données : ", e);
                this.displayDownloadBtn = false;
                this.exportMsg = "Erreur lors de l'exportation, veuillez réessayer ultérieurement";
                this.isSpinning = false;
                this.buildDFPerims(this.userPerims);
                this.buildUserLevel()
                this.resetZonage();
                this.displaySearch = false;
            });
        },
        resetZonage: function () {
            if (this.zonage && this.zonage.length > 0) {
                this.zonageSelected = null;

            }
        }

    }
}
</script>

<style scoped>
.modal-backdrop {
    opacity: 0.5 !important;
}

.pp-coprof-export-check-correction {
    padding-bottom: 5px;
    text-align: left;
    margin-top: -12px;
}

.pp-coprof-export-check-input {
    border: 1px solid #cccccc;
    text-align: center;
    border-radius: 0px;
    padding: 7px 5px;
    margin-top: 8px;
}

.pp-btn-search {
    background-color: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(127 126 126);
    border-radius: 999px;
    border: 1px solid rgb(192 192 192);
    padding: 6px;
    cursor: pointer;
    width: 26px;
    height: 26px;
    margin-top: 30px;
    ;

    &:hover {
        background-color: #cacaca;
        color: white;
    }
}
</style>