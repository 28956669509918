<template>
    <div id="legende">
        <h5 class=""><i class="fa fa-bookmark text-primary" aria-hidden="true"></i> {{parameters.indicTitle}}</h5>
        <ul class="list-group">
            <li class="list-group-item list-group-item-action" v-for="classColor,index in parameters.colors">
                <div v-if="index==0" @click="onClassItemClick(index)"><i class="fa fa-square fa-1x" :style="{color:classColor, opacity: 0.6}" aria-hidden="true"></i> <span class="text_class_legend_map">{{parameters.discretClass[index] | formatNumber}} {{parameters.suffix}} et plus</span></div>
                <div v-else-if="index==(parameters.discretClass.length)" @click="onClassItemClick(index)"><i class="fa fa-square fa-1x" :style="{color:classColor, opacity: 0.6}" aria-hidden="true"></i> <span class="text_class_legend_map"> Moins de {{parameters.discretClass[index-1] | formatNumber}} {{parameters.suffix}}</span></div>
                <div v-else="" @click="onClassItemClick(index)"><i class="fa fa-square fa-1x" :style="{color:classColor, opacity: 0.6}" aria-hidden="true"></i> <span class="text_class_legend_map">  de {{parameters.discretClass[index] | formatNumber}} à moins de {{parameters.discretClass[index-1] | formatNumber}} {{parameters.suffix}}</span></div>
            </li>
            <li class="list-group-item" v-if="parameters.nr_color">
                <div><i class="fa fa-square fa-1x" :style="{color: parameters.nr_color}" aria-hidden="true"></i><span class="text_class_legend_map"> Non renseigné</span></div>
            </li>
            <li class="list-group-item" v-if="parameters.sec_color">
                <div><i class="fa fa-square fa-1x" :style="{color: parameters.sec_color,  opacity: 0.5}" aria-hidden="true"></i><span class="text_class_legend_map"> Secret</span></div>
            </li>
            <li class="list-group-item" v-if="parameters.nd_color">
                <div><i class="fa fa-square fa-1x" :style="{color: parameters.nd_color}" aria-hidden="true"></i><span class="text_class_legend_map"> Non renseigné</span></div>
            </li>
        </ul>
        <hr>
        <!--<small>Source : <span v-html>{{parameters.source}}</span></small>-->
    </div>
</template>
<script>
    export default {
        props: ['parameters'],
        data(){
            return{

            }

        },
        methods:{
            onClassItemClick: function(id){

            },
            exportLegende: function(){

                html2canvas(document.getElementById('legende')).then(function(canvas2) {

                    let canvasData = document.body.appendChild(canvas2);

                    let context = canvas2.getContext('2d');
                    let the_time = new Date().getTime();

                    EventBus.$emit('LEGENDE_CANVAS_BUILD', canvas2)

                    //return canvas2;


                    /*
                    if (navigator.msSaveBlob) {
                        navigator.msSaveBlob(canvas2.msToBlob(), 'legende_'+this.parameters.indicTitle+'.png');
                    } else {
                        canvas2.toBlob(function(blob) {
                            FileSaver.saveAs(blob, 'legende_'+this.parameters.indicTitle+'.png');
                        });
                    }

                    */


                });

            }
        }
    }

</script>
<style>

    .text_class_legend_map{
        font-size: 0.8em;
    }

</style>