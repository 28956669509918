<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col">
                    <h4>
                    <button type="button" class="btn btn-light btn-block btn-md text-left" @click="displaySearch = !displaySearch ">
                        <i class="fa fa-map-marker" aria-hidden="true"></i> <span class="badge badge-primary">{{order}}</span> <span class="badge badge-info">{{selectedScaleLabel.label}}</span>
                        (<span class="text-primary">{{selectedTerritory.codegeo}}</span>) <span>{{selectedTerritory.label}}</span>
                    </button>
                    </h4>
                </div>
                <div class="col text-right">
                     <button class="btn btn-primary btn-sm" @click="displaySearch = !displaySearch" v-b-tooltip.hover title="Modifier votre sélection"><i class="fa fa-search" aria-hidden="true"></i></button>
                </div>
            </div>
        </div>
        <div class="card-body" v-if="displaySearch">
            <div class="row">
                <div class="col-md-4">
                        <b-form-select v-model="selectedScale" class="mb-3" id="select_scale">
                            <option v-for="scaleref in scales" :value="scaleref.codegeo">{{scaleref.label}}</option>
                        </b-form-select>
                </div>
                <div class="col-md-8">
                        <input id="queryInput"  name="query" v-model="query" class="form-control"  placeholder="Chercher par le code ou le nom">
                </div>
            </div>
            <div style="max-height: 150px; overflow: auto;">
            <table class="table table-sm table-bordered">
                <tr v-for="item in tableFilter">
                   <td><button class="btn btn-sm btn-outline-primary" @click="updateSelection(item.codegeo,item.label)"><i class="fa fa-map-marker" aria-hidden="true"></i> ({{item.codegeo}}) {{item.label}}</button></td>
                </tr>
            </table>
            </div>
        </div>
    </div>
</template>
<script>
    import {PGService} from '../services/PGService';
    export default {
        props: ['order', 'refScale','userRefTerr', 'section'],
        name: "select-one-terr",
        data    : function(){
            return {
                isSpinning: false,
                displaySearch: false,
                millesime: '17',
                query: '',
                refCogs: [],
                scales: [
                   //{codegeo: 'COM', label : 'Communes'},
                   //{codegeo: 'CANT', label : 'Cantons'},
                   //{codegeo: 'AU', label : 'Aires Urbaines'},
                   //{codegeo: 'UU', label : 'Unités Urbaines'},
                   //{codegeo: 'ZE', label : 'Zones d\'Emploi'},
                   //{codegeo: 'EPCI', label : 'EPCI'},
                   //{codegeo: 'DEP', label : 'Départements'},
                   //{codegeo: 'REG', label : 'Région'},
                   //{codegeo: 'FR', label : 'France métropolitaine'},
                ],
                selectedScale: null,
                selectedScaleLabel: '',
                selectedTerritory: {codegeo: '', label: '', scale:''},
                allCog: [],
            }
        },
        mounted: function(){

            this.pgService = new PGService();
            let that = this;
            //console.log("ordre du selecteur : ",this.order);


            switch(this.section){
            case('pppi'):
                this.scales = [
                  {codegeo: 'COM', label : 'Communes'},
                  {codegeo: 'EPCI', label : 'EPCI'},
                  {codegeo: 'DEP', label : 'Départements'},
                  //{codegeo: 'AU', label : 'Aires Urbaines'},
                  {codegeo: 'UU', label : 'Unités Urbaines'},
                  {codegeo: 'ZE', label : 'Zones d\'emploi'},
                  //{codegeo: 'CANT', label : 'Cantons'},
                ];

                break;
            case('pprive'):
                this.scales = [
                    {codegeo: 'COM', label : 'Communes'},
                    {codegeo: 'UU', label : 'Unités Urbaines'},
                    {codegeo: 'ZE', label : 'Zone d\'emploi'},
                    {codegeo: 'EPCI', label : 'EPCI'},
                    {codegeo: 'DEP', label : 'Départements'},
                    {codegeo: 'REG', label : 'Région'},
                    {codegeo: 'FR', label : 'France métropolitaine'},
                ];
                break;
            case('ppdom_filoc'):
              this.scales = [
                {codegeo: 'COM', label : 'Communes'},
                {codegeo: 'EPCI', label : 'EPCI'},
                {codegeo: 'DEP', label : 'Départements'},
                {codegeo: 'DOM', label : 'Ensemble des DOMs'},
              ];
              break;
            case('ppdom'):
                this.scales = [
                    {codegeo: 'COM', label : 'Communes'},
                    {codegeo: 'EPCIDOM', label : 'EPCI'},
                    {codegeo: 'DEP', label : 'Départements'},
                    {codegeo: '4DOM', label : 'Ensemble des DOMs (hors Mayotte)'},
                ];
                break;
            };

            this.prepareComp();
            if(this.section=='pppi' && this.order == 0){
                this.scales.push({codegeo: 'DEP', label : 'Départements'});
            }

            EventBus.$on('OPEN_SEARCH_TERR_MODUL', (evt) =>{
                (evt!=this.order)?this.displaySearch = false: null;
            });

            EventBus.$on('SELECT_DEP_TERRITORY', (evt) =>{
                if(evt.order!=this.order&&this.section=='pppi'){
                    //console.log('section: ',this.section, 'selection de departement'); ??????
                    /*
                  for(let i=0;i<this.scales.length;i++){
                        if(this.scales[i].codegeo == 'DEP'){
                            this.scales.splice(i,1);
                        }
                    }

                     */
                }
            });

            EventBus.$on('END_GET_REF_COG', (evt) =>{
                //console.log(evt);
                this.initFirstSelection(evt);
            });

        },
        watch   :{
            displaySearch: function(val){
                (val==true)?EventBus.$emit('OPEN_SEARCH_TERR_MODUL', this.order):null;
            },
            selectedScale: function(val){
                // console.log(val);
                if(val=='FR'){
                    this.query='France'
                }else{
                    this.query='';
                }
            },
        },
        computed: {
            tableFilter: function () {
                return this.findBy(this.refCogs, this.query, 'label', 'codegeo', this.selectedScale);
            }
        },
        methods:{
            prepareComp: function(){
                //console.log(this.scale)
                this.selectedScale = this.refScale;
                this.isSpinning = false;
                this.updateScaleLabel();
            },
            findBy: function (list, value, column1, column2, scale) {
                value = value.toLowerCase();
                let that = this;
                return list.filter(function (item) {
                    if(value != '' && item[column1]&& item[column2]){
                        let searchLabel = that.removeAccent(item[column1].toLowerCase());
                        return (searchLabel.includes(value)||item[column2].toLowerCase().includes(value)) && item['scale'].includes(scale);
                    }
                })
            },
            removeAccent: function(s){
                let r = s.toLowerCase();
                let non_asciis = {'a': '[àáâãäå]', 'ae': 'æ', 'c': 'ç', 'e': '[èéêë]', 'i': '[ìíîï]', 'n': 'ñ', 'o': '[òóôõö]', 'oe': 'œ', 'u': '[ùúûűü]', 'y': '[ýÿ]'};
                for (let i in non_asciis) { r = r.replace(new RegExp(non_asciis[i], 'g'), i); }
                return r;
            },
            getUserRefTerr: function(){

                if(this.userRefTerr && this.userRefTerr != null && this.userRefTerr.refs['terrSelected'+this.order]){

                    let userRefs = this.userRefTerr.refs['terrSelected'+this.order];
                    //console.log('ter '+this.order,  userRefs.codegeo, userRefs.label);
                    this.selectedTerritory.label = userRefs.label;
                    this.selectedTerritory.scale = userRefs.scale;
                    this.selectedTerritory.code = userRefs.codegeo;
                    this.selectedScale  =  userRefs.scale;
                    this.selectedScaleLabel.label = userRefs.scaleLabel;
                    this.updateSelection(userRefs.codegeo,userRefs.label);
                }
            },
            updateSelection: function(code, label){

                //console.log('update : ', code, label);
                this.selectedTerritory.codegeo = code;
                this.selectedTerritory.label = label;
                this.query = '';
                this.displaySearch = false;
                this.updateScaleLabel();
                this.diffuseSelectedTerritory();


            },

            diffuseSelectedTerritory: function(){

                    let selectCodeGeo = (this.selectedTerritory)? this.selectedTerritory.codegeo : null;

                EventBus.$emit('UPDATE_SELECTED_TER', {
                    'level': this.order,
                    'scale': this.selectedScale,
                    'scaleLabel': this.selectedScaleLabel.label,
                    'codegeo': selectCodeGeo,
                    'label': (this.selectedTerritory && this.selectedTerritory.label)?this.selectedTerritory.label:''
                })

                if(this.selectedScale=='DEP'){

                    EventBus.$emit('SELECT_DEP_TERRITORY', {order: this.order, section : this.section});
                }

            },

            updateScaleLabel: function(){

                let that = this;
                let indexSearch =  _.findIndex(this.scales, function(o) {
                    return o.codegeo == that.selectedScale;
                });
                this.selectedScaleLabel = this.scales[indexSearch];
            },

            initFirstSelection: function(evt)
            {
                let that = this;
                let indexSearch =  _.findIndex(evt, function(o) {
                    //console.log(o.scale);
                    return o.scale == that.selectedScale;
                });
                this.refCogs = evt;
                this.selectedTerritory = this.refCogs[indexSearch];
                //this.getUserRefTerr();
                this.diffuseSelectedTerritory();
            }
        }
    }
</script>
<style scoped>

</style>