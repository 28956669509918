<template>
    <div :hidden="!visible" class="rotating-plane-spinner"></div>
</template>
<style>
    .rotating-plane-spinner {
      margin: 25px auto;
      width: 40px;
      height: 40px;
      left: 50%;
      top: 50%;
      background-color: #79013a;
      position: absolute;
      z-index: 70000;

      -webkit-animation: fbm-rotate-spinner 1.2s infinite ease-in-out;
      animation: fbm-rotate-spinner 1.2s infinite ease-in-out;
    }

    @-webkit-keyframes fbm-rotate-spinner {
      0% {
        -webkit-transform: perspective(120px)
      }
      50% {
        -webkit-transform: perspective(120px) rotateY(180deg)
      }
      100% {
        -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg)
      }
    }

    @keyframes fbm-rotate-spinner {
      0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
      }
      50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
      }
      100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      }
    }

</style>
<script>
    export default{
        data(){
            return{
                visible: true,
                timeout: undefined,
                delay: 0,
            }
        },
        props: {
            test: '',
        },
        mounted(){

            EventBus.$on('HIDE_SPINNER', function(evt){
                this.visible=false;
            })

        },
        watch: {
            test: function(newVal){
                if(!newVal){
                   this.cancel();
                   this.visible = false;
                   return;
                }else{
                    this.visible = true;
                }
            }
        },
        methods : {
            cancel () {
                    clearTimeout(this.timeout);
                    this.timeout = undefined;
                },

        }
    }
</script>
