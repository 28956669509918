"use strict";

import axios from "axios";
import { entries, forEach, result } from "lodash";

export default class CoproffService {
  constructor() {
    //this.refs = null;
  }
  getRefs() {
    let prom = new Promise((resolveMe, rejectMe) => {
      axios
        .get("/api/coproff-clean-data-ref")
        .then((response) => {
          if (response.data && response.data.length) {
            let refs = response.data.map((item) => ({
              ...item,
              val: JSON.parse(item.val),
            }));

            resolveMe(refs);
          } else {
            alert("Error: No ref data found");
          }
          //return response.data;
        })
        .catch((error) => {
          console.log(error);
          alert("Error: getRefs failed");
          rejectMe(error);
        });
    });

    return prom;
  }
  mergeData(dataValue, refsDataOrigin, order) {
    let refsData = JSON.parse(JSON.stringify(refsDataOrigin));
    for (let i = 0; i < refsData.length; i++) {
      const val = refsData[i].val.refData;
      for (let j = 0; j < val.length; j++) {
        forEach(entries(val[j]), ([key, value]) => {
          if (typeof value === "string" && value.includes("data.")) {
            refsData[i].val.refData[j][key] = dataValue[value.substring(5)];
          } else {
          }
        });
      }
      if (i === refsData.length - 1) {
        return refsData.sort((a, b) => a.order - b.order);
      }
    }
  }
  buildStats(data) {
    return [
      {
        id: 0,
        is_double: false,
        typeref: "Nb",
        color: "primary",
        title: "Nombre de copropriétés",
        val: { refData: [{ nb: data.tot_copro, taux: null, unity: null }] },
        src: "RNIC/FF",
        ref: "Le Nombre de propriétés est issu de la base de données RNIC/FF et comptabilise l'ensemble des copropriétés immatriculées et non immatriculées. Il est à noter que les copropriétés non immatriculées sont des copropriétés qui n'ont pas encore fait l'objet d'une immatriculation au registre national des copropriétés. Les données sont issues de la base de données RNIC/FF. <br><p class=\"pp-coproff-calc\"> COUNT[idcopro]</p>",
      },
      {
        id: 1,
        is_double: false,
        typeref: "Nb_taux",
        color: "secondary",
        title: "Nombre et taux de copropriétés immatriculées",
        val: {
          refData: [
            {
              nb: data.tot_copro_immat,
              taux: data.pct_copro_immat,
              unity: null,
            },
          ],
        },
        src: "RNIC/FF",
        ref: "Le Nombre et taux de copropriétés immatriculées, comptabilise l'ensemble des copropriétés immatriculées dans le Registre  RNIC parmis l'ensemble des coprpriétés. Les données sont issues de la base de données RNIC/FF, <br><p class=\"pp-coproff-calc\"> (COUNT[num_immat] <p> null / COUNT[idcopro] ) * 100 </p>",
      },
      {
        id: 2,
        is_double: false,
        typeref: "Nb",
        color: "tertiary",
        title: "Nombre de logements en copropriétés",
        val: { refData: [{ nb: data.tot_logmt, taux: null, unity: null }] },
        src: "RNIC/FF",
        ref: "Le Nombre de  logement est issu de la base de données RNIC/FF et comptabilise l'ensemble des logements parmis les  copropriétés immatriculées et non immatriculées. Il est à noter que les copropriétés non immatriculées sont des copropriétés qui n'ont pas encore fait l'objet d'une immatriculation au registre national des copropriétés. Les données sont issues de la base de données RNIC/FF, <br><p class=\"pp-coproff-calc\"> SUM[tot_logmnt] immatriculé + SUM[tot_logmnt] non immatriculé</p>",
      },
      {
        id: 3,
        is_double: false,
        typeref: "Nb_taux",
        color: "primary",
        title: "Nombre et taux de logements immatriculés",
        val: {
          refData: [
            {
              nb: data.tot_logmt_immat,
              taux: data.pct_logmt_immat,
              unity: null,
            },
          ],
        },
        src: "RNIC/FF",
        ref: "Le Nombre et taux de logements immatriculés, comptabilise l'ensemble des logements parmis les copropriétés immatriculées dans le Registre RNIC au seins de l'ensemble des coprpriétés.<br><p class=\"pp-coproff-calc\"> (SUM[tot_logmnt] immatriculé / SUM[tot_logmnt] immatriculé + SUM[tot_logmnt] non immatriculé) * 100</p>",
      },
      {
        id: 4,
        is_double: true,
        typeref: "Nb_taux",
        color: "secondary",
        title: "Nombre/Taux de copropriétés de ",
        val: {
          refData: [
            {
              id: 10,
              label: "moins de 10 lots",
              nb: data.tot_lots_inf_10,
              taux: data.pct_lots_inf_10,
              unity: null,
            },
            {
              id: 20,
              label: "de 10 à 50 lots",
              nb: data.tot_lots_11_49,
              taux: data.pct_lots_11_49,
              unity: null,
            },
            {
              id: 30,
              label: "de 51 à 199 lots",
              nb: data.tot_lots_50_199,
              taux: data.pct_lots_50_199,
              unity: null,
            },
            {
              id: 40,
              label: "200 et plus",
              nb: data.tot_lots_sup_200,
              taux: data.pct_lots_sup_200,
              unity: null,
            },
          ],
        },
        src: "FF",
        ref: 'Le Nombre et le taux de copropriétés par taille raporté à l\'ensemble des coprpriétés immatriculées et non immatriculées. Ces données sont issues des données FF utilisées dans la base Coproff. <br><p class="pp-coproff-calc"> (COUNT[tot_lots] <= ecart typeref / COUNT idcopro) * 100</p>',
      },
      {
        id: 6,
        is_double: false,
        typeref: "Nb",
        color: "primary",
        title:
          "Évolution du taux de copropriétés immatriculées depuis le dernier millésime",
        val: { refData: [{ nb: null, taux: data.evol_immat, unity: null }] },
        src: "RNIC",
        ref: "L'évolution du taux de propriétés immatriculées dans les Registre RNIC représente l'évolution des immatriculation entre le millésime officiel courant et le précédant. Remarque :  si absence de millésime de référence précédant, la valeur affichée est codifiée 'NA'. <br><p class=\"pp-coproff-calc\"> (COUNT[num_immat] millesime / COUNT[num_immat] millesime-1) * 100</p>",
      },
      {
        id: 7,
        is_double: true,
        typeref: "Nb_taux",
        color: "tertiary",
        title: "Nombre/Taux de logements par date de construction ",
        val: {
          refData: [
            {
              id: 10,
              label: "avant 1915",
              nb: data.tot_construct_av15,
              taux: data.pct_construct_av15,
              unity: null,
            },
            {
              id: 20,
              label: "de 1915 à 1948",
              nb: data.tot_construct_15_48,
              taux: data.pct_construct_15_48,
              unity: null,
            },
            {
              id: 30,
              label: "de 1948 à 1971",
              nb: data.tot_construct_48_71,
              taux: data.pct_construct_48_71,
              unity: null,
            },
            {
              id: 40,
              label: "de 1971 à 2000",
              nb: data.tot_construct_70_2000,
              taux: data.pct_construct_70_2000,
              unity: null,
            },
            {
              id: 50,
              label: "après 2000",
              nb: data.tot_construct_sup_2000,
              taux: data.pct_construct_sup_2000,
              unity: null,
            },
          ],
        },
        src: "FF",
        ref: 'Nombre et Taux de copropriétés par date de construction selon les données FF rapportés à l\'ensemble des copropriété immatriculées et non immatriculées dont la date de construction est renseignée. Ces données sont issues des données FF utilisées dans la base Coproff. <br><p class="pp-coproff-calc"> (([an_const] <= ecart type) / (COUNT[idcopro] && [an_const] <> null)) * 100</p>',
      },
      {
        id: 5,
        is_double: false,
        typeref: "Nb_taux",
        color: "secondary",
        title: "Nombre moyen de logements en copropriétés",
        val: {
          refData: [{ nb: data.nb_logmt_copro_moyen, taux: null, unity: null }],
        },
        src: "FF",
        ref: 'Nombre moyen de logement par copropriété, parmis l\'ensemble des logements rapportés au nombre de copropriétés immatriculées et non immatriculées. Ces données sont issues des données FF utilisées dans la base Coproff. <br><p class="pp-coproff-calc"> SUM[tot_logmnt] / COUNT[idcopro]</p>',
      },
      {
        id: 9,
        is_double: true,
        typeref: "Nb_taux",
        color: "primary",
        title: "Taux de logement par type de chauffage",
        val: {
          refData: [
            {
              id: 0,
              label: "Chauffage collectif",
              nb: data.tot_prop_chauff_collect,
              taux: data.pct_prop_chauff_collect,
              unity: null,
            },
            {
              id: 10,
              label: "Chauffage individuel",
              nb: data.tot_prop_chauff_indiv,
              taux: data.pct_prop_chauff_indiv,
              unity: null,
            },
            {
              id: 20,
              label: "Chauffage mixte",
              nb: data.tot_prop_chauff_mixte,
              taux: data.pct_prop_chauff_mixte,
              unity: null,
            },
          ],
        },
        src: "FF",
        ref: "Nombre et taux de logement répartis par type de chauffage et rapportés à l'ensemble des logements en copropriétés immatriculées ou non et dont la données chauffage est renseignée pour les trois valeurs ('collectif', 'individuel', 'mixte'). Ces données sont issues des données FF utilisées dans la base Coproff. <br><p class=\"pp-coproff-calc\"> (COUNT[typechauff] = chauffage / (COUNT[idcopro] && typechauff = ['collectif','individuel','mixte']) * 100 </p>",
      },
      {
        id: 10,
        is_double: false,
        typeref: "Nb_taux",
        color: "secondary",
        title: "Nombre et taux de logements vacants",
        val: {
          refData: [
            { nb: data.tot_log_vacant, taux: data.pct_log_vacant, unity: null },
          ],
        },
        src: "FF",
        ref: 'Nombre et taux de logement considérés comme vacant (y compris vacance de moins de 5 ans) rapoortés à l\'ensemble des logements en copropriétés immatriculées ou non. Ces données sont issues des données FF utilisées dans la base Coproff. <br><p class="pp-coproff-calc"> (SUM[nlovac]  / COUNT[idcopro] ) * 100 </p>',
      },
      {
        id: 11,
        is_double: false,
        typeref: "Nb_taux",
        color: "tertiary",
        title: "Nombre et taux de passoires énérgétiques \n\r(DPE f/g)",
        val: {
          refData: [
            { nb: data.tot_log_dpefgn, taux: data.pct_log_dpefgn, unity: null },
          ],
        },
        src: "FF",
        ref: "Nombre et taux de copropriétés dont le diagnistique DPE est attribué aux note 'f' ou 'g' rappporté à l'ensemble des copropriétés immatriculées et dont le diagnistique DPE est accessible. Ces données sont issues des données FF utilisées dans la base Coproff. <br><p class=\"pp-coproff-calc\"> (SUM[dpelogfgn] = ['f','g'] / (COUNT[idcopro] && [dpe] <> null) * 100 </p>",
      },
      {
        id: 12,
        is_double: false,
        typeref: "Nb_taux",
        color: "primary",
        title:
          "Nombre et taux de logements appartenant à une personne morale ou assimilé",
        val: {
          refData: [
            { nb: data.tot_pers_moral, taux: data.pct_pers_moral, unity: null },
          ],
        },
        src: "RNIC",
        ref: 'Nombe et taux de logement appartenant à une personne morale ou assimilé rapportés au nombre de copropriétés immatriculées. Données issues des renseignement RNIC sur l\'ensemble des copropriétés immatriculées. <br><p class="pp-coproff-calc"> (COUNT[n_pers_mor] <> null / COUNT[idcopro] ) * 100 </p>',
      },
      {
        id: 13,
        is_double: false,
        typeref: "Nb_taux",
        color: "secondary",
        title: "Nombre et taux de syndicats bénévoles",
        val: {
          refData: [
            {
              id: 10,
              nb: data.tot_syndic_benevol,
              taux: data.pct_syndic_benevol,
              unity: null,
            },
          ],
        },
        src: "RNIC",
        ref: "Nombe et taux de copropriété gérées par un syndic bénévole rapportés au nombre de copropriétés immatriculées. Données issues des renseignement RNIC sur l'ensemble des copropriétés immatriculées. <br><p class=\"pp-coproff-calc\"> (COUNT[typ_syndic] = 'bénévole' / COUNT[idcopro] ) * 100</p>",
      },
      {
        id: 14,
        is_double: false,
        typeref: "Nb",
        color: "tertiary",
        title: "Taux d'impayés moyens",
        val: {
          refData: [
            { id: 10, nb: null, taux: data.moy_dette_copro, unity: null },
          ],
        },
        src: "RNIC",
        ref: "Montant totals des impayés rapportés à la somme totale des montants d'exercices des copropriétés immatriculées et dont les montants d'exercices sont renseignés. Données issues des renseignement RNIC sur l'ensemble des copropriétés immatriculées. <br><p class=\"pp-coproff-calc\"> (SUM[charge_ope]-SUM[dette_copro]) / COUNT[idcopro] </p>",
      },
      {
        id: 15,
        is_double: false,
        typeref: "Nb",
        color: "tertiary",
        title:
          "Moyenne de situation des prix des copropriétés par apport au marché local",
        val: {
          refData: [
            { id: 10, nb: data.ind_moy_marche, taux: null, unity: null },
          ],
        },
        src: "RNIC",
        ref: "Moyenne de la situation des prix par apport à l'indice du marché local de base 100 (exemple : 3.5 = moyenne de 103,5% par apport à l'indice du marché local de base 100 ). Données issues des renseignement RNIC rapportés au copropriétés dont l'indice de situation des prix est renseigné. <br><p class=\"pp-coproff-calc\">(SUM[ind_marche] / COUNT[idcopro]= ind_marche <> null)</p>",
      },
    ];
  }
}
