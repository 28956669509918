export class Ol3HeatMapService{


    constructor(zoom) {
        this._zoomLevel = zoom;
        this._map=null;
        this.tooltip = null;
        this.overlay = null;
        this._gradient = ['#00f', '#0ff', '#0f0', '#ff0', '#f00'];
    }

    buildHeatMap(geoJsonData, layerName){

        let blur = 15;
        let radius = 35;

        let vectorSource = null;

        if(geoJsonData){
            vectorSource = new ol.source.Vector({
                features: new ol.format.GeoJSON().readFeatures(geoJsonData)
            });

        }else{
            vectorSource = new ol.source.Vector;
        }

        let vectorHeatMap = new ol.layer.Heatmap({
            gradient: this.gradient,
            source: vectorSource,
        });

        (layerName!='')?vectorHeatMap.set('name', layerName):vectorHeatMap.set('name', 'draw_layer');

        return vectorHeatMap;
    }


    /////////////////////////////////////
    ///////// GETTERS AND SETTERS ///////
    /////////////////////////////////////

    get map(){
        return this._map;
    }
    set map(map){
        this._map = map;
    }
    set gradient(grad){
        this._gradient = grad;
    }
    get gradient(){
        return this._gradient;
    }
    get zoomLevel(){
        return this._zoomLevel;
    }
    set zoomLevel(zoomAmount){
        this._zoomLevel = zoomAmount;
    }
}