<template>
    <dialog :open="displayDialog" id="ppDialog" ref="myDiag">
        <div class="dialog-header">
            <h4>{{ title }}</h4>
            <form method="dialog">
                <div @click="closeDialog" class="btn-modale  text-white"><svg xmlns="http://www.w3.org/2000/svg"
                        width="20" height="20" viewBox="0 0 24 24">
                        <path fill="currentColor"
                            d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m-3.4 14L12 13.4L8.4 17L7 15.6l3.6-3.6L7 8.4L8.4 7l3.6 3.6L15.6 7L17 8.4L13.4 12l3.6 3.6l-1.4 1.4Z" />
                    </svg></div>
            </form>
        </div>
        <div v-if="icon" class="w-100 text-center p-2 text-secondary">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24">
                <path fill="currentColor"
                    d="M11 9h2V7h-2zm3 8v-2h-1v-4h-3v2h1v2h-1v2zM5 3h14a2 2 0 0 1 2 2v14c0 .53-.21 1.04-.59 1.41c-.37.38-.88.59-1.41.59H5c-.53 0-1.04-.21-1.41-.59C3.21 20.04 3 19.53 3 19V5c0-1.11.89-2 2-2m14 16V5H5v14z" />
            </svg>
        </div>
        <div class="dialog-body">
            <slot name="content"></slot>
            <form method="dialog">
                <div v-if="asClose" class="btn btn-primary mt-2" @click="closeDialog">
                    {{ closeLabel }}
                </div>
            </form>
        </div>
    </dialog>
</template>

<script>
export default {
    name: "dialog_modal",
    props: {
        title: {
            type: String,
            default: "Information"
        },
        displayDialog: {
            type: Boolean,
            required: true,
            default: false
        },
        asClose: {
            type: Boolean,
            required: false,
            default: true
        },
        closeLabel: {
            type: String,
            required: false,
            default: "Fermer"
        },
        actionLabel: {
            type: String,
            required: false,
            default: "Valider"
        },
        icon: {
            type: String,
            required: false,
            default: "info"
        }
    },
    data: () => {
        return {
            diag: null
        }
    },
    watch: {
        displayDialog(newVal, oldVal) {
            if (newVal != oldVal && newVal == true) {
                this.$refs.myDiag.showModal();
            }
        },
    },
    methods: {
        closeDialog: function () {
            console.log("closeDialog")
            this.$refs.myDiag.close()
            this.$emit("close");
        },
        actionDialog: function () {
            this.$emit("action");
        }
    }
};

</script>

<style scoped>
#ppDialog {
    padding: 0;
    border: 0;
    border-radius: 0.3rem;
    box-shadow: 0 0 0.9em black;
    max-width: 600px;
    max-height: 80%;
    z-index: 1000;

    :focus-visible {
        outline: none;
    }

}

.dialog-header {
    display: flex;
    justify-content: space-between;
    background-color: rgb(214, 214, 214);
    color: #424242;
    padding: 0.5rem;
}

.dialog-body {
    display: flex;
    font-size: 0.8rem;
    flex-direction: column;
    padding: 0.8rem;
    overflow-y: auto;
    text-align: center;
}

dialog::backdrop {
    background-color: rgba(0, 0, 0, 0.6);
}

.btn-modale {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;

    :hover {
        background-color: #fff;
        color: #6F647D;
    }
}

/* @keyframes animate-top {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

.animate {
    animation-name: animate-top;
    animation-duration: 0.4s;
} */
</style>