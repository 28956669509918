<template>
    <div class="row mb-4">
        <div class="col d-flex justify-content-between ">

            <a  href="/pppi_actu" class="btn  btn-lg " :class="[(tag =='data') ? 'btn-primary' : 'btn-outline-primary']"><i class="fa fa-bar-chart text-primary2" aria-hidden="true"></i> Fiche de synthèse</a>
            <a  href="/pppi_carto/1" class="btn  btn-lg " :class="[(tag =='carto') ? 'btn-primary' : 'btn-outline-primary']"><i class="fa fa-map-marker" aria-hidden="true"></i> Cartographie</a>
            <a  href="/pppi_export/1" class="btn btn-lg" :class="[(tag =='export') ? 'btn-primary' : 'btn-outline-primary']"><i class="fa fa-cloud-download text-primary2" aria-hidden="true"></i> Exportation des données brutes</a>
            <a  href="/pppi_doc/1" class="btn btn-lg" :class="[(tag =='doc') ? 'btn-primary' : 'btn-outline-primary']"><i class="fa fa-stack-overflow text-primary2" aria-hidden="true"></i> Zone documentaire</a>

        </div>
    </div>
</template>

<script>
    export default {
        name: "pp_navbar",
        props: ['tag', 'selectedReg', 'selectedRegLabel'],
        data: function(){
            return {

            }
        },
        mounted(){

        },
        watch: {

        },
        methods:{

        }
    };
</script>

<style scoped>

</style>