export class Ol3PointMapService {

    constructor(zoom) {
        this._zoomLevel = zoom;
        this._map=null;
        this.tooltip = null;
        this.overlay = null;
    }

    buildPointsLayer(geoJsonData, indic="nb_prop_occup",sizeCoef=1, props={colors:[ [255,0,10,0.6], [0,100,200,0.6]]},  layerName=''){

        let iconStyle = new ol.style.Style({
            text: new ol.style.Text({
                text: '\uf041',
                scale: .5,
                font: 'normal 30px FontAwesome',
                textBaseline: 'Bottom',
                fill: new ol.style.Fill({
                    color: '#FF0000',
                })
            })
        });

        let centroidSource = new ol.source.Vector({
            features: (new ol.format.GeoJSON()).readFeatures(geoJsonData)
        });
        //console.log(centroidSource.getFeatures());

        let clusterSource = new ol.source.Cluster({
            distance: parseInt(40),
            source: centroidSource,
        });
        let styleCache = {};
        let that = this;
        let markerLayer = new ol.layer.Vector({
            source: clusterSource ,
            style: function(feature, resolution) {
                /////// PARAMETRES /////////
                let size = feature.get('features').length;
                let sum_indic = 0;
                let features = feature.get('features');
                for(var i = 0; i < features.length; i++){
                    // here you'll have access to your normal attributes:
                    sum_indic += Number(features[i].get(indic));
                }
                let zoom = that._map.getView().getZoom();
                let fillColor = null;
                (sum_indic>150)?fillColor = props.colors[0]: fillColor = props.colors[1];
                ////////////// FIN PARAMETRES /////////

                var style = styleCache[size];
                if (!style) {
                    style = [new ol.style.Style({
                        image: new ol.style.Circle({
                            ////////////////////////////////// TO DO ////////////
                            /////////////// Algorythme de taille !!!!!!! ///////
                            ////////////////////////////////////////////////////
                            radius: (sum_indic/(zoom))*sizeCoef,
                            stroke: new ol.style.Stroke({
                                color: '#fff'
                            }),
                            fill: new ol.style.Fill({
                                color: fillColor
                            })
                        }),
                        text: new ol.style.Text({
                            text: sum_indic.toString(),//size.toString(),
                            fill: new ol.style.Fill({
                                color: '#00FF00'
                            })
                        })
                    })];
                    styleCache[size] = style;
                }
                return style;
            },
            renderOrder: null,
        });

        (layerName!='')?markerLayer.set('name', layerName):markerLayer.set('name', 'draw_layer');

        return markerLayer
    }

    /////////////////////////////////////
    ///////// GETTERS AND SETTERS ///////
    /////////////////////////////////////

    get map(){
        return this._map;
    }
    set map(map){
        this._map = map;
    }
    get zoomLevel(){
        return this._zoomLevel;
    }
    set zoomLevel(zoomAmount){
        this._zoomLevel = zoomAmount;
    }

}