<template>
    <div style="position:relative;">
        <label for="pp-search-terr">
            <slot></slot>
        </label>
        <input type="text" id="pp-search-terr" class="form-control" :placeholder="`${levelTitle}...`" v-model="search"
            @keyup="debounceMethod">
        <div v-if="zonages && zonages.length > 0" class="list-group"
            style="position:absolute; z-index:1000; width:100%;">
            <div class="list-group-item list-group-item-action" style="font-size: small;cursor:pointer;"
                v-for="terr in zonages" :key="terr.code" @click="selectTerr(terr)">{{ terr.nom }} ({{ terr.code }})
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import Loading from '../Loading.vue';
import { debounce } from 'lodash';
export default {
    name: "searchterr",
    props: ['level', 'parentterr'],
    components: {
        Loading
    },
    data: function () {
        return {
            search: null,
            levelTitle: "Commune",
            zonages: [],

        }
    },
    created() {
        this.debounceMethod = debounce(this.debounceMethod, 100);
    },
    mounted() {
        switch (this.level) {
            case "com":
                this.levelTitle = "Commune";
                break;
            case "dep":
                this.levelTitle = "Département";
                break;
            default:
                this.levelTitle = "Commune";
                break;
        }
        console.log("level", this.level, "serach", this.search, "levelTitle", this.levelTitle)
    },
    methods: {
        debounceMethod(e) {
            this.searchTerr(e.target.value);
        },
        searchTerr(code) {
            console.log('le code', code, 'le parent', this.parentterr);
            axios.get(`https://geo.api.gouv.fr/communes?nom=${code}&fields=nom,code,departement&limit=300&boost=population`)
                .then((result) => {
                    this.zonages = result.data.filter((terr) => terr.departement.code === String(this.parentterr));
                    console.log('response', this.zonages);
                })
                .catch(error => {
                    console.log('error', error);
                });
        },
        selectTerr(terr) {
            this.search = terr.nom;
            this.$emit('selected', terr, "com");
            this.zonages = [];
        }
    }
}
</script>
<style></style>