/**
 * Created by fbmfbm on 08/03/2017.
 */


export class Ol3ProcessService {
    constructor(zoom) {
        this._zoomLevel = zoom;
        this._map=null;
        this.tooltip = null;
        this.overlay = null;
        //this.ign_key = 'c6fo9gjgpjnmt81cyiab5e59';
        this.ign_key = 'vr8xlhtarn8r0z526u1w0emn';
    }

    buildResolution(){
        let resolutions = [
            156543.03392804103,
            78271.5169640205,
            39135.75848201024,
            19567.879241005125,
            9783.939620502562,
            4891.969810251281,
            2445.9849051256406,
            1222.9924525628203,
            611.4962262814101,
            305.74811314070485,
            152.87405657035254,
            76.43702828517625,
            38.218514142588134,
            19.109257071294063,
            9.554628535647034,
            4.777314267823517,
            2.3886571339117584,
            1.1943285669558792,
            0.5971642834779396,
            0.29858214173896974,
            0.14929107086948493,
            0.07464553543474241
        ] ;
        let projection = ol.proj.get('EPSG:3857');
        let projectionExtent = projection.getExtent();
        let size = ol.extent.getWidth(projectionExtent) / 256;
        let resolutions2 = new Array(20);
        let matrixIds = new Array(20);
        for (let z = 0; z < 20; ++z) {
            // generate resolutions and matrixIds arrays for this WMTS
            resolutions2[z] = size / Math.pow(2, z);
            matrixIds[z] = z;
            //console.log(resolutions2[z]);
        }

        return resolutions2;
    }

    /**
     * buildMap function
     * @param divRef
     * @param zoomLevel
     * @param layer
     * @returns {*|null|ol.Map}
     */
    buildMap(divRef, zoomLevel, layer='Road'){
        this._zoomLevel = zoomLevel;
        let resolutions = this.buildResolution();

        let attribution = new ol.control.Attribution({
            collapsible: false
        });

        this._map = new ol.Map({
            layers: [
                new ol.layer.Tile({
                    source:
                        new  ol.source.OSM(),
                        /*
                        new ol.source.BingMaps({
                        attributions: ["BingMaps"],
                        key: 'Ann-y97gpi1eYfOK806hTKFoZz8z8763yMvIg96gwTMvkGQbhaVN_Yx5qoRUCq9z',
                        imagerySet: 'Road',
                        crossOrigin: 'anonymous'
                    }),
                    */

                    visible: (layer=='Road')?true:false,
                }),
                new ol.layer.Tile({
                    source:
                     new ol.source.WMTS({
                        url:'https://wxs.ign.fr/' + this.ign_key + '/wmts',
                        crossOrigin: 'anonymous',
                        attributions: ["&copy; IGN-2022/Géoportail"],
                        layer:'ORTHOIMAGERY.ORTHOPHOTOS',
                        matrixSet: 'PM',
                        format: 'image/jpeg',
                        tileGrid: new ol.tilegrid.WMTS({
                            origin: [-20037508,20037508],
                            resolutions: resolutions,
                            matrixIds:["0","1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20"],
                        }),
                        style: 'normal'
                    }),
                    /*
                    source: new ol.source.BingMaps({
                        attributions: ["BingMaps"],
                        key: 'Ann-y97gpi1eYfOK806hTKFoZz8z8763yMvIg96gwTMvkGQbhaVN_Yx5qoRUCq9z',
                        imagerySet: 'AerialWithLabels',
                        crossOrigin: 'anonymous'
                    }),
                    */
                    visible: (layer=='Sat')?true:false,
                }),
                new ol.layer.Tile({
                    source: new ol.source.XYZ({
                        url:'http://{1-4}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
                        crossOrigin: 'anonymous',
                        attributions: [new ol.Attribution({html : "&copy; IGN-2022/Géoportail"})],
                    }),
                    visible: (layer=='Grey')?true:false,
                }),
                new ol.layer.Tile({
                    source: new ol.source.WMTS({
                        url:'https://wxs.ign.fr/' + this.ign_key + '/wmts',
                        crossOrigin: 'anonymous',
                        attributions: ["&copy; IGN-2022/Géoportail"],
                        layer:'CADASTRALPARCELS.PARCELS',
                        matrixSet: 'PM',
                        format: 'image/png',
                        tileGrid: new ol.tilegrid.WMTS({
                            origin: [-20037508,20037508],
                            resolutions: resolutions,
                            matrixIds:["0","1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20"],
                        }),
                        style: 'bdparcellaire_o'
                    }),
                    visible: (layer=='Parcelle')?true:false,
                }),
                new ol.layer.Tile({
                    source: new ol.source.WMTS({
                        url:'https://wxs.ign.fr/' + this.ign_key + '/wmts',
                        layer:'CADASTRALPARCELS.PARCELS',
                        crossOrigin: 'anonymous',
                        attributions: ["&copy; IGN-2022/Géoportail"],
                        matrixSet: 'PM',
                        format: 'image/png',
                        tileGrid: new ol.tilegrid.WMTS({
                            origin: [-20037508,20037508],
                            resolutions: resolutions,
                            matrixIds:["0","1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20"],
                        }),
                        style: 'normal'
                    }),
                    visible: (layer=='Elevation')?true:false,
                }),
                new ol.layer.Tile({
                    source: new ol.source.WMTS({
                        url: 'https://wxs.ign.fr/' + this.ign_key + '/wmts',
                        layer:'ADMINISTRATIVEUNITS.BOUNDARIES',
                        crossOrigin: 'anonymous',
                        attributions: [""],
                        matrixSet: 'PM',
                        format: 'image/png',
                        projection: 'EPSG:3857',
                        tileGrid: new ol.tilegrid.WMTS({
                            origin: [-20037508,20037508],
                            resolutions: resolutions,
                            matrixIds:["0","1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20"],
                        }),
                        style: 'normal'
                    }),
                    visible: (layer=='Limite')?true:false,
                })
            ],

            view: new ol.View({
                center: ol.proj.fromLonLat([261011.16255078022, 6250602.637492495], 'EPSG:4326'),
                zoom: zoomLevel
            }),
            target: divRef,
            controls: ol.control.defaults({attribution: false}).extend([
                attribution,
                new ol.control.FullScreen({
                    tipLabel: 'Affichage plein écran',
                    source: 'fullscreen',
                })
            ]),
        });
        return this._map;
    }
    /**
     * buildVectorLayer
     * @param layerName
     * @param geoJsonData
     * @returns {ol.layer.Vector}
     */
    buildVectorLayer( geoJsonData=null, layerName='', params=null) {

        if(geoJsonData){

            let allFeatures = (new ol.format.GeoJSON()).readFeatures(geoJsonData);

            if(params) {
                allFeatures.forEach(function (feature) {
                    params.forEach(function(pp){
                        feature.set(pp[0], pp[1]);
                    })
                })
            }

            this.vectorSource = new ol.source.Vector({
                features: allFeatures,
                crossOrigin: 'anonymous'
            });
        }else{
            this.vectorSource = new ol.source.Vector;
        }
        this.vectorLayer = new ol.layer.Vector({
            source: this.vectorSource,
            //style: this.buildMapStyle,
            renderOrder: null
        });
        (layerName!='')?this.vectorLayer.set('name', layerName):this.vectorLayer.set('name', 'draw_layer');
        return this.vectorLayer;
    }



    /**
     * addMapOverInteraction
     */
    addMapOverInteraction(map, layer) {
        // ---- INTERACTIONS TYPES ----
        let overInterraction = new ol.interaction.Select({
            condition: ol.events.condition.pointerMove,
            layer: layer
        });
        map.addInteraction(overInterraction);
    }
    /**
     * addMapClickInteraction
     */
    addMapClickInteraction(map, layer) {
        // ---- INTERACTIONS TYPES ----

        map.on('click', (evt) => {
            let pixel = evt.pixel;
            let featureSelected = evt.map.forEachFeatureAtPixel(pixel, (feature, layer) => feature);
            if(featureSelected) {
                console.log(featureSelected.getKeys());
            };
        });
    }


    getLayerByName(map,layerName){
        let that = this;
        let layer;
        map.getLayers().forEach(function (calque, i) {
            if (calque.get('name') === layerName){
                layer = calque;
            }
        });
        return layer;
    }
    cleanByLayerName(map,layerName){
        let that = this;
        map.getLayers().forEach(function (layer, i) {
            if (layer.get('name') === layerName){
                map.removeLayer(layer);
            }
        });
    }



    addTooltipInteraction(map, DomElement, arrayOfRef=[{label:'Code',value:'code'},{label:'label',value:'label'}]){
        this.tooltip = DomElement;
        this.tooltip_arrayOfRef = arrayOfRef;
        let that = this;

        this.overlay = new ol.Overlay({
            element : that.tooltip,
            offset: [10,0],
            positioning: 'bottom-left',
        });

        map.addOverlay(this.overlay);
        map.on('pointermove', this.eventTooltipOnMap,this);
    }

    eventTooltipOnMap(evt,layerToTrack='vector'){
        let pixel = evt.pixel;
        let map = evt.map;

        let feature = map.forEachFeatureAtPixel(pixel, (feature, layer) => {

            //(layer)?console.log(layer.get('name')):null;

            if(layer && layer.get('name') == layerToTrack){
                return feature ;
            }else{
                return null;
            }

        });

        this.tooltip.style.display = feature ? '' : 'none';


        if (feature ) {
            this.overlay.setPosition(evt.coordinate);
            let displayInfo = '';

            for(let i=0;i<this.tooltip_arrayOfRef.length;i++){
                if(i>0){
                    (feature.get(this.tooltip_arrayOfRef[i].value))?displayInfo += '<div class="line"><span class="label">'+this.tooltip_arrayOfRef[i].label+'</span><span class="value">'+feature.get(this.tooltip_arrayOfRef[i].value)+'</span></div>':null;
                }else{
                    (feature.get(this.tooltip_arrayOfRef[i].value))?displayInfo += this.tooltip_arrayOfRef[i].label+''+feature.get(this.tooltip_arrayOfRef[i].value):null;
                }
            }
            (feature.get('displayTooltip') || feature.get('code').length>5)?this.tooltip.innerHTML = displayInfo:this.tooltip.innerHTML = 'XXXX';

        }else{

        }
    }

    /////////////////////////////////////
    ///////// GETTERS AND SETTERS ///////
    /////////////////////////////////////

    get map(){
       return this._map;
    }
    set map(divRef){
        if(!this._map){
            this._map = this.buildMap(divRef);
            return this._map;
        }else{
            return this._map
        }
    }
    get zoomLevel(){
        return this._zoomLevel;
    }
    set zoomLevel(zoomAmount){
        this._zoomLevel = zoomAmount;
    }


}
