require("./bootstrap");
import Vue from "vue";
window.Vue = Vue; //require("vue");
import ol from "openlayers";
window.ol = ol; //require("openlayers");

window.FileSaver = require("file-saver");

window.EventBus = new Vue();
import Bootstrap from "bootstrap";
import { BootstrapVue } from "bootstrap-vue";
window.BootstrapVue = BootstrapVue;
//import "bootstrap/dist/css/bootstrap.css";
//import "bootstrap-vue/dist/bootstrap-vue.css";

//import  tableToJson  from "table-to-json"; //require("table-to-json");
import html2canvas from "html2canvas";
window.html2canvas = html2canvas; //require("html2canvas");

Vue.use(BootstrapVue);

import JsPDF from "jspdf";
window.JsPDF = JsPDF; //require("jspdf");
import jspdfAutoTable from "jspdf-autotable";
window.JsPdfAutoTable = jspdfAutoTable; //require("jspdf-autotable");

import numeral from "numeral";

numeral.register("locale", "fr", {
  delimiters: {
    thousands: " ",
    decimal: ",",
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t",
  },
  ordinal: function(number) {
    return number === 1 ? "er" : "ème";
  },
  currency: {
    symbol: "€",
  },
});

// switch between locales

numeral.locale("fr");

Vue.filter("formatNumber", function(value) {
  if (
    value &&
    value.substring(0, 1) != "[" &&
    value.substring(0, 1) != "]" &&
    value != "nr" &&
    value != "s"
  ) {
    if (value.indexOf(".") > 0) {
      //console.log(value);
      if (Math.round(value) < 10) {
        /// hack pour ne pas arrondire la taille de ménage en cas de valeur non calculée !!

        value = Math.round(value * 100) / 100;
        return value;
      } else {
        // console.log(value);
        value = numeral(Math.round(value)).format("0,0");
      }
      //return Math.round(value);
      return value;
    } else {
      //console.log(value);
      return numeral(value).format("0,0");
    }
  } else {
    return value;
  }
});

import Select4Terr from "./components/Select4Terr.vue";
Vue.component("select4Terr", Select4Terr);

import SelectOneTerr from "./components/SelectOneTerr.vue";
Vue.component("selectOneTerr", SelectOneTerr);

import PPrivDisplay1 from "./components/pp/PPriv_display1.vue";
Vue.component("ppDisplay1", PPrivDisplay1);

import PPNavbar from "./components/pp/PP_Navbar.vue";
Vue.component("pp_navbar", PPNavbar);

import PPExport from "./components/pp/PP_export.vue";
Vue.component("pp_export", PPExport);

import PPPINavbar from "./components/pppi/PPPI_Navbar.vue";
Vue.component("pppi_navbar", PPPINavbar);

import PPDOMNavbar from "./components/ppdom/PPDOM_Navbar.vue";
Vue.component("ppdom_navbar", PPDOMNavbar);

import GeoBreadCrumb from "./components/GeoBreadCrumb.vue";
Vue.component("geoBreadCrumb", GeoBreadCrumb);

import LegendChoroMap from "./components/LegendChoroMap.vue";
Vue.component("legendChoroMap", LegendChoroMap);

import SimpleOlMap from "./components/SimpleOlMap.vue";
Vue.component("simple-ol-map", SimpleOlMap);

import SynthMapList from "./components/SynthMapListe.vue";
Vue.component("synth-map-list", SynthMapList);

import CoproNavbar from "./components/copro/Copro_Navbar.vue";
Vue.component("copro_navbar", CoproNavbar);

import CoproffNavbar from "./components/coproff/Coproff_Navbar.vue";
Vue.component("coproff_navbar", CoproffNavbar);

import CoproffStats from "./components/coproff/CoproffStats.vue";
Vue.component("coproff_stats", CoproffStats);

import CoproffExport from "./components/coproff/CoproffExport.vue";
Vue.component("coproff_export", CoproffExport);

import ToolsBigBtn from "./components/ToolsBigBtn.vue";
Vue.component("tools-big-btn", ToolsBigBtn);

import PPPIClassement from "./components/PPPIClassement.vue";
Vue.component("pppi-classement", PPPIClassement);

import ActeEngagementForme from "./components/ActeEngagementForme.vue";
Vue.component("acte_engagement_forme", ActeEngagementForme);

import FbmRotateSpinner from "./components/fbmRotateSpinner.vue";
Vue.component("fbm-rotate-spinner", FbmRotateSpinner);

const app = new Vue({
  el: "#app",
});
