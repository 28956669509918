"use strict";
import { LogoDataService } from "./logoDataService";

export class ExportPdfService {
  constructor(structureData, appFileName) {
    this.headers = new Headers({ "Content-Type": "application/json" });
    this.logoDataService = new LogoDataService();
    this.structureData = structureData;
    this.appFileName = appFileName;
  }

  buildDoc(refTab, querry_table, refTerr, titleApp) {
    let title = titleApp;

    let doc = new window.JsPDF({
      orientation: "landscape",
    });

    let posY = 26;
    //let baseX = 104;
    let baseX = 10;

    let logoData = this.logoDataService.logoDataAnah();
    //let logoDihal = this.logoDataService.logoDataDihal();
    let logoMinister = this.logoDataService.logoDataMinister();

    doc.addImage(logoMinister, "JPEG", baseX + 2, 15, 45, 30);

    doc.addImage(logoData, "JPEG", baseX + 240, 18, 30, 26);
    //doc.setFillColor(96, 121, 150);
    //doc.rect(0, 5, 100, 200, 'F');

    doc.setFontSize(10);
    doc.setTextColor(96, 121, 150);
    doc.setFontSize(28);
    doc.text(title, baseX, posY + 50);
    doc.setFontSize(18);
    doc.setTextColor(187, 11, 73);
    doc.text("Territoires de référence", baseX, posY + 70);
    doc.setFontSize(14);
    doc.setTextColor(50, 50, 50);

    //let refTerr = [this.terr1, this.terr2, this.terr3, this.terr4];
    //console.log(refTerr);
    for (let i = 0; i < refTerr.length; i++) {
      doc.text(
        "Territoire " +
          (i + 1) +
          " : " +
          refTerr[i].label +
          " (" +
          refTerr[i].codegeo +
          ")",
        baseX,
        posY + 80 + 7 * i
      );
    }

    if (querry_table == "querry_pppi") {
      let mentionPPPI_1 =
        "Ces données ont vocation à alimenter la définition et la mise en oeuvre des politiques locales de lutte contre l'habitat indigne, et ne doivent être diffusées que dans le cadre d'un partenariat formalisé ";
      let mentionPPPI_2 =
        "avec les services de l'État ou l'Anah. Ces données sont soumises aux règles strictes de diffusion, leur exploitation impose des précautions méthodologiques.  ";
      let mentionPPPI_3 =
        "Les valeurs correspondent aux valeurs secrètes (comprises entre 1 et 10 inclus ou masquées pour ne pas permettre de reconstituer une valeur secrète). ";
      let mentionPPPI_4 = "";
      let mentionPPPI_5 = "";

      doc.setFontSize(6);
      doc.setFontStyle("italic");
      let pos_pave = 185;
      let inteligne = 3;
      doc.text("Précautions : ", baseX, pos_pave);
      pos_pave += inteligne;
      doc.text(mentionPPPI_1, baseX, pos_pave);
      pos_pave += inteligne;
      doc.text(mentionPPPI_2, baseX, pos_pave);
      pos_pave += inteligne;
      doc.text(mentionPPPI_3, baseX, pos_pave);
      pos_pave += inteligne;
      doc.text(mentionPPPI_4, baseX, pos_pave);
      pos_pave += inteligne;
      doc.text(mentionPPPI_5, baseX, pos_pave);
    }

    doc.setFontSize(8);
    doc.setFontStyle("italic");
    //doc.text("Anah 2018", 104, 190);

    doc.addPage();
    doc.setPage(1);

    let totalPagesExp = "{total_pages_count_string}";

    let indexPage = 1;
    let that = this;
    let titleSection = "";

    let pageContent = function (data) {
      if (data.pageCount > 1) {
        if (indexPage == 1) {
          titleSection = String(that.structureData[0].section.section);
        }
        doc.setFontSize(12);
        doc.setTextColor(96, 121, 150);
        doc.setFontStyle("normal");
      }
    };
    //console.log(this.structureData[0].section.section);
    for (let a = 0; a < refTab.length; a++) {
      titleSection = String(that.structureData[refTab[a].sec].section.section);
      let marginTopTable = 29;

      for (let i = 0; i < refTab[a].nb_tab; i++) {
        let elem = document.getElementById("sec_" + refTab[a].sec + "tab_" + i);
        //console.log(elem);
        let headStyle = {};
        if (elem) {
          //console.log(elem);
          if (i == 0) {
            $(elem).prepend(
              "<tr><th>" +
                titleSection +
                "</th><th></th> <th></th> <th></th><th></th><th></th><th></th><th></th><th></th></tr>"
            );
            headStyle = {
              fillColor: [187, 11, 73],
              textColor: [255, 255, 255],
              fontSize: 9,
              0: { halign: "left" },
              1: { halign: "right" },
              2: { halign: "right" },
              3: { halign: "right" },
              4: { halign: "right" },
              5: { halign: "right" },
              6: { halign: "right" },
              7: { halign: "right" },
              8: { halign: "right" },
            };
          } else {
            headStyle = {
              fillColor: [255, 255, 255],
              textColor: [96, 121, 150],
              fontSize: 8,
              lineWidth: 0.3,
            };
          }

          let lastRowContent = elem.lastChild.innerHTML;
          let lastRow = elem.lastChild;
          if (lastRowContent === "<!---->" || lastRowContent === "") {
            lastRow.parentNode.removeChild(lastRow);
          } else {
            // ------- Attention patch pour les lignes avec lien url en fin de tableau
            lastRow.parentNode.removeChild(lastRow);
          }
          let res = doc.autoTableHtmlToJson(elem, false);

          if (i == 0) $(elem).remove($(elem).firstChild); // ----> patch pour retirer le heade precedement ajouté pour le pdf

          let lastYPosition = 0.0;
          doc.lastAutoTable.finalY
            ? (lastYPosition = doc.lastAutoTable.finalY + 2.0)
            : (lastYPosition = 150.5);

          doc.autoTable(res.columns, res.data, {
            didDrawPage: pageContent,
            margin: { top: marginTopTable },
            startY: lastYPosition,
            pageBreak: "avoid",
            body: elem,
            showHead: "everyPage",
            styles: {
              scellPadding: 0.4,
              fontSize: 7.5,
              lineColor: 200,
              lineWidth: 0.2,
            },

            headStyles: headStyle,
            columnStyles: {
              0: {
                cellWidth: 110,
                overflow: "linebreak",
                halign: "left",
                fillColor: false,
              },
              1: {
                cellWidth: 16,
                overflow: "linebreak",
                halign: "right",
                fillColor: false,
              },
              2: {
                cellWidth: 16,
                overflow: "linebreak",
                halign: "right",
                fillColor: false,
              },
              3: {
                cellWidth: 16,
                overflow: "linebreak",
                halign: "right",
                fillColor: false,
              },
              4: {
                cellWidth: 16,
                overflow: "linebreak",
                halign: "right",
                fillColor: false,
              },
              5: {
                cellWidth: 16,
                overflow: "linebreak",
                halign: "right",
                fillColor: false,
              },
              6: {
                cellWidth: 16,
                overflow: "linebreak",
                halign: "right",
                fillColor: false,
              },
              7: {
                cellWidth: 16,
                overflow: "linebreak",
                halign: "right",
                fillColor: false,
              },
              8: {
                cellWidth: 16,
                overflow: "linebreak",
                halign: "right",
                fillColor: false,
              },
            },

            didParseCell: function (data) {
              if (data.cell && data.cell.raw && data.cell.raw.textContent) {
                //let textCellValue = data.cell.raw.textContent.replace(/[^\x20-\x7E]/gmi, "").trim();
                let textCellValue = data.cell.raw.textContent.trim();

                if (textCellValue.toLowerCase().indexOf("dont") >= 0) {
                  data.cell.text = "      - " + textCellValue;
                } else {
                  data.cell.text = textCellValue;
                }

                /*
                                if(textCellValue.indexOf('Retrouve') >= 0){
                                    data.cell.text = "";
                                }
                                */
              }
            },

            willDrawCell: function (data) {
              if (
                data.row &&
                data.row.index === 0 &&
                data.section === "body" &&
                i === 0
              ) {
                doc.setFontStyle("bold");
                doc.setFontSize(8);
                doc.setTextColor(96, 121, 150);
                doc.setDrawColor(200);
                doc.setLineWidth(0.3);
              } else if (data.cell && data.cell.text[0].indexOf("ource") >= 0) {
                doc.setFontStyle("bold");
                doc.setFontSize(6);
                doc.setTextColor(80, 80, 80);
              }
            },
          });
        }
      }
    }
    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }
    let pageCount = doc.internal.getNumberOfPages();

    for (let i = 2; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(12);
      doc.setTextColor(96, 121, 150);
      doc.setFontStyle("normal");
      doc.text(title, 35, 20);
      doc.setFontSize(12);
      doc.addImage(logoData, "JPEG", 14, 5, 20, 17);

      // Nom des territoires
      let refTerr1 = refTerr[0].label.substring(0, 25);
      let refTerr2 = refTerr[1].label.substring(0, 25);
      let refTerr3 = refTerr[2].label.substring(0, 25);
      let refTerr4 = refTerr[3].label.substring(0, 25);
      doc.setFontSize(7);
      let columns = [refTerr1, refTerr2, refTerr3, refTerr4];
      let datatest = [];
      //console.log(columns);
      doc.autoTable({
        head: [columns],
        startY: 24.5,
        margin: [0, 0, 0, 138],
        headStyles: {
          halign: "center",
          fontSize: 8,
          overflow: "hidden",
          fillColor: [96, 121, 150],
          lineColor: 255,
          lineWidth: 0.2,
        },
        tableWidth: 145,
        tablehight: 15,
        styles: {
          cellPadding: 0.5,
          fontSize: 8,
          overflow: "hidden",
          cellWidth: 34,
        },
      });

      doc.setFontSize(9);
      doc.text(
        15,
        190,
        doc.internal.getCurrentPageInfo().pageNumber + "/" + pageCount
      );
    }
    doc.save(this.appFileName + ".pdf");
    doc = new window.JsPDF({ orientation: "landscape" });
  }
}
