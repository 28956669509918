"use strict";

export class DataStructureService {

    //protected pgGeoDataDB: string;
    //protected headers: Headers;

    constructor(){

        this.headers = new Headers({'Content-Type': 'application/json'});
    }

    getStructure(thema, querry_tbl, level=null)
    {
        let params = {
            thema: thema,
            level: level,
            querry_tbl: querry_tbl
        };

        return axios.post('/geodb/datastructure', params, this.headers);
    }

    getMapList(section){

        let params = {
            section: section,
        };

        //console.log("section : ", section);

        return axios.post('/geodb/maplist', params, this.headers);
    }

}